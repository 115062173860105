import React, { useState, useEffect } from 'react';
import { fetchData } from '../api'; // Import the API function
import MatchupPlayerCard from './MatchupPlayerCard';
import './MatchupPlayerRoster.css';
import { teamsDict } from '../components/TeamInfo';
import playerInfoData from '../components/player_info.json'; // Import player_info.json
import { abbreviationToTeamName } from '../components/TeamDictionary';
import { useKeycloak } from '../KeycloakProvider'; // Import the Keycloak context
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const positions = ['PG', 'SG', 'SF', 'PF', 'C'];

const defaultPlayer = {
  fgPercentage: 'N/A',
  home_or_away: 1,
  imageSrc: 'player_logos/default.png',
  mpg: '0.0',
  name: 'No Player',
  number: 0,
  opponentLogoSrc: 'team_logos/default.png',
  opponentName: 'N/A',
  playerId: 0,
  position: 'N/A',
  ppg: '0.0',
  apg: '0.0',
  rpg: '0.0',
};

const generatePlayer = (playerInfo) => {
  const { player_id, player_name, position, team_name } = playerInfo;
  const additionalPlayerData = playerInfoData.find(p => p.player_id === player_id);

  return {
    ...playerInfo,
    playerId: player_id,
    name: player_name,
    imageSrc: `player_logos/${player_id}.png`,
    position: additionalPlayerData?.position || position,
    number: additionalPlayerData?.player_number || playerInfo.number || 0,
    teamName: team_name,
    fgPercentage: playerInfo.fg_percentage
      ? `${(playerInfo.fg_percentage * 100).toFixed(1)}%`
      : 'N/A',
    mpg: playerInfo.mpg ? playerInfo.mpg.toFixed(1) : '0.0',
    valueChange: playerInfo.pct_change
      ? `${(playerInfo.pct_change * 100).toFixed(2)}%`
      : '0.00%',
    ppg: playerInfo.pts_mean_rolling_30_games
      ? playerInfo.pts_mean_rolling_30_games.toFixed(1)
      : '0.0',
    apg: playerInfo.ast_mean_rolling_30_games
      ? playerInfo.ast_mean_rolling_30_games.toFixed(1)
      : '0.0',
    rpg: playerInfo.reb_mean_rolling_30_games
      ? playerInfo.reb_mean_rolling_30_games.toFixed(1)
      : '0.0',
  };
};

const MatchupPlayerRoster = ({ homeTeamId, awayTeamId }) => {
  const [homePlayers, setHomePlayers] = useState([]);
  const [awayPlayers, setAwayPlayers] = useState([]);
  const [error, setError] = useState(null);
  const { token } = useKeycloak(); // Get the token from Keycloak
  const navigate = useNavigate(); // Initialize navigate for navigation

  useEffect(() => {
    const fetchAndEnrichPlayers = async (teamId, setPlayers) => {
      try {
        const endpoint = `players_overall/get_team_players/?team_id=${teamId}`;
        const response = await fetchData(endpoint, token);
        const sortedPlayers = response.sort((a, b) => a.minutes_rank - b.minutes_rank);

        const enrichedPlayers = sortedPlayers.map((player) => {
          return generatePlayer(player);
        });
        setPlayers(enrichedPlayers);
      } catch (error) {
        console.error(`Error fetching players for team ${teamId}:`, error);
        setError(`Failed to fetch players for team ${teamId}`);
        setPlayers([]);
      }
    };

    fetchAndEnrichPlayers(homeTeamId, setHomePlayers);
    fetchAndEnrichPlayers(awayTeamId, setAwayPlayers);
  }, [homeTeamId, awayTeamId, token]);

  const getStarters = (players) => players.slice(0, 5);
  const getSubstitutes = (players) => players.slice(5, 10);

  const homeStarters = getStarters(homePlayers);
  const homeSubs = getSubstitutes(homePlayers);
  const awayStarters = getStarters(awayPlayers);
  const awaySubs = getSubstitutes(awayPlayers);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const getTeamColors = (teamName) => {
    if (!teamName) return null;
    const lastWord = teamName.split(' ').pop(); // Get the last word of the team name
    const team = teamsDict[lastWord];
    return team ? team.colors : null;
  };

  const handlePlayerClick = (player) => {
    navigate(`/PlayerDashboard`, { state: { playerId: player.playerId } });
  };

  const renderPlayerCard = (player) => {
    const colors = getTeamColors(player?.teamName);
    if (!colors) return null;

    return (
      <MatchupPlayerCard
        playerData={player || defaultPlayer}
        color1={colors[0]}
        color2={colors[1]}
        color3={colors[2] || colors[1]}
        onClick={() => handlePlayerClick(player || defaultPlayer)}
      />
    );
  };

  return (
    <div className="matchup-players-roster-outer-container">
      <div className="matchup-players-roster-labels">
        <div className="matchup-players-roster-team-labels">
          <div className="matchup-players-roster-column-label home_starter">Starter</div>
          <div className="matchup-players-roster-column-label home_sub">Sub</div>
        </div>
        <div className="matchup-players-roster-column-label roster">Roster</div>
        <div className="matchup-players-roster-team-labels">
          <div className="matchup-players-roster-column-label away_starter">Starter</div>
          <div className="matchup-players-roster-column-label away_sub">Sub</div>
        </div>
      </div>

      <div className="matchup-players-roster-container">
        {positions.map((position, index) => (
          <div key={index} className="matchup-players-roster-row">
            <div className="matchup-players-roster-team">
              {renderPlayerCard(homeSubs[index])}
              {renderPlayerCard(homeStarters[index])}
            </div>
            <div className="matchup-players-roster-separator"></div>
            <div className="matchup-players-roster-position-label">{position}</div>
            <div className="matchup-players-roster-separator"></div>
            <div className="matchup-players-roster-team">
              {renderPlayerCard(awayStarters[index])}
              {renderPlayerCard(awaySubs[index])}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchupPlayerRoster;