import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'c7343360-3bcb-41b7-bc98-d73910686819', // Replace with your key
    disableTelemetry: true, // Default to not tracking until consent is granted
    enableDebug: true, // Enable debug logs in the console
  },
});

appInsights.loadAppInsights();

export default appInsights;
