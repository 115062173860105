import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import PlayerCard from '../main_dashboard_components/PlayerID';
import TeamVersus from '../main_dashboard_components/TeamVersus';
import InjuryReportTable from '../main_dashboard_components/InjuryReportTable';
import Last15Chart from '../main_dashboard_components/Last15Chart';
import Last15Table from '../main_dashboard_components/L15Table';
import ShootingChart from '../main_dashboard_components/RollingFeature';
import VersusTeamChart from '../main_dashboard_components/VersusTeamChart';
import { players } from '../components/PlayerInfo';
import './PlayerDashboard.css';
import PositionDefense from '../main_dashboard_components/PositionDefense';
import OpponentDefense from '../team_dashboard_components/OpponentDefense';
import PlayerLogsHistorical from '../main_dashboard_components/PlayerLogsHistorical';
import useAuth from '../hooks/useAuth';
import MinuteChart from '../main_dashboard_components/MinuteChart';
import SimilarPlayerPerformance from '../main_dashboard_components/SimilarPlayerPerformance';
import PremiumFeature from '../main_dashboard_components/PremiumFeature';
import PlayerSelection from '../main_dashboard_components/PlayerSelection';
import ShootingAndDefense from '../main_dashboard_components/ShootingAndDefense';
import PlayerPropsTable from '../main_dashboard_components/PlayerPropsTable';
import useFetchPlayerThresholds from '../components/PlayerThresholdData';

const PlayerDashboard = () => {
  const {
    teamColors,
    setTeamColors,
    selectedPlayer,
    setSelectedPlayer,
    thresholds
  } = usePlayerColors();

  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const paramPlayerId = searchParams.get('playerId'); // e.g. ?playerId=123

  const [selectedFeature, setSelectedFeature] = useState('Total');
  const [showSum, setShowSum] = useState(true);
  const [isAuthenticated, , , username, roles] = useAuth();

  const [initialized, setInitialized] = useState(false);

  // 1) Track screen width to conditionally switch layouts
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 2) On mount (and whenever param/state changes), pick final ID
  //    Now we only run this logic once if not initialized
  useEffect(() => {
    if (!initialized) {
      let finalId = null;
      if (paramPlayerId) {
        finalId = paramPlayerId;
      } else if (state?.playerId) {
        finalId = state.playerId;
      }

      if (finalId) {
        setSelectedPlayer(finalId);
        if (players[finalId]) {
          setTeamColors(players[finalId].teamColors);
        }
      } else if (!selectedPlayer) {
        const firstKey = Object.keys(players)[0];
        setSelectedPlayer(firstKey);
        setTeamColors(players[firstKey].teamColors);
      }
      setInitialized(true);
    }
  }, [
    paramPlayerId,
    state,
    selectedPlayer,
    setSelectedPlayer,
    setTeamColors,
    initialized
  ]);

  // 3) Fetch thresholds after stable selectedPlayer
  const playerName = players[selectedPlayer]?.name || 'Unknown Player';
  useFetchPlayerThresholds(playerName);

  // 4) Example: track page view once
  useEffect(() => {
    (function () {
      const methods = [
        'trackEvent',
        'trackException',
        'trackMetric',
        'trackPageView',
        'trackTrace',
        'setAuthenticatedUserContext',
        'clearAuthenticatedUserContext'
      ];
      const appInsights = window.appInsights || function (config) {
        function trackFunc(func) {
          return function () {
            const args = arguments;
            appInsights.queue.push(function () {
              appInsights[func].apply(appInsights, args);
            });
          };
        }
        const ai = { config: config },
          d = document,
          scriptElem = d.createElement('script');
        function setup() {
          for (let i = 0; i < methods.length; i++) {
            ai[methods[i]] = trackFunc(methods[i]);
          }
        }
        scriptElem.src = config.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js';
        d.getElementsByTagName('head')[0].appendChild(scriptElem);
        setup();
        ai.queue = [];
        ai.version = '1.0';
        return ai;
      }({
        instrumentationKey: 'YOUR_INSTRUMENTATION_KEY'
      });
      window.appInsights = appInsights;

      // Track page view
      appInsights.trackPageView({
        name: 'PlayerDashboard',
        properties: { component: 'PlayerDashboard' }
      });
    })();
  }, []);

  // 5) Info-button tooltip logic
  useEffect(() => {
    document.querySelectorAll('.player-dashboard-info-button').forEach((button) => {
      button.addEventListener('mouseenter', () => {
        const infoText = button.querySelector('.player-dashboard-info-text');
        const rect = button.getBoundingClientRect();
        if (infoText) {
          infoText.style.top = `${rect.top + window.scrollY - infoText.offsetHeight - 10}px`;
          infoText.style.left = `${rect.left + rect.width / 2 - infoText.offsetWidth / 2}px`;
        }
      });
    });
  }, []);

  // 6) Feature toggles
  const features = [
    'Total', 'AST', 'REB',
    'Pts+Ast', 'Pts+Reb', 'Ast+Reb',
    'Pts+Ast+Reb', 'BLK', 'STL'
  ];
  const featureMap = {
    Points: 'Total',
    Assists: 'AST',
    Rebounds: 'REB',
    Steals: 'STL',
    Blocks: 'BLK',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Ast+Reb': 'Ast+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb'
  };
  const displayMap = {
    Total: 'PTS',
    AST: 'AST',
    REB: 'REB',
    'Pts+Ast': 'PTS+AST',
    'Pts+Reb': 'PTS+REB',
    'Ast+Reb': 'AST+REB',
    'Pts+Ast+Reb': 'PTS+AST+REB',
    BLK: 'BLK',
    STL: 'STL'
  };

  const handleFeatureClick = (feature) => setSelectedFeature(feature);
  const handleToggle = () => setShowSum(!showSum);

  const handlePlayerLogsFeatureSelect = (feature) => {
    const mappedFeature = featureMap[feature] || feature;
    setSelectedFeature(mappedFeature);
  };

  const getDisplayFeature = (feature) => displayMap[feature] || feature.toUpperCase();

  // Instead of modifying URL, simply set context
  const handlePlayerSelect = (playerId) => {
    setSelectedPlayer(playerId);
    setTeamColors(players[playerId].teamColors);
  };

  // 7) Decide which layout to render:
  //    If screenWidth < 900, we show "mobile-ish" layout that merges row 4 & 5
  //    If screenWidth >= 900, we show your original layout
  const isMobileView = screenWidth < 900;

  // Original desktop layout as a separate chunk:
  const desktopLayout = (
    <>
      {/* ROW 1: PlayerCard + InjuryReport + TeamVersus */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-player-card">
          <PlayerCard
            selectedPlayer={selectedPlayer}
            handlePlayerSelect={handlePlayerSelect}
          />
        </div>
        <div className="player-dashboard-injury-report">
          <InjuryReportTable selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-team-versus">
          <TeamVersus showSum={showSum} />
        </div>
      </div>

      {/* ROW 2: Feature Menu */}
      <div className="player-dashboard-feature-menu">
        {features.map((feat) => (
          <div
            key={feat}
            className={`player-dashboard-feature-item ${
              selectedFeature === feat ? 'active' : ''
            }`}
            onClick={() => handleFeatureClick(feat)}
          >
            {getDisplayFeature(feat)}
            {thresholds[playerName]?.[feat] !== undefined &&
              thresholds[playerName][feat] !== null && (
                <div className="threshold-value">
                  {thresholds[playerName][feat]}
                </div>
            )}
          </div>
        ))}
      </div>

      {/* ROW 3: Last15 Chart + Table */}
      <div className="player-dashboard-main-charts">
        <div className="player-dashboard-last-15">
          <Last15Chart
            showSum={showSum}
            onToggle={handleToggle}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-last-15-table">
          <Last15Table selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 4: VersusTeam + Shooting + PositionDefense + PremiumFeature */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-versus-team">
          <VersusTeamChart
            showSum={showSum}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-shooting">
          <ShootingChart
            showSum={showSum}
            teamColors={teamColors}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-position-defense">
          <PositionDefense selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-premium-feature">
          <PremiumFeature selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 5: OpponentDefense + PropsTable */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-opponent-defense">
          <OpponentDefense
            teamColors={teamColors}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-props-table">
          <PlayerPropsTable selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 6: Historical Logs + Similar Perf + PlayerSelection */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-historical-logs">
          <PlayerLogsHistorical
            selectedFeature={selectedFeature}
            setSelectedFeature={handlePlayerLogsFeatureSelect}
          />
        </div>
        <div className="player-dashboard-similar-performance">
          <SimilarPlayerPerformance selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-player-selection">
          <PlayerSelection selectedFeature={selectedFeature} />
        </div>
      </div>
    </>
  );

  // "Mobile-ish" layout for < 900px
  // — we combine row 4 & 5 so that PositionDefense sits next to OpponentDefense,
  //   PremiumFeature sits next to PropsTable, etc.
  const mobileMergedLayout = (
    <>
      {/* ROW 1: PlayerCard + InjuryReport + TeamVersus (same as desktop) */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-player-card">
          <PlayerCard
            selectedPlayer={selectedPlayer}
            handlePlayerSelect={handlePlayerSelect}
          />
        </div>
        <div className="player-dashboard-injury-report">
          <InjuryReportTable selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-team-versus">
          <TeamVersus showSum={showSum} />
        </div>
      </div>

      {/* ROW 2: Feature Menu (unchanged) */}
      <div className="player-dashboard-feature-menu">
        {features.map((feat) => (
          <div
            key={feat}
            className={`player-dashboard-feature-item ${
              selectedFeature === feat ? 'active' : ''
            }`}
            onClick={() => handleFeatureClick(feat)}
          >
            {getDisplayFeature(feat)}
            {thresholds[playerName]?.[feat] !== undefined &&
              thresholds[playerName][feat] !== null && (
                <div className="threshold-value">
                  {thresholds[playerName][feat]}
                </div>
            )}
          </div>
        ))}
      </div>

      {/* ROW 3: Last15 Chart + Table (unchanged) */}
      <div className="player-dashboard-main-charts">
        <div className="player-dashboard-last-15">
          <Last15Chart
            showSum={showSum}
            onToggle={handleToggle}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-last-15-table">
          <Last15Table selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* 
        ROW 4 & 5 MERGED INTO ONE ROW:
        1) VersusTeam
        2) Shooting
        3) PositionDefense
        4) OpponentDefense
        5) PremiumFeature
        6) PropsTable
      */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-versus-team">
          <VersusTeamChart showSum={showSum} selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-shooting">
          <ShootingChart
            showSum={showSum}
            teamColors={teamColors}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-position-defense">
          <PositionDefense selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-opponent-defense">
          <OpponentDefense
            teamColors={teamColors}
            selectedFeature={selectedFeature}
          />
        </div>
        <div className="player-dashboard-premium-feature">
          <PremiumFeature selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-props-table">
          <PlayerPropsTable selectedFeature={selectedFeature} />
        </div>
      </div>

      {/* ROW 6: Historical Logs + Similar Perf + PlayerSelection (unchanged) */}
      <div className="player-dashboard-row">
        <div className="player-dashboard-historical-logs">
          <PlayerLogsHistorical
            selectedFeature={selectedFeature}
            setSelectedFeature={handlePlayerLogsFeatureSelect}
          />
        </div>
        <div className="player-dashboard-similar-performance">
          <SimilarPlayerPerformance selectedFeature={selectedFeature} />
        </div>
        <div className="player-dashboard-player-selection">
          <PlayerSelection selectedFeature={selectedFeature} />
        </div>
      </div>
    </>
  );

  return (
    <div className="player-dashboard-container">
      {isMobileView ? mobileMergedLayout : desktopLayout}
    </div>
  );
};

export default PlayerDashboard;
