import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src="/big_logo.png" alt="Showstone Logo" className="footer-logo" />
        <p className="footer-description">
          This website's content is provided solely for entertainment purposes. Please be mindful of and adhere to the
          sports betting laws applicable in your jurisdiction.
        </p>
        <p className="footer-disclaimer">
          This website operates independently and is not connected to, endorsed by, or affiliated with the NFL, MLB,
          NBA, NHL, PGA, UFC, WNBA, or any other sports leagues or teams. Any names, logos, or images featured are
          trademarks™ or registered® trademarks owned by their respective organizations, used here without implying any
          association or endorsement.
        </p>
        <p className="footer-helpline">
          If you or someone you know is struggling with gambling, help is available. Contact the{" "}
          <span className="helpline-highlight">National Problem Gambling Helpline at 1-800-522-4700</span> for
          confidential support, available 24/7. Talking to a professional can provide guidance, resources, and support
          tailored to your needs. Remember, help is just a call away.
        </p>
        <div className="footer-links">
          <a href="/PrivacyPolicy" className="footer-link">
            Privacy Policy
          </a>
          <a href="/CookiePolicy" className="footer-link">
            Cookie Policy
          </a>
          <a href="/TermsOfService" className="footer-link">
            Terms of Service
          </a>
        </div>
        <div className="footer-contact">
          <p className="contact-email">
            Contact us:{" "}
            <a href="mailto:showstone@showstone.io" className="email-link">
              showstone@showstone.io
            </a>
          </p>
        </div>
        {/* <div className="footer-socials">
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="social-icon discord">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="currentColor">
              <path d="M20.317 4.369A19.791 19.791 0 0016.8 3.1a15.447 15.447 0 00-.745 1.593A18.06 18.06 0 008 4.692a14.86 14.86 0 00-.793-1.589A19.579 19.579 0 003.692 4.3a20.33 20.33 0 00-3.017 14.588A19.878 19.878 0 006.7 20.075c.185-.233.377-.477.566-.734-2.069-.538-2.906-2.2-2.906-2.2s2.62 1.067 5.5 1.6a19.36 19.36 0 001.254-2.315 20.466 20.466 0 004.863.1c.419.755.846 1.48 1.255 2.313 2.877-.538 5.496-1.6 5.496-1.6s-.839 1.664-2.906 2.2c.189.256.381.501.566.734a19.743 19.743 0 005.621-1.487 19.907 19.907 0 00-3.016-14.593zm-11.654 8.156c-.88 0-1.597-.846-1.597-1.88s.717-1.88 1.597-1.88 1.598.844 1.598 1.88-.718 1.88-1.598 1.88zm7.674 0c-.88 0-1.597-.846-1.597-1.88s.717-1.88 1.597-1.88 1.598.844 1.598 1.88-.718 1.88-1.598 1.88z" />
            </svg>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon twitter">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="currentColor">
              <path d="M23.643 4.937a9.665 9.665 0 01-2.825.775 4.94 4.94 0 002.163-2.725 9.916 9.916 0 01-3.127 1.184A4.92 4.92 0 0016.616 3a4.92 4.92 0 00-4.93 4.924c0 .386.045.761.127 1.12C7.728 8.81 4.1 6.896 1.67 3.934a4.89 4.89 0 00-.666 2.477c0 1.71.87 3.222 2.2 4.107a4.926 4.926 0 01-2.225-.616v.06a4.924 4.924 0 003.946 4.83 5.027 5.027 0 01-2.212.084 4.937 4.937 0 004.6 3.42A9.869 9.869 0 010 20.555 13.92 13.92 0 007.548 22c9.142 0 14.307-7.721 13.995-14.646A9.926 9.926 0 0024 4.557a9.72 9.72 0 01-2.357.654z" />
            </svg>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="currentColor">
              <path d="M12 0C8.7 0 8.333.016 7.053.073c-1.28.057-2.154.267-2.914.57a5.891 5.891 0 00-2.126 1.386A5.89 5.89 0 00.608 4.186c-.302.76-.513 1.635-.57 2.914C.016 8.333 0 8.7 0 12s.016 3.667.073 4.947c.057 1.28.267 2.154.57 2.914a5.893 5.893 0 001.385 2.126 5.89 5.89 0 002.127 1.385c.76.302 1.635.513 2.914.57C8.333 23.984 8.7 24 12 24s3.667-.016 4.947-.073c1.28-.057 2.154-.267 2.914-.57a5.893 5.893 0 002.126-1.385 5.892 5.892 0 001.385-2.126c.302-.76.513-1.635.57-2.914.057-1.28.073-1.647.073-4.947s-.016-3.667-.073-4.947c-.057-1.28-.267-2.154-.57-2.914a5.89 5.89 0 00-1.385-2.126A5.892 5.892 0 0019.861.643C19.101.34 18.226.129 16.947.073 15.667.016 15.3 0 12 0zM12 5.838a6.162 6.162 0 016.164 6.162A6.162 6.162 0 0112 18.162 6.162 6.162 0 015.838 12 6.162 6.162 0 0112 5.838zm6.406-.005a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z" />
            </svg>
          </a>
          <a href="https://reddit.com" target="_blank" rel="noopener noreferrer" className="social-icon reddit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="currentColor">
              <path d="M11.999.018C5.373.018 0 5.39 0 12.018 0 18.626 5.373 24 11.999 24 18.627 24 24 18.626 24 12.018 24 5.39 18.627.018 11.999.018zm6.862 15.557c-.328.326-.702.6-1.121.822.084.274.128.562.128.862 0 2.036-2.412 3.693-5.383 3.693-2.975 0-5.388-1.656-5.388-3.693 0-.3.044-.588.129-.862a3.495 3.495 0 01-1.121-.822 3.513 3.513 0 010-4.97 3.48 3.48 0 014.939 0 6.019 6.019 0 013.692 0 3.48 3.48 0 014.939 0 3.513 3.513 0 010 4.97z" />
            </svg>
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
