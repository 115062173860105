import React, { useState, useEffect } from "react";
// If you want to use a <Link> for the "Sign Up" button, import from react-router-dom:
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";  // <--- import your Auth0 hook
import './LandingPage.css'
// Import your local assets here
import lbj from "./assets/ad_dash.png";
import showstoneLogoWhite from "./assets/showstoneLogoWhite.png";
import mlScreenshot from "./assets/top_pics.png";

// New imports for Key Features
import tactics from "./assets/tactics.png";
import court from "./assets/court.png";
import dunks from "./assets/dunks.png";
import injuryReport from "./assets/injury_report.png";

const LandingPage = () => {
  // 1) Use the auth hook to get login/logout
  const [
    isAuthenticated,
    getToken,
    username,
    roles,
    loading,
    login,
    logout
  ] = useAuth();

  // For navigation to /Subscription
  const navigate = useNavigate();

  const [openFAQ, setOpenFAQ] = useState(null); // Track the open FAQ item

  const toggleFAQ = index => {
    setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ open state
  };

  const faqs = [
    {
      question: "How can I use Showstone?",
      answer: "Showstone provides comprehensive analytics and data visualization tools for NBA statistics and player performance predictions."
    },
    {
      question: "What features are included with the free version?",
      answer: "The free version offers access to basic NBA team and player statistics as well as matchup statistics and other features."
    },
    {
      question: "How can I get in touch with you",
      answer: "You can email us at showstone@showstone.io !"
    },
    // {
    //   question: "Is the website currently in Beta/Free",
    //   answer: "Yes, the website is currently in the testing period and all the features are free and unlocked"
    // },
    // More FAQs can be added here
  ];

  // Updated Key Features data
  const features = [
    {
      title: "ADVANCED PLAYER AND TEAM STATISTICS",
      text: "Advanced player and team statistics reveal in-depth performance metrics, from shooting efficiency to defensive impact and player influence on team dynamics.",
      image: tactics,
    },
    {
      title: "MATCHUP STATS AND ANALYTICS",
      text: "Matchup analytics deliver in-depth comparisons of player and team dynamics, revealing strengths, weaknesses, and key factors for each game. ",
      image: court,
    },
    {
      title: "PLAYER PROP PREDICTIONS",
      text: "Player props insights use data-driven predictions to highlight key performance trends for individual players, from scoring and assists to rebounds and more. ",
      image: dunks,
    },
    {
      title: "ADVANCED INJURY REPORT",
      text: "Track the injury report and evaluate how player absences or returns can affect team performance. Past games without player analyzed instantly",
      image: injuryReport,
    },
  ];

  // We'll store "slides" so that:
  // - On desktop: 2 items per slide (2 cards side by side)
  // - On mobile: 1 item per slide (1 card)
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        // Mobile: 1 feature per slide
        setSlides(features.map((f) => [f]));
      } else {
        // Desktop: 2 features per slide
        const newSlides = [];
        for (let i = 0; i < features.length; i += 2) {
          newSlides.push(features.slice(i, i + 2));
        }
        setSlides(newSlides);
      }
      setCurrentIndex(0);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="landing-page-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="scar-container">
          <svg
            className="scar-shape"
            viewBox="0 0 1440 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M-31 373.5L311 137.5L910 411L1471 -151"
              stroke="#4B0080"
              strokeWidth="427"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="hero-content">
          <h1 className="hero-title">
            Basketball Analytics Hub With Advanced Statistics and Future
            Performance Projections
          </h1>
          <p className="hero-subtitle">
            Welcome to Showstone – Your Ultimate NBA Stats &amp; Player
            Performance Insights Platform!
          </p>
          {/* 2) Use onClick={login} to sign up/login */}
          <button className="hero-button" onClick={login}>
            Sign Up for FREE
          </button>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section">
        <div className="about-container">
          <div className="about-text">
            <h2>
              <span className="highlight">Advanced Data at Your Fingertips</span> 
            </h2>
            <p>
              Whether you're a dedicated basketball fan looking to get a deeper
              grasp of NBA games and player performance, or a
              veteran sports analyst - our platform will meet your needs.
            </p>
          </div>
          <div className="about-image">
            <img
              src={lbj}
              alt="LeBron James showing basketball analytics"
              className="lbj-shadow"
            />
          </div>
        </div>
      </section>

      {/* What we Section */}
      <section className="what-we-offer-section">
        <div className="offer-header">
          <h2>WHAT WE OFFER</h2>
        </div>
        <div className="offer-grid">
          <div className="offer-item">
            <h3>Comprehensive NBA Stats</h3>
            <p>
              Dive into real-time statistics that offer a comprehensive view of player performance and team dynamics. From scoring trends to defensive matchups,
              get instant access to data that shapes the game
            </p>
          </div>
          <div className="offer-item">
            <h3>AI-Powered Player Predictions</h3>
            <p>
              Our sophisticated machine learning model utilizes comprehensive
              historical data and player trends to deliver detailed insights and
              highly accurate predictions, offering a precise estimation of
              player performance.
            </p>
          </div>
          <div className="offer-item">
            <h3>Interactive Data Visualization</h3>
            <p>
              Discover key matchup metrics through dynamic visuals, including
              interactive charts, graphs, and intuitive tools, for a deeper and
              more engaging analytical experience.
            </p>
          </div>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="key-features-section">
        <h2 className="key-features-title">KEY FEATURES</h2>
        <div className="carousel-container">
          <button className="carousel-button prev" onClick={handlePrev}>
            &#9664;
          </button>
          {slides.length > 0 &&
            slides[currentIndex].map((item, idx) => (
              <div className="card" key={idx}>
                {/* Circle behind icon */}
                <div className="icon-circle">
                  <img
                    className="card-icon"
                    src={item.image}
                    alt="Feature Icon"
                  />
                </div>
                <h3 className="card-title">{item.title}</h3>
                <p className="card-text">{item.text}</p>
              </div>
            ))}
          <button className="carousel-button next" onClick={handleNext}>
            &#9654;
          </button>
        </div>
      </section>

      {/* Machine Learning Section */}
      <section className="machine-learning-section">
        <div className="ml-container">
          <h4 className="ml-subtitle">Our technology</h4>
          <h2 className="ml-title">MACHINE LEARNING MODEL</h2>

          <div className="ml-content">
            <div className="ml-image">
              <img
                src={mlScreenshot}
                alt="Machine Learning picks screenshot"
                className="ml-screenshot"
              />
            </div>
            <ul className="ml-text">
              <li>
                Our machine learning model provides estimations across 7
                different player props, achieving an overall accuracy of 56%.
                For high-confidence predictions (Top Picks), the model delivers an impressive
                accuracy of 63%.
              </li>
              <li>
                By analyzing over 300,000 NBA player games, the model uncovers
                complex patterns and trends hidden within the data. This allows
                it to generate detailed insights and predictions, offering a
                forward-looking perspective on player performance.
              </li>
              <li>
                We've developed a cutting-edge confidence estimation system,
                which evaluates the reliability of each prediction. This advanced
                feature empowers users to filter and focus on the most promising
                options with clarity and precision.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="pricing-section">
        <div className="pricing-cards-container">
          {/* FREE CARD */}
          <div className="pricing-card free-card">
            <div className="pricing-card-header">
              <img
                src={showstoneLogoWhite}
                alt="Showstone Logo"
                className="card-logo"
              />
              <span className="card-tier">FREE</span>
            </div>
            <div className="pricing-card-body">
              <h3 className="pricing-card-price">0$</h3>
              <ul className="pricing-features-list">
                <li>Create Account for FREE!</li>
                <li>Access our stats and NBA content</li>
              </ul>
            </div>
            <div className="pricing-card-footer">
              <button className="pricing-card-button" onClick={login}>
                Sign Up for FREE
              </button>
            </div>
          </div>

          {/* BASIC CARD */}
          {/* <div className="pricing-card basic-card">
            <div className="pricing-card-header">
              <img
                src={showstoneLogoWhite}
                alt="Showstone Logo"
                className="card-logo"
              />
              <span className="card-tier">BASIC</span>
            </div>
            <div className="pricing-card-body">
              <h3 className="pricing-card-price">0$</h3>
              <ul className="pricing-features-list">
                <li>Create Account for FREE!</li>
                <li>Access our stats and NBA content</li>
                <li>Bankroll Management Tools</li>
              </ul>
            </div>
            <div className="pricing-card-footer">
              <button className="pricing-card-button" onClick={login}>
                Buy
              </button>
            </div> */}
          {/* </div> */}

          {/* PREMIUM CARD */}
          <div className="pricing-card premium-card">
            <div className="pricing-card-header">
              <img
                src={showstoneLogoWhite}
                alt="Showstone Logo"
                className="card-logo"
              />
              <span className="card-tier">PREMIUM</span>
            </div>
            <div className="pricing-card-body">
              <h3 className="pricing-card-price">24.87€</h3>
              <ul className="pricing-features-list">
                <li>Advanced Features</li>
                <li>Model Projections</li>
                <li>Ad-free experience</li>
                <li>Priority customer support</li>
                <li>Early access to new features</li>
              </ul>
            </div>
            <div className="pricing-card-footer">
              {/* CHANGE: navigate to /Subscription instead of login */}
              <button
                className="pricing-card-button"
                onClick={() => navigate("/Subscription")}
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="faq-header">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="faq-content">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className="faq-icon">
                  {openFAQ === index ? '-' : '+'}
                </span>
              </div>
              <div
                className="faq-answer"
                style={{ display: openFAQ === index ? 'block' : 'none' }}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
