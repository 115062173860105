import React from 'react';

const Players = () => {
  return (
    <div className="players-content">
      <h2>Players</h2>
      <p>Players' data will be displayed here.</p>
    </div>
  );
};

export default Players;
