// AvailablePlayers.js
import React from 'react';
import './AvailablePlayers.css';

const AvailablePlayers = ({ loadSelectedPlayers, parsedMatchups, onMatchupSelect, loading }) => {
  return (
    <div className="available-players-buttons">
      <button
        key="default"
        onClick={loadSelectedPlayers}
        className="available-players-matchup-btn default-btn"
      >
        <span>⭐</span>
        <span>Selected Players</span>
      </button>

      {parsedMatchups.map((matchup, index) => (
        <button
          key={index}
          onClick={() => onMatchupSelect(matchup)}
          className="available-players-matchup-btn"
        >
          <img src={`/team_logos/${matchup.awayTeam.toLowerCase()}.png`} alt={matchup.awayTeam} />
          <span className="matchup-time">
            <span className="matchup-at">@</span>
            <span>{matchup.label}</span>
          </span>
          <img src={`/team_logos/${matchup.homeTeam.toLowerCase()}.png`} alt={matchup.homeTeam} />
        </button>
      ))}
      {loading && <p>Loading players...</p>}
    </div>
  );
};

export default AvailablePlayers;
