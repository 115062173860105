import React from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p className="effective-date">
        <strong>Effective Date:</strong> 08.12.2024
      </p>
      <p>
        These Terms of Service (the “Terms”) govern your access to and use of
        Showstone.io (the “Website”), including all analytics, statistics,
        forecasts, and other related services and content (collectively, the
        “Services”). By accessing or using the Website, you agree to comply
        with and be legally bound by these Terms. If you do not accept these
        Terms, you must refrain from using the Website and its Services.
      </p>

      <h2>1. Scope and Use of Services</h2>
      <p>
        The Website provides sports analytics, statistical models, forecasts,
        and insights designed for informational and entertainment purposes only.
        These Services do not constitute professional, financial, legal, or
        gambling advice, nor do they guarantee any specific outcomes or
        performance.
      </p>
      <p>
        You understand that any reliance on the Services is entirely at your
        own risk, and we assume no liability for any actions taken based on the
        content provided. It is your sole responsibility to determine how to
        use the information appropriately.
      </p>

      <h2>2. Disclaimer of Warranties</h2>
      <p>
        The Website and Services are provided on an “as-is” and “as-available”
        basis, without any express or implied warranties, including but not
        limited to:
      </p>
      <ul>
        <li>Fitness for a particular purpose</li>
        <li>Merchantability or non-infringement</li>
        <li>
          The accuracy, timeliness, completeness, or reliability of analytics,
          forecasts, or data
        </li>
        <li>
          Continuous availability, uninterrupted service, or error-free
          operation
        </li>
      </ul>
      <p>
        We do not warrant or guarantee the accuracy or outcome of predictions,
        projections, or forecasts made through the Website. Sports outcomes are
        inherently unpredictable, and past performance is not indicative of
        future results.
      </p>

      <h2>3. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by applicable law, Showstone, its
        affiliates, employees, partners, and licensors shall not be liable for
        any direct, indirect, incidental, consequential, special, punitive, or
        exemplary damages arising from or related to:
      </p>
      <ul>
        <li>Your use or inability to use the Website or Services</li>
        <li>
          Errors, inaccuracies, or omissions in any analytics, predictions, or
          information provided
        </li>
        <li>
          Losses or damages resulting from reliance on content provided by the
          Website
        </li>
        <li>Service disruptions, technical failures, or unauthorized access</li>
        <li>
          Loss of profits, revenue, business opportunities, data, or goodwill,
          even if advised of the possibility of such damages
        </li>
      </ul>
      <p>
        You acknowledge that you bear full responsibility for any decisions or
        actions taken based on the information provided by the Website,
        including financial or wagering activities.
      </p>

      <h2>4. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Showstone, its
        affiliates, partners, employees, contractors, and licensors from and
        against any claims, liabilities, damages, losses, or expenses
        (including legal fees) arising from:
      </p>
      <ul>
        <li>Your use of or reliance on the Website or Services</li>
        <li>Any breach of these Terms or violation of applicable laws by you</li>
        <li>
          Any third-party claims related to your actions or decisions based on
          the content or analytics provided by the Website
        </li>
      </ul>

      <h2>5. Compliance with Applicable Laws</h2>
      <p>
        You are responsible for ensuring that your use of the Website and
        Services complies with all relevant laws, regulations, and third-party
        agreements, including those related to gambling and online betting
        where applicable. If you engage in any activities involving financial
        transactions or betting, you do so entirely at your own risk and in
        compliance with relevant jurisdictional regulations.
      </p>

      <h2>6. Prohibited Conduct</h2>
      <p>You agree not to engage in any activity that:</p>
      <ul>
        <li>Violates these Terms or any applicable laws</li>
        <li>
          Involves the use of bots, scraping tools, or other automated
          technologies to extract data from the Website
        </li>
        <li>
          Interferes with the operation or security of the Website, including
          attempting to access unauthorized areas or systems
        </li>
        <li>Uses the Services for fraudulent or unlawful purposes</li>
      </ul>
      <p>
        We reserve the right to investigate and take legal action in response
        to any violations, including termination of your access to the Website
        without prior notice.
      </p>

      <h2>7. Third-Party Links and Content</h2>
      <p>
        The Website may contain links to or incorporate content from third-party
        websites or services. We are not responsible for the content, accuracy,
        availability, or privacy practices of third-party websites, nor do we
        endorse or assume liability for any losses or damages arising from your
        interaction with such third-party services.
      </p>

      <h2>8. Service Availability and Modifications</h2>
      <p>
        We do not guarantee uninterrupted access to the Website or Services and
        may, at our sole discretion:
      </p>
      <ul>
        <li>Suspend, modify, or discontinue any aspect of the Website without notice</li>
        <li>Restrict access to certain features or content</li>
      </ul>
      <p>
        You acknowledge that we shall not be liable for any disruption,
        suspension, or modification of the Website or its Services.
      </p>

      <h2>9. Intellectual Property Rights</h2>
      <p>
        All content, analytics, tools, software, and materials provided on the
        Website are the intellectual property of Showstone or its licensors.
      </p>
      <p>
        You agree not to copy, modify, distribute, or exploit any content from
        the Website without prior written consent or attempt to reverse-engineer
        or extract the source code of the Website.
      </p>

      <h2>10. Termination of Access</h2>
      <p>
        We reserve the right to suspend or terminate your access if you violate
        these Terms or engage in unauthorized or fraudulent activities.
      </p>

      <h2>11. Subscriptions, Restricted Countries, and VPN Usage</h2>
      <p>
        We may offer subscription-based Services on the Website. However, we do
        not allow subscriptions from certain countries where such use may be
        restricted by local laws, regulations, or our internal compliance
        policies. If your jurisdiction is on our list of restricted countries,
        you must not attempt to subscribe or use our Services.
      </p>
      <p>
        Any attempt to bypass these restrictions through the use of Virtual
        Private Networks (“VPNs”) or similar methods is strictly prohibited. We
        reserve the right to suspend, terminate, or ban any user found
        circumventing these geo-restrictions, or otherwise violating these
        Terms.
      </p>
      <p>
        It is your responsibility to ensure you are not located in a restricted
        jurisdiction and are in compliance with all applicable local laws
        before subscribing or using the Services.
      </p>

      <h2>12. Changes to Terms of Service</h2>
      <p>
        We may amend these Terms at any time, with changes effective upon
        posting to the Website.
      </p>

      <h2>13. Governing Law and Dispute Resolution</h2>
      <p>
        Any disputes shall be resolved through arbitration, and you waive your
        right to class-action lawsuits.
      </p>

      <h2>14. Entire Agreement</h2>
      <p>
        These Terms constitute the entire agreement between you and Showstone.
      </p>

      <h2>15. Severability</h2>
      <p>
        If any provision is invalid, the remaining provisions shall remain
        enforceable.
      </p>

      <h2>16. Contact Information</h2>
      <p>Email: showstone@showstone.io</p>
    </div>
  );
};

export default TermsOfService;
