import React, { useState, useEffect } from 'react';
import { teamsDict } from '../../components/TeamInfo';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import './TeamComparisonChart.css';
import { fetchData } from "../../api"; // Import your utility function
import { useKeycloak } from '../../KeycloakProvider'; // Import the Keycloak context

// Function to calculate contrast ratio
const getContrastRatio = (color1, color2) => {
  const getLuminance = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

// Function to select the most distinct away team color
const selectMostDistinctColor = (homeColor, awayColors) => {
  let maxContrast = 0;
  let distinctColor = awayColors[0];

  awayColors.forEach((color) => {
    const contrast = getContrastRatio(homeColor, color);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      distinctColor = color;
    }
  });

  return distinctColor;
};

const FeatureBar = ({ label, homeValue, awayValue, homeColor, awayColor }) => {
  const total = homeValue + awayValue;
  let homePercentage = (homeValue / total) * 100;
  let awayPercentage = (awayValue / total) * 100;

  // Amplify the difference
  const amplificationFactor = 3; // Adjust this value to control the amplification
  const difference = homePercentage - 50;
  homePercentage = 50 + difference * amplificationFactor;
  awayPercentage = 100 - homePercentage;

  // Ensure percentages are within 0-100 range
  homePercentage = Math.max(10, Math.min(90, homePercentage)); // Minimum 10%, maximum 90%
  awayPercentage = 100 - homePercentage;

  return (
    <div className="team-comparison-feature-bar">
      <span className="team-comparison-bar-value left">{homeValue.toFixed(1)}</span>
      <div className="team-comparison-bar-container">
        <div className="team-comparison-bar-label">
          <span>{label}</span>
          <div className="team-comparison-bar-label-underline"></div>
        </div>
        <div className="team-comparison-bar">
          <div 
            style={{ 
              width: `${homePercentage}%`, 
              backgroundColor: homeColor
            }}
          ></div>
          <div 
            style={{ 
              width: `${awayPercentage}%`, 
              backgroundColor: awayColor
            }}
          ></div>
        </div>
      </div>
      <span className="team-comparison-bar-value right">{awayValue.toFixed(1)}</span>
    </div>
  );
};

const TeamComparisonChart = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const [homeTeamStats, setHomeTeamStats] = useState({});
  const [awayTeamStats, setAwayTeamStats] = useState({});
  const [activeButton, setActiveButton] = useState('offensive');
  const { keycloak, token } = useKeycloak(); // Use Keycloak context to get token

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const homeTeamFullName = reverseTeamAbbreviation[homeTeamAbbr];
  const awayTeamFullName = reverseTeamAbbreviation[awayTeamAbbr];

  const homeTeamInfo = teamsDict[homeTeamFullName];
  const awayTeamInfo = teamsDict[awayTeamFullName];

  const features = {
    offensive: [
      { key: 'pointsScored', label: 'Points Per Game', dataKey: 'team_pts_season_average' },
      { key: 'assistsPerGame', label: 'Assists Per Game', dataKey: 'team_ast_season_average' },
      { key: 'reboundsPerGame', label: 'Rebounds Per Game', dataKey: 'team_reb_season_average' },
      { key: 'stealsPerGame', label: 'Steals Per Game', dataKey: 'team_stl_season_average' },
      { key: 'threePtPerGame', label: '3PT%', dataKey: 'team_fg3_pct' },
      { key: 'freeThrowPercentage', label: 'FT%', dataKey: 'team_ft_pct' },
      { key: 'fieldGoalPercentage', label: 'FG%', dataKey: 'team_fg_pct' },
      { key: 'blocksPerGame', label: 'Blocks Per Game', dataKey: 'team_blk_season_average' },
      { key: 'turnoversPerGame', label: 'Turnovers Per Game', dataKey: 'team_tov_season_average' },
    ],
    defensive: [
      { key: 'pointsAllowed', label: 'Allowed Points Per Game', dataKey: 'team_pts_allowed_season_average' },
      { key: 'assistsAllowed', label: 'Allowed Assists Per Game ', dataKey: 'team_ast_allowed_season_average' },
      { key: 'reboundsAllowed', label: 'Allowed Rebounds Per Game', dataKey: 'team_reb_allowed_season_average' },
      { key: 'stealsAllowed', label: 'Allowed Steals Per Game', dataKey: 'team_stl_allowed_season_average' },
      { key: 'threePtAllowed', label: 'Allowed 3PT%', dataKey: 'team_fg3_pct_allowed' },
      { key: 'freeThrowAllowed', label: 'Allowed FT%A', dataKey: 'team_ft_pct_allowed' },
      { key: 'fieldGoalAllowed', label: 'Allowed FG%A', dataKey: 'team_fg_pct_allowed' },
      { key: 'blocksAllowed', label: 'Allowed Blocks Per Game', dataKey: 'team_blk_allowed_season_average' },
      { key: 'turnoversForced', label: 'Turnovers Forced Per Game', dataKey: 'team_tov_allowed_season_average' },
    ],
  };

  const homeTeamColor = homeTeamInfo.colors[0] || '#1d70b7';
  const awayTeamColor = selectMostDistinctColor(homeTeamColor, awayTeamInfo.colors.slice(0, 2));

  useEffect(() => {
    const fetchTeamStats = async (teamId) => {
      const metrics = [
        'team_fgm_season_average',
        'team_fga_season_average',
        'team_fg3m_season_average',
        'team_fg3a_season_average',
        'team_ftm_season_average',
        'team_fta_season_average',
        'team_reb_season_average',
        'team_ast_season_average',
        'team_tov_season_average',
        'team_stl_season_average',
        'team_blk_season_average',
        'team_pts_season_average',
        'team_fgm_allowed_season_average',
        'team_fga_allowed_season_average',
        'team_fg3m_allowed_season_average',
        'team_fg3a_allowed_season_average',
        'team_ftm_allowed_season_average',
        'team_fta_allowed_season_average',
        'team_reb_allowed_season_average',
        'team_ast_allowed_season_average',
        'team_tov_allowed_season_average',
        'team_stl_allowed_season_average',
        'team_blk_allowed_season_average',
        'team_pts_allowed_season_average',
      ];

      const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
      const endpoint = `team_overall_stats/?team_id=${teamId}&${queryString}`;

      try {
        const response = await fetchData(endpoint, token);
        return response;
      } catch (error) {
        console.error('Error fetching team stats:', error);
        return {};
      }
    };

    const calculatePercentages = (stats) => {
      if (!stats) return {};
      return {
        ...stats,
        team_fg_pct: stats.team_fgm_season_average && stats.team_fga_season_average
          ? (stats.team_fgm_season_average / stats.team_fga_season_average) * 100
          : 0,
        team_fg3_pct: stats.team_fg3m_season_average && stats.team_fg3a_season_average
          ? (stats.team_fg3m_season_average / stats.team_fg3a_season_average) * 100
          : 0,
        team_ft_pct: stats.team_ftm_season_average && stats.team_fta_season_average
          ? (stats.team_ftm_season_average / stats.team_fta_season_average) * 100
          : 0,
        team_fg_pct_allowed: stats.team_fgm_allowed_season_average && stats.team_fga_allowed_season_average
          ? (stats.team_fgm_allowed_season_average / stats.team_fga_allowed_season_average) * 100
          : 0,
        team_fg3_pct_allowed: stats.team_fg3m_allowed_season_average && stats.team_fg3a_allowed_season_average
          ? (stats.team_fg3m_allowed_season_average / stats.team_fg3a_allowed_season_average) * 100
          : 0,
        team_ft_pct_allowed: stats.team_ftm_allowed_season_average && stats.team_fta_allowed_season_average
          ? (stats.team_ftm_allowed_season_average / stats.team_fta_allowed_season_average) * 100
          : 0,
      };
    };

    const fetchDataAndSetStats = async () => {
      const homeTeamId = teamsDict[homeTeamFullName].team_id;
      const awayTeamId = teamsDict[awayTeamFullName].team_id;

      const [homeData, awayData] = await Promise.all([
        fetchTeamStats(homeTeamId),
        fetchTeamStats(awayTeamId)
      ]);

      setHomeTeamStats(calculatePercentages(homeData));
      setAwayTeamStats(calculatePercentages(awayData));
    };

    fetchDataAndSetStats();
  }, [homeTeamFullName, awayTeamFullName, token]);

  return (
    <div className="team-comparison-chart">
      <div className="team-comparison-chart-buttons">
        <button
          className={`team-comparison-chart-button ${activeButton === 'offensive' ? 'active' : ''}`}
          onClick={() => setActiveButton('offensive')}
        >
          Offensive
        </button>
        <button
          className={`team-comparison-chart-button ${activeButton === 'defensive' ? 'active' : ''}`}
          onClick={() => setActiveButton('defensive')}
        >
          Defensive
        </button>
      </div>
      <div className="team-comparison-features-container">
        {features[activeButton].map((feature) => (
          <FeatureBar
            key={feature.key}
            label={feature.label}
            homeValue={homeTeamStats[feature.dataKey] || 0}
            awayValue={awayTeamStats[feature.dataKey] || 0}
            homeColor={homeTeamColor}
            awayColor={awayTeamColor}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamComparisonChart;
