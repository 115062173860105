import React, { useState, useEffect, useRef } from "react";
import TopPickCard from "./TopPickCard";
import { fetchData } from "../api"; // your fetch utility
import { useKeycloak } from "../KeycloakProvider";
import "./TopPickCards.css";

/**
 * X/15 symmetrical filter:
 * If user types x=12 => keep picks with over_count >=12 or <=3 (the symmetrical range).
 */
function passesX15Range(overCount, xValue) {
  if (!xValue) return true;
  const xNum = parseInt(xValue, 10);
  if (isNaN(xNum)) return true;
  const lowerBound = 15 - xNum;
  return overCount >= xNum || overCount <= lowerBound;
}

/** Symmetrical rank so 0/15 & 15/15 come first. */
function symmetricalRank(overCount) {
  return Math.min(overCount, 15 - overCount);
}

/** Summation logic for a single log row given a multi-feature (e.g. "pts_ast"). */
function computeLogValue(log, feature) {
  const f = (feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += log.pts;
  if (f.includes("reb")) val += log.reb;
  if (f.includes("ast")) val += log.ast;
  return val;
}

/** Summation logic for rolling columns if the feature is combined. */
function sumRollingMean(pick) {
  const f = (pick.feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += (pick.pts_vs_opponent_rolling_2_mean || 0);
  if (f.includes("reb")) val += (pick.reb_vs_opponent_rolling_2_mean || 0);
  if (f.includes("ast")) val += (pick.ast_vs_opponent_rolling_2_mean || 0);
  return parseFloat(val.toFixed(2));
}

/** Summation for home/away rolling 15 columns. */
function sumHomeAwayRolling15(pick) {
  const f = (pick.feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += (pick.home_or_away_rolling_pts_15_games || 0);
  if (f.includes("reb")) val += (pick.home_or_away_rolling_reb_15_games || 0);
  if (f.includes("ast")) val += (pick.home_or_away_rolling_ast_15_games || 0);
  return parseFloat(val.toFixed(2));
}

export default function TopPickCards() {
  const { token, roles } = useKeycloak();
  // Check premium
  const isPremiumUser = roles.includes("premium");

  // Top picks data
  const [topPicks, setTopPicks] = useState([]);
  // Last15 logs data
  const [last15Logs, setLast15Logs] = useState([]);

  // Filters
  const [featureFilter, setFeatureFilter] = useState([]);
  const [featureDropdownOpen, setFeatureDropdownOpen] = useState(false);
  const [x15Filter, setX15Filter] = useState("");
  const [overUnderFilter, setOverUnderFilter] = useState("All");
  const [oddsMin, setOddsMin] = useState("");
  const [oddsMax, setOddsMax] = useState("");
  const [probabilityFilter, setProbabilityFilter] = useState(60);
  const multiSelectRef = useRef(null);

  /**
   * 1) Always define the effect at the top-level,
   *    but only do the data fetching inside if user is premium.
   */
  useEffect(() => {
    if (!isPremiumUser || !token) return; 
    // If user isn't premium, or no token, skip fetching

    async function loadData() {
      try {
        const [picksData, logsData] = await Promise.all([
          fetchData("top-picks/", token),         // Returns picks
          fetchData("top-picks-last15/", token),  // The logs data
        ]);
        setTopPicks(picksData || []);
        setLast15Logs(logsData || []);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }
    loadData();
  }, [isPremiumUser, token]);

  /**
   * 2) If user is NOT premium => return the "premium only" message.
   *    This return occurs AFTER the hook is declared.
   */
  if (!isPremiumUser) {
    return (
      <div style={{ textAlign: 'center', margin: '2rem' }}>
        <p style={{ fontSize: '1.2rem', fontFamily:'var(--font-main)', marginBottom: '1rem' }}>
          This page is only for premium members.
        </p>
        <button
          onClick={() => (window.location.href = "/Subscription")}
          style={{
            padding: '0.75rem 1.5rem',
            backgroundColor: 'var(--main-color)',
            fontFamily:'var(--font-main)',
            border: 'none',
            borderRadius: '4px',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
        >
          Buy Premium
        </button>
      </div>
    );
  }

  // 3) Merging top picks + logs
  const merged = topPicks.map((pick) => {
    // Filter logs for matching player
    const logs = last15Logs
      .filter((lg) => lg.player_id === pick.player_id)
      .sort((a, b) => new Date(a.game_date) - new Date(b.game_date));

    // Calculate over_count
    let overCount = 0;
    const barValues = logs.map((lg) => {
      const val = computeLogValue(lg, pick.feature);
      if (val > (pick.threshold || 0)) overCount++;
      return {
        date: new Date(lg.game_date),
        value: val,
        pts: lg.pts,
        reb: lg.reb,
        ast: lg.ast,
        min: lg.min || 0,
      };
    });

    // minutesDiff => L5 vs L15
    const minutesArr = barValues.map((bv) => bv.min);
    const l15MinAvg = minutesArr.length
      ? minutesArr.reduce((acc, m) => acc + m, 0) / minutesArr.length
      : 0;
    const last5Min = minutesArr.slice(-5);
    const l5MinAvg = last5Min.length
      ? last5Min.reduce((acc, m) => acc + m, 0) / last5Min.length
      : 0;
    const minutesDiff = parseFloat((l5MinAvg - l15MinAvg).toFixed(2));

    // If your backend returns `total_games_without` in the pick object:
    const gamesWithout = pick.total_games_without || 0;

    return {
      ...pick,
      logs: barValues,
      over_count: overCount,
      minutes_diff: minutesDiff,
      rolling_mean_sum: sumRollingMean(pick),
      rolling_home_sum: sumHomeAwayRolling15(pick),
      total_games_without: gamesWithout,
    };
  });

  // 4) Filtering
  const filtered = merged.filter((p) => {
    // Feature filter
    if (featureFilter.length > 0) {
      if (!featureFilter.includes("all")) {
        const matched = featureFilter.some(
          (f) => f.toLowerCase() === p.feature.toLowerCase()
        );
        if (!matched) return false;
      }
    }

    // X/15 symmetrical
    if (!passesX15Range(p.over_count || 0, x15Filter)) {
      return false;
    }

    // Probability
    if ((p.feature_probability || 0) * 100 < probabilityFilter) return false;

    // Over/Under
    if (overUnderFilter !== "All") {
      const isOver = (p.feature_prediction || 0) > (p.threshold || 0);
      if (overUnderFilter === "over" && !isOver) return false;
      if (overUnderFilter === "under" && isOver) return false;
    }

    // Odds
    if (oddsMin && p.odds < parseFloat(oddsMin)) return false;
    if (oddsMax && p.odds > parseFloat(oddsMax)) return false;

    return true;
  });

  // 5) Sort => symmetrical rank => 0/15 & 15/15 come first
  const sorted = [...filtered].sort((a, b) => {
    const rankA = symmetricalRank(a.over_count || 0);
    const rankB = symmetricalRank(b.over_count || 0);
    return rankA - rankB; // ascending => 0 => top
  });

  // Multi-select toggling
  const toggleFeatureDropdown = () => setFeatureDropdownOpen(!featureDropdownOpen);

  const handleFeatureSelection = (val) => {
    if (val === "all") {
      if (featureFilter.includes("all")) {
        setFeatureFilter([]);
      } else {
        setFeatureFilter(["all"]);
      }
      return;
    }
    if (featureFilter.includes("all")) {
      setFeatureFilter([]);
    }
    if (featureFilter.includes(val)) {
      setFeatureFilter(featureFilter.filter((f) => f !== val));
    } else {
      setFeatureFilter([...featureFilter, val]);
    }
  };

  return (
    <div style={{ maxWidth: "1440px", margin: "0 auto", padding: "20px" }}>
      {/* Filters row */}
      <div
        className="top-cards-filters"
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "1rem" }}
      >
        {/* Feature multi-select */}
        <div
          className="top-cards-filter-item top-cards-multi-select-wrapper"
          ref={multiSelectRef}
        >
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>
            Features:
          </label>
          <div
            className="top-cards-multi-select-control"
            onClick={toggleFeatureDropdown}
            style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}
          >
            <span>
              {featureFilter.includes("all")
                ? "All Features"
                : featureFilter.length > 0
                ? featureFilter.join(", ").toUpperCase()
                : "All Features"}
            </span>
            <i className="top-cards-arrow"></i>
          </div>
          {featureDropdownOpen && (
            <div className="top-cards-multi-select-options">
              {/* "ALL" option */}
              <div className="top-cards-option">
                <label>
                  <input
                    type="checkbox"
                    checked={featureFilter.includes("all")}
                    onChange={() => handleFeatureSelection("all")}
                  />
                  ALL
                </label>
              </div>
              {["pts", "reb", "ast", "pts_ast", "pts_reb", "ast_reb", "pts_ast_reb"].map(
                (feat) => (
                  <div key={feat} className="top-cards-option">
                    <label>
                      <input
                        type="checkbox"
                        checked={featureFilter.includes(feat)}
                        onChange={() => handleFeatureSelection(feat)}
                      />
                      {feat.toUpperCase()}
                    </label>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        <div className="top-cards-filter-item">
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>X/15:</label>
          <input
            type="number"
            placeholder="12 =>"
            value={x15Filter}
            onChange={(e) => setX15Filter(e.target.value)}
            style={{ width: "90px", fontFamily: "var(--font-main)" }}
          />
        </div>

        <div className="top-cards-filter-item">
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>
            Confidence
          </label>
          <input
            type="number"
            value={probabilityFilter}
            onChange={(e) => setProbabilityFilter(parseFloat(e.target.value))}
            min="0"
            max="100"
            style={{ width: "90px", fontFamily: "var(--font-main)" }}
          />
        </div>

        <div className="top-cards-filter-item">
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>
            Over/Under:
          </label>
          <select
            value={overUnderFilter}
            onChange={(e) => setOverUnderFilter(e.target.value)}
            style={{ fontFamily: "var(--font-main)", color: "#5a2d82" }}
          >
            <option value="All">All</option>
            <option value="over">Over</option>
            <option value="under">Under</option>
          </select>
        </div>

        {/*
        <div className="top-cards-filter-item">
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>
            Odds Min:
          </label>
          <input
            type="number"
            step="0.01"
            value={oddsMin}
            onChange={(e) => setOddsMin(e.target.value)}
            placeholder="1.5"
            style={{ width: "60px", fontFamily: "var(--font-main)" }}
          />
        </div>

        <div className="top-cards-filter-item">
          <label style={{ color: "#5a2d82", fontFamily: "var(--font-main)" }}>
            Odds Max:
          </label>
          <input
            type="number"
            step="0.01"
            value={oddsMax}
            onChange={(e) => setOddsMax(e.target.value)}
            placeholder="2.5"
            style={{ width: "60px", fontFamily: "var(--font-main)" }}
          />
        </div>
        */}
      </div>

      {/* Cards */}
      <div className="top-pick-cards-container">
        {sorted.length > 0 ? (
          sorted.map((p, idx) => {
            const barValues = p.logs || [];
            const valuesArray = barValues.map((obj) => obj.value);
            const maxValue = Math.max(...valuesArray, p.threshold || 0, 1);

            // scale
            const scaledBars = barValues.map((obj) => ({
              ...obj,
              scaledValue: maxValue ? (obj.value / maxValue) * 100 : 0,
            }));

            // L15 average
            const sumVal = valuesArray.reduce((acc, v) => acc + v, 0);
            const l15 = valuesArray.length
              ? (sumVal / valuesArray.length).toFixed(1)
              : "0.0";

            // Last 5
            const last5 = valuesArray.slice(-5);
            const sum5 = last5.reduce((acc, v) => acc + v, 0);
            const l5 = last5.length ? (sum5 / last5.length).toFixed(1) : "0.0";

            let percentageChange = "0.0";
            if (parseFloat(l15) !== 0) {
              percentageChange = (
                ((parseFloat(l5) - parseFloat(l15)) / parseFloat(l15)) *
                100
              ).toFixed(1);
            }

            return (
              <TopPickCard
                key={idx}
                // FRONT
                playerName={p.player_name || "Unknown"}
                feature={p.feature}
                lineValue={p.threshold || 0}
                predicted={p.feature_prediction || 0}
                probability={
                  p.feature_probability
                    ? (p.feature_probability * 100).toFixed(1) + "%"
                    : "0%"
                }
                l15={l15}
                l5Average={parseFloat(l5)}
                percentageChange={percentageChange}
                overThreshold={p.over_count || 0}
                totalBars={valuesArray.length}
                barValues={scaledBars}

                // BACK
                impact={p.impact || 0}
                nextOpponent={p.next_opponent || "TBD"}
                rollingValue2Mean={p.rolling_mean_sum || 0}
                rollingHome15={p.rolling_home_sum || 0}
                minutesDiff={p.minutes_diff || 0}
                injuredPlayersDetails={p.injured_players_details || ""}
                gamesWithout={p.total_games_without || 0}
              />
            );
          })
        ) : (
          <p>No picks match filters.</p>
        )}
      </div>
    </div>
  );
}
