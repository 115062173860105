export const fetchData = async (endpoint, token = null, method = "GET", body = null) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // Set up headers dynamically based on whether a token is available
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    // Make the API call
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : null, // Include body only if provided
    });

    // Handle non-OK responses
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed: ${response.status} ${errorText}`);
    }

    // Parse and return the response JSON
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message || error);
    throw error;
  }
};
