import React from 'react';
import './MatchupPlayerInfo.css';

const MatchupPlayerInfo = ({ playerData, color1, color2, color3 }) => {
  // Default values for when playerData is incomplete or missing
  const defaultName = 'N/A';
  const defaultPosition = 'N/A';
  const defaultNumber = '00';
  const defaultImageSrc = '/api/placeholder/200/200';
  const defaultStats = '0.0';

  // Function to format the name based on your specifications
  const formatPlayerName = (name) => {
    if (!name) return defaultName;

    const nameParts = name.split(' ');
    const lastName = nameParts[nameParts.length - 1];
    const firstInitial = nameParts[0][0];

    if (lastName.length < 8) {
      return `${firstInitial}. ${lastName}`;
    } else {
      return lastName;
    }
  };

  // Check if playerData and playerData.name exist before trying to access them
  const formattedName = playerData && playerData.name
    ? formatPlayerName(playerData.name)
    : defaultName;

  return (
    <div className="matchup-players-player-info">
      <div className="matchup-players-color-split">
        {/* Position */}
        <div
          className="matchup-players-color-box"
          style={{ backgroundColor: color2, color: color1 || '#000000' }}
        >
          <span className="matchup-players-position">{playerData?.position || defaultPosition}</span>
        </div>

        {/* Number */}
        <div
          className="matchup-players-color-box"
          style={{ backgroundColor: color3, color: color1 || '#000000' }}
        >
          <span className="matchup-players-number">#{playerData?.number || defaultNumber}</span>
        </div>

        <img
          src={playerData?.imageSrc || defaultImageSrc}
          alt={playerData?.name || 'Player'}
          className="matchup-players-player-image"
        />
      </div>

      {/* Formatted Name */}
      <div className="matchup-players-player-name" style={{ backgroundColor: color1 || '#000000' }}>
        {formattedName}
      </div>

      {/* Stats */}
      <div className="matchup-players-player-stats" style={{ backgroundColor: color1 || '#000000' }}>
        <div className="matchup-players-stat-label">PPG</div>
        <div className="matchup-players-stat-value">{playerData?.ppg || defaultStats}</div>
        <div className="matchup-players-stat-label">APG</div>
        <div className="matchup-players-stat-value">{playerData?.apg || defaultStats}</div>
        <div className="matchup-players-stat-label">RPG</div>
        <div className="matchup-players-stat-value">{playerData?.rpg || defaultStats}</div>
      </div>
    </div>
  );
};

export default MatchupPlayerInfo;
