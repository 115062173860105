import React, { useState, useEffect } from 'react';
import './MatchupPlayerPredictions.css';
import { fetchData } from '../api';
import { useKeycloak } from '../KeycloakProvider';

const PlayerPredictions = ({ color1, playerData }) => {
  const [predictions, setPredictions] = useState(null);
  const { token } = useKeycloak();

  useEffect(() => {
    const fetchPredictions = async () => {
      if (!playerData || !playerData.playerId) {
        setPredictions(null);
        return;
      }

      try {
        // Fetch predictions for the player
        const queryParams = new URLSearchParams({
          latest: 'true',
          player_name: playerData.player_name,
        });

        const data = await fetchData(
          `player-feature-predictions/filter_predictions/?${queryParams.toString()}`,
          token
        );

        // Map predictions to features
        const featureMap = {
          pts: 'Pts',
          reb: 'Reb',
          ast: 'Ast',
        };

        const predData = {
          Pts: { pred: 'n/a', prob: 'n/a' },
          Reb: { pred: 'n/a', prob: 'n/a' },
          Ast: { pred: 'n/a', prob: 'n/a' },
        };

        data.forEach((item) => {
          const prop = featureMap[item.feature];
          if (prop) {
            predData[prop] = {
              pred: item.feature_prediction
                ? Math.round(item.feature_prediction).toString()
                : 'n/a',
              prob: item.feature_probability
                ? `${(item.feature_probability * 100).toFixed(1)}%`
                : 'n/a',
            };
          }
        });

        setPredictions(predData);
      } catch (error) {
        console.error('Failed to fetch player predictions:', error);
        setPredictions(null);
      }
    };

    if (token) {
      fetchPredictions();
    } else {
      console.error('Token not available');
      setPredictions(null);
    }
  }, [token, playerData]);

  if (!predictions) {
    return (
      <div className="matchup-players-predictions">
        <div className="matchup-players-predictions-title">Predictions</div>
        <p>No data available for this player.</p>
      </div>
    );
  }

  return (
    <div className="matchup-players-predictions">
      <div className="matchup-players-predictions-title">Predictions</div>
      <table className="matchup-players-predictions-table">
        <thead>
          <tr>
            <th>Prop</th>
            <th>Pred</th>
            <th>Prob</th>
          </tr>
        </thead>
        <tbody>
          {['Pts', 'Reb', 'Ast'].map((prop) => (
            <tr key={prop}>
              <td>{prop}</td>
              <td>{predictions[prop]?.pred || 'n/a'}</td>
              <td>{predictions[prop]?.prob || 'n/a'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerPredictions;
