// Import teamsDict from TeamInfo
import { teamsDict } from './TeamInfo'; // Ensure this path is correct

// Import player data directly from the JSON file
import playerInfoData from './player_info.json'; // Adjust the path accordingly

// Import matchups from the previously defined file
import { matchups } from './MatchupInfo'; // Adjust the path accordingly

// Function to generate random data for a player
const generateRandomData = () => ({
  fgPercentage: `${Math.floor(45 + Math.random() * 20)}%`, // Random FG% between 45% and 65%
  mpg: (30 + Math.random() * 10).toFixed(1),               // Random MPG between 30.0 and 40.0
  valueChange: '55%',  // Random change between -5% and +5%
  home_or_away: Math.random() > 0.5 ? 1 : 0,               // Randomly 1 (home) or 0 (away)
  time: `${['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][Math.floor(Math.random() * 7)]}, ${
    Math.floor(Math.random() * 12 + 1)
  }:${Math.floor(Math.random() * 60)
    .toString()
    .padStart(2, '0')} ${Math.random() > 0.5 ? 'am' : 'pm'}`, // Random time
  number: Math.floor(Math.random() * 99),                   // Random jersey number between 0 and 99
});

// Function to generate a player object and find the matchup
const generatePlayer = (playerInfo) => {
  const { player_id, player_name, position, team_name } = playerInfo;
  const imageSrc = `player_logos/${player_id}.png`;
  const lastWordOfTeamName = team_name.split(' ').pop(); // Get the last word of the team name
  
  // Get the team info using the last word of the team name
  const teamInfo = teamsDict[lastWordOfTeamName] || { logo: 'default_logo.png', colors: ['#000000', '#FFFFFF'] };

  // Find the matchup for the player's team
  const matchup = matchups.find((m) => m.matchup.includes(lastWordOfTeamName));
  let opponentFullName, opponentInfo;

  if (matchup) {
    const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
    opponentFullName = lastWordOfTeamName === homeTeam ? awayTeam : homeTeam;
    opponentInfo = teamsDict[opponentFullName] || { logo: 'default_logo.png' };
  } else {
    // Fallback to a random opponent if no matchup is found
    const opponentTeams = Object.keys(teamsDict).filter((team) => team !== team_name);
    opponentFullName = opponentTeams[Math.floor(Math.random() * opponentTeams.length)];
    opponentInfo = teamsDict[opponentFullName] || { logo: 'default_logo.png' };
  }

  const randomData = generateRandomData();

  return {
    playerId: player_id,
    name: player_name,
    imageSrc: imageSrc,
    fgPercentage: randomData.fgPercentage,
    mpg: randomData.mpg,
    valueChange: randomData.valueChange,
    teamLogoSrc: teamInfo.logo,
    teamColors: teamInfo.colors,
    opponentLogoSrc: opponentInfo.logo,
    teamName: lastWordOfTeamName,
    opponentName: opponentFullName,
    home_or_away: randomData.home_or_away,
    time: randomData.time,
    position: position,
    number: randomData.number,
  };
};

// Generate the players object synchronously
export const players = {};

playerInfoData.forEach((playerInfo) => {
  players[playerInfo.player_id] = generatePlayer(playerInfo);
});


