import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { fetchDataTeams, fetchTeamPlayers } from './data/TeamPointsDrilldownData';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './TeamPointsDrilldown.css';
import teamAbbreviationToFullName from '../components/TeamFullNameDictionary.js';
import { useKeycloak } from '../KeycloakProvider';

const TeamPointsDrilldown = ({ selectedFeature }) => {
  const { teamColors, selectedTeam } = usePlayerColors();
  const chartRef = useRef(null);
  const [cachedTeamsData, setCachedTeamsData] = useState({});
  const [inDrillDown, setInDrillDown] = useState(false);
  const [currentTeamAbbreviation, setCurrentTeamAbbreviation] = useState(null);
  const [currentGameDate, setCurrentGameDate] = useState(null);
  const [showTotal, setShowTotal] = useState(true);
  const { token } = useKeycloak();
  const titleFeatureMap = {
    Total: 'Points',
    AST: 'Assists',
    REB: 'Rebounds',
    BLK: 'Blocks',
    STL: 'Steals',
    'Pts+Ast': 'Points + Assists',
    'Pts+Reb': 'Points + Rebounds',
    'Ast+Reb': 'Assists + Rebounds',
    'Pts+Ast+Reb': 'Points + Assists + Rebounds',
  };
  
  // Get the display label for the selected feature
  const titleFeatureLabel = titleFeatureMap[selectedFeature] || selectedFeature;
  const fontMain = getComputedStyle(document.documentElement).getPropertyValue('--font-main').trim();
  const formatDate = (date) => {
    const d = new Date(date);
    const year = String(d.getFullYear()).slice(-2);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const destroyChart = () => {
    if (chartRef.current) {
      const existingChart = echarts.getInstanceByDom(chartRef.current);
      if (existingChart) {
        existingChart.dispose();
      }
    }
  };

  const goBackToTeams = useCallback(async () => {
    setInDrillDown(false);
    setCurrentGameDate(null);

    let updatedTeamsData;

    if (cachedTeamsData[currentTeamAbbreviation]) {
      updatedTeamsData = cachedTeamsData[currentTeamAbbreviation];
    } else {
      updatedTeamsData = await fetchDataTeams(currentTeamAbbreviation, token);
      updatedTeamsData = updatedTeamsData.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
      setCachedTeamsData((prevCache) => ({
        ...prevCache,
        [currentTeamAbbreviation]: updatedTeamsData,
      }));
    }
    

    updatedTeamsData.forEach((item) => {
      item.team_points_ast = item.team_points + item.team_ast;
      item.team_points_reb = item.team_points + item.team_reb;
      item.team_ast_reb = item.team_ast + item.team_reb;
      item.team_points_ast_reb = item.team_points + item.team_ast + item.team_reb;
    });

    const featureMap = {
      Total: 'team_points',
      AST: 'team_ast',
      REB: 'team_reb',
      BLK: 'team_blk',
      STL: 'team_stl',
      'Pts+Ast': 'team_points_ast',
      'Pts+Reb': 'team_points_reb',
      'Ast+Reb': 'team_ast_reb',
      'Pts+Ast+Reb': 'team_points_ast_reb',
      Points: ['team_fg2m', 'team_fg3m', 'team_ftm'],
    };

    const feature = featureMap[selectedFeature] || 'team_points';

    const seriesData = createSeries(updatedTeamsData, feature);
    
    const updatedTeamOption = {
      xAxis: {
        type: 'category',
        data: updatedTeamsData.map((item) => item.game_date),
        axisLabel: {
          rotate: 0,
          align: 'center',
          fontSize: 11.5,
          margin: 10,
          formatter: (value, index) => {
            const dateFormatted = formatDate(value);
            const opponent = updatedTeamsData[index].opponent;
            return `${dateFormatted}\n${opponent}`;
          },
        },
      },
      yAxis: {
        type: 'value',
        minInterval: 1,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        top: '12%',
        containLabel: true,
      },
      legend: {
        top: '0%',
        left: '3%',
        data: seriesData.map((s) => s.name),
        textStyle: {
          color: '#000',
          fontFamily: fontMain,  // Apply the font-main CSS variable
        },
        itemWidth: 20,
        itemHeight: 14,
        itemGap: 10,
        icon: 'rect',
        z: 10,
      },
      series: seriesData,
    };

    const myChart = echarts.getInstanceByDom(chartRef.current);
    if (updatedTeamOption && updatedTeamOption.series && updatedTeamOption.series.length > 0) {
      myChart.setOption(updatedTeamOption);
    }
    enableChartClick();
  }, [cachedTeamsData, selectedFeature, teamColors, currentTeamAbbreviation, showTotal]);

  const createSeries = (data, feature, isPlayerData = false) => {
    const series = [];

    const filteredData = data.filter((item) => (isPlayerData ? item[feature] : item[feature]) >= 2);
    console.log('filteredData',filteredData);
    if (showTotal && !inDrillDown) {
      series.push({
        name: selectedFeature,
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          formatter: '{c}',
        },
        data: filteredData.map((item) => ({
          value: isPlayerData ? item[feature] : item[feature],
          name: item.game_date || item.player_name,
          opponent: item.opponent,
        })),
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        itemStyle: {
          color: teamColors[0],
        },
      });
    } else {
      if (selectedFeature === 'Total') {
        series.push({
          name: 'FG2M',
          type: 'bar',
          stack: 'total',
          data: filteredData.map((item) => (isPlayerData ? item.fg2m : item.team_fg2m)),
          itemStyle: { color: teamColors[0] },
          label: { show: true, position: 'insideTop', formatter: '{c}' },
        });
        series.push({
          name: 'FG3M',
          type: 'bar',
          stack: 'total',
          data: filteredData.map((item) => (isPlayerData ? item.fg3m : item.team_fg3m)),
          itemStyle: { color: teamColors[1] || '#91CC75' },
          label: { show: true, position: 'insideTop', formatter: '{c}' },
        });
        series.push({
          name: 'FTM',
          type: 'bar',
          stack: 'total',
          data: filteredData.map((item) => (isPlayerData ? item.ftm : item.team_ftm)),
          itemStyle: { color: teamColors[2] || '#FAC858' },
          label: { show: true, position: 'insideTop', formatter: '{c}' },
        });
      } else if (['Pts+Ast', 'Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
        if (['Pts+Ast', 'Pts+Ast+Reb', 'Pts+Reb'].includes(selectedFeature)) {
          series.push({
            name: 'Pts',
            type: 'bar',
            stack: 'total',
            data: filteredData.map((item) => (isPlayerData ? item.pts : item.team_points)),
            itemStyle: { color: teamColors[0] },
            label: { show: true, position: 'insideTop', formatter: '{c}' },
          });
        }
        if (['Ast+Reb', 'Pts+Ast', 'Pts+Ast+Reb'].includes(selectedFeature)) {
          series.push({
            name: 'Ast',
            type: 'bar',
            stack: 'total',
            data: filteredData.map((item) => (isPlayerData ? item.ast : item.team_ast)),
            itemStyle: { color: teamColors[1] || '#91CC75' },
            label: { show: true, position: 'insideTop', formatter: '{c}' },
          });
        }
        if (['Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
          series.push({
            name: 'Reb',
            type: 'bar',
            stack: 'total',
            data: filteredData.map((item) => (isPlayerData ? item.reb : item.team_reb)),
            itemStyle: { color: teamColors[2] || '#FAC858' },
            label: { show: true, position: 'insideTop', formatter: '{c}' },
          });
        }
      } else {
        series.push({
          name: selectedFeature,
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: '{c}',
          },
          data: filteredData.map((item) => ({
            value: isPlayerData ? item[feature] : item[feature],
            name: item.game_date || item.player_name,
            opponent: item.opponent,
          })),
          universalTransition: {
            enabled: true,
            divideShape: 'clone',
          },
          itemStyle: {
            color: teamColors[0],
          },
        });
      }
    }

    return series;
  };

  const initializeChart = useCallback(
    async (teamAbbreviation, data = null, isPlayerData = false, gameDate = null) => {
      destroyChart();

      const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

      let teamsData = data || cachedTeamsData[teamAbbreviation];

      if (!teamsData) {
        teamsData = await fetchDataTeams(teamAbbreviation, token);
        teamsData = teamsData.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
        setCachedTeamsData((prevCache) => ({
          ...prevCache,
          [teamAbbreviation]: teamsData,
        }));
      }

      teamsData.forEach((item) => {
        item.team_points_ast = item.team_points + item.team_ast;
        item.team_points_reb = item.team_points + item.team_reb;
        item.team_ast_reb = item.team_ast + item.team_reb;
        item.team_points_ast_reb = item.team_points + item.team_ast + item.team_reb;
      });

      const featureMap = {
        Total: 'team_points',
        AST: 'team_ast',
        REB: 'team_reb',
        BLK: 'team_blk',
        STL: 'team_stl',
        'Pts+Ast': 'team_points_ast',
        'Pts+Reb': 'team_points_reb',
        'Ast+Reb': 'team_ast_reb',
        'Pts+Ast+Reb': 'team_points_ast_reb',
        Points: ['team_fg2m', 'team_fg3m', 'team_ftm'],
      };

      const feature = featureMap[selectedFeature] || 'team_points';

      const xAxisData = isPlayerData
        ? teamsData.filter((item) => item.game_date === gameDate).map((item) => item.player_name)
        : teamsData.map((item) => item.game_date);

      const seriesData = createSeries(teamsData, feature, isPlayerData);

      const teamOption = {
        id: 'teams',
        legend: {
          top: '0%',
          left: '3%',
          data: seriesData.map((s) => s.name),
          textStyle: {
            color: '#000',
            fontFamily: fontMain,  // Apply the font-main CSS variable
          },
          itemWidth: 20,
          itemHeight: 14,
          itemGap: 10,
          icon: 'rect',
          z: 10,
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            rotate: 0,
            align: 'center',
            fontSize: 11.5,
            margin: 10,
            formatter: (value, index) => {
              if (isPlayerData) return value;
              const dateFormatted = formatDate(value);
              const opponent = teamsData[index].opponent;
              return `${dateFormatted}\n${opponent}`;
            },
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: '12%',
          containLabel: true,
        },
        series: seriesData,
        graphic: inDrillDown
          ? [
              {
                type: 'text',
                right: 50,
                top: 20,
                style: {
                  text: 'Back',
                  fontSize: 18,
                  fill: 'grey',
                  cursor: 'pointer',
                },
                onclick: function () {
                  goBackToTeams();
                },
              },
            ]
          : [],
      };

      if (teamOption && teamOption.series && teamOption.series.length > 0) {
        myChart.setOption(teamOption);
      }
      enableChartClick();
      setCurrentTeamAbbreviation(teamAbbreviation);
    },
    [cachedTeamsData, selectedFeature, teamColors, currentTeamAbbreviation, showTotal]
  );

  const handleChartClick = useCallback(
    async (params) => {
      const dataItem = params.data;

      if (!dataItem || !dataItem.name) {
        console.error('Data item is missing or undefined:', dataItem);
        return;
      }

      setInDrillDown(true);
      setCurrentGameDate(dataItem.name);

      const teamPlayers = await fetchTeamPlayers(currentTeamAbbreviation, dataItem.name, token);

      const opponentFull = teamPlayers.length > 0 ? teamAbbreviationToFullName[teamPlayers[0].opponent] : 'BOS';

      const featureMap = {
        Total: 'pts',
        AST: 'ast',
        REB: 'reb',
        BLK: 'blk',
        STL: 'stl',
        'Pts+Ast': ['pts', 'ast'],
        'Pts+Reb': ['pts', 'reb'],
        'Ast+Reb': ['ast', 'reb'],
        'Pts+Ast+Reb': ['pts', 'ast', 'reb'],
        Points: ['fg2m', 'fg3m', 'ftm'],
      };

      const feature = featureMap[selectedFeature];

      const createPlayerSeries = () => {
        const series = [];

        const filteredPlayers = teamPlayers.filter((item) => {
          if (Array.isArray(feature)) {
            return feature.some((feat) => item[feat] >= 2);
          }
          return item[feature] >= 2;
        });

        if (Array.isArray(feature)) {
          feature.forEach((feat, index) => {
            series.push({
              name: feat.toUpperCase(),
              type: 'bar',
              stack: 'total',
              data: filteredPlayers.map((item) => item[feat]),
              itemStyle: { color: teamColors[index] || '#91CC75' },
              label: {
                show: true,
                position: 'insideTop',
                formatter: (params) => {
                  return params.value > 2 ? params.value : '';
                },
              },
            });
          });
        } else {
          series.push({
            name: selectedFeature,
            type: 'bar',
            data: filteredPlayers.map((item) => item[feature]),
            itemStyle: { color: teamColors[0] },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}',
            },
          });
        }

        return series;
      };

      destroyChart();

      const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

      const playerSeriesData = createPlayerSeries();

      const playerOption = {
        id: `team_${currentTeamAbbreviation}_${dataItem.name}`,
        title: {
          text: `${titleFeatureLabel} ${dataItem.name} vs ${opponentFull}`,
          left: '3%',
          top: '2%',
          textStyle: {
            fontSize: 18,
            fontWeight: '600',
            fontFamily: fontMain,  // Apply the font-main CSS variable

          },
        },
        legend: {
          top: '7%',
          left: '3%',
          data: playerSeriesData.map((s) => s.name),
          textStyle: {
            color: '#000',
            fontFamily: fontMain,  // Apply the font-main CSS variable
          },
          itemWidth: 20,
          itemHeight: 14,
          itemGap: 10,
          icon: 'rect',
          z: 20,
        },
        xAxis: {
          type: 'category',
          data: teamPlayers.map((item) => {
            const fullName = item.player_name;
            const nameParts = fullName.split(' ');
        
            if (nameParts.length > 1) {
              // For multi-part first names (e.g., Karl-Anthony)
              const firstNameParts = nameParts[0].split('-');
              const initials = firstNameParts.length > 1 
                ? firstNameParts.map(part => part[0]).join('') // e.g., "K" + "A"
                : nameParts[0][0]; // Single initial if not hyphenated
        
              const lastName = nameParts.slice(1).join(' ');
              return `${initials}. ${lastName}`;
            }
        
            // If the name has no last name or only one part, return it as-is
            return fullName;
          }),
          axisLabel: {
            fontSize: 14,
            rotate: 15,
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: '17%',
          containLabel: true,
        },
        series: playerSeriesData,
        graphic: [
          {
            type: 'text',
            right: 50,
            top: 30,
            style: {
              text: 'Back',
              fontSize: 18,
              fill: 'grey',
              cursor: 'pointer',
            },
            onclick: function () {
              goBackToTeams();
            },
          },
        ],
      };
      

      if (playerOption && playerOption.series && playerOption.series.length > 0) {
        myChart.setOption(playerOption);
      } else {
        console.error('Chart option is invalid:', playerOption);
      }
    },
    [currentTeamAbbreviation, selectedFeature, teamColors]
  );

  const enableChartClick = useCallback(() => {
    const myChart = echarts.getInstanceByDom(chartRef.current);
    if (myChart) {
      myChart.on('click', 'series', handleChartClick);
    }
  }, [handleChartClick]);

  useEffect(() => {
    if (selectedTeam) {
      setInDrillDown(false);
      setCurrentGameDate(null);
      const selectedTeamAbbreviation = teamNameToAbbreviation[selectedTeam];
      const cachedData = cachedTeamsData[selectedTeamAbbreviation];

      if (cachedData && Object.keys(cachedData).length >= 1) {
        destroyChart();

        initializeChart(selectedTeamAbbreviation, cachedData);
      } else {
        fetchDataTeams(selectedTeamAbbreviation, token).then((data) => {
          const sortedData = data.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
          setCachedTeamsData((prevCache) => ({
            ...prevCache,
            [selectedTeamAbbreviation]: sortedData,
          }));
          destroyChart();
          initializeChart(selectedTeamAbbreviation, sortedData);
        });
      }
    }
  }, [selectedTeam, selectedFeature, teamColors, initializeChart, cachedTeamsData]);

  useEffect(() => {
    if (chartRef.current && currentTeamAbbreviation) {
      const cachedData = cachedTeamsData[currentTeamAbbreviation];
      if (cachedData && Object.keys(cachedData).length >= 1) {
        destroyChart();
        if (inDrillDown) {
          initializeChart(currentTeamAbbreviation, cachedData, true, currentGameDate);
        } else {
          initializeChart(currentTeamAbbreviation, cachedData);
        }
      }
    }
  }, [showTotal, initializeChart, currentTeamAbbreviation, inDrillDown, cachedTeamsData, currentGameDate]);

  return (
    <div className="chart-wrapper">
      {!inDrillDown && (
        <div className="chart-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="chart-title">
            {`${titleFeatureLabel} Clickable Last 15`}
          </div>
          <div className="toggle-container">
            <span>{showTotal ? 'Total' : 'Stacked'}</span>
            <label className="switch" style={{'--primary-color': teamColors[0] }}>
              <input type="checkbox" onChange={() => setShowTotal((prev) => !prev)} checked={showTotal} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      )}
      <div ref={chartRef} className="chart-container" style={{ width: '100%', height: '100%' }} />
    </div>
  );
  
};

export default TeamPointsDrilldown;
