import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import { getPlayerId } from "../components/PlayerLookup";
import { abbreviationToTeamName } from "../components/TeamDictionary";
import "./TopPickCard.css";

export default function TopPickCard({
  // FRONT
  playerName,
  feature,
  lineValue,
  predicted,
  probability,
  l15,
  l5Average,
  percentageChange,
  overThreshold,
  totalBars,
  barValues,

  // BACK
  impact,
  nextOpponent,
  rollingValue2Mean,
  rollingHome15,
  minutesDiff,
  injuredPlayersDetails
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const navigate = useNavigate();
  const { setSelectedPlayer } = usePlayerColors();

  // If playerName is missing or not a string, fallback
  const safeName = typeof playerName === "string" ? playerName : "Unknown";

  // If name is very long, shorten to "X. Lastname"
  const formattedName =
    safeName.length > 16
      ? `${safeName.split(" ")[0][0]}. ${safeName.split(" ")[1] || ""}`
      : safeName;

  // Over/Under logic
  const isOver = (predicted || 0) > (lineValue || 0);
  const featureTitle = feature.toUpperCase();
  const statusLabel = isOver ? "Over" : "Under";
  const arrow = isOver ? "↑" : "↓";

  // Chart scaling
  const maxBarValue = Math.max(
    ...barValues.map((entry) => entry.value),
    lineValue || 1
  );
  const CHART_HEIGHT = 150;
  const scaleFactor = CHART_HEIGHT / maxBarValue;

  // Format minute difference and impact strings
  const minutesDiffStr =
    minutesDiff > 0 ? `+${minutesDiff.toFixed(2)}` : minutesDiff.toFixed(2);
  const impactStr = impact > 0 ? `+${impact.toFixed(2)}` : impact.toFixed(2);

  // Parse the injured players detail
  function parseInjuries(details) {
    if (!details) return [];
    return details.split(";").map((entry) => {
      const match = entry
        .trim()
        .match(/^(.+?) \(impact: ([-\d.]+), games_without: (\d+)\)$/);
      return match
        ? {
            name: match[1],
            impact: parseFloat(match[2]),
            games: parseInt(match[3], 10),
          }
        : null;
    }).filter(Boolean);
  }

  // shortens "Firstname Lastname" -> "F. Lastname"
  function formatName(fullName) {
    const parts = fullName.split(" ");
    if (parts.length > 1) {
      return `${parts[0][0]}. ${parts[parts.length - 1]}`;
    }
    return fullName; // fallback
  }

  const injuries = parseInjuries(injuredPlayersDetails);

  /**
   * If user does a normal left-click, we override and do an SPA route + set context.
   * If user does a middle-click / ctrl-click / cmd-click, we let the default open a new tab.
   */
  const handleNameLinkClick = (e) => {
    // Check for middle-click or ctrl/cmd key => open in new tab as normal
    if (
      e.button === 1 || // Middle click
      e.metaKey || // Cmd-click
      e.ctrlKey || // Ctrl-click
      e.altKey || // Rare: alt+click
      e.shiftKey
    ) {
      // Let the default browser behavior proceed
      return;
    }

    // Otherwise, prevent default and do an SPA navigation + set context
    e.preventDefault();
    e.stopPropagation();

    const pid = getPlayerId(safeName);
    setSelectedPlayer(pid);
    navigate(`/PlayerDashboard?playerId=${encodeURIComponent(pid)}`);
  };

  return (
    <div
      className="top-pick-card"
      onClick={() => setIsFlipped(!isFlipped)}
      style={{ cursor: "pointer" }}
    >
      <div className={`top-pick-card-inner ${isFlipped ? "flipped" : ""}`}>
        {/* FRONT FACE */}
        <div className="top-pick-card-face front" style={{ position: "relative" }}>
          <h3 className="top-pick-card-title">
            {/* 
              We use a real <a> with an href so middle/ctrl-click works.
              We intercept the normal left-click in handleNameLinkClick. 
            */}
            <a
              href={`/PlayerDashboard?playerId=${encodeURIComponent(
                getPlayerId(safeName)
              )}`}
              onClick={handleNameLinkClick}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "inherit",
              }}
            >
              {formattedName}
            </a>
          </h3>

          <p className="top-pick-card-feature">
            {featureTitle}{" "}
            <span className={isOver ? "over" : "under"}>
              {arrow}
              {statusLabel}
            </span>
          </p>

          <div className="top-pick-card-values">
            <div className="top-pick-card-stat">
              Line <span>{lineValue.toFixed(1)}</span>
            </div>
            <div className="top-pick-card-stat">
              Proj <span>{predicted.toFixed(1)}</span>
            </div>
            <div className="top-pick-card-stat">
              Conf <span>{probability}</span>
            </div>
            <div className="top-pick-card-stat">
              L15 <span>{l15}</span>
            </div>
          </div>

          <div className="top-pick-card-divider" />

          <div className="top-pick-card-content">
            <div className="top-pick-card-l5-pill">
              L5 <span className="pill-value">{l5Average.toFixed(1)}</span>
              <span
                className={`top-pick-card-percentage ${
                  parseFloat(percentageChange) >= 0 ? "positive" : "negative"
                }`}
              >
                {percentageChange}%
              </span>
            </div>

            <div className="top-pick-card-chart-container">
              <div className="top-pick-card-chart-label">
                {overThreshold}/{totalBars}
              </div>
              <div
                className="top-pick-card-line"
                style={{ bottom: `${(lineValue || 0) * scaleFactor + 7}px` }}
              />
              <div className="top-pick-card-bars">
                {barValues.map((entry, idx) => (
                  <div
                    key={idx}
                    className={`top-pick-card-bar ${
                      entry.value >= lineValue ? "above-line" : ""
                    }`}
                    style={{ height: `${entry.value * scaleFactor}px` }}
                    title={`Date: ${entry.date.toDateString()}
Pts: ${entry.pts}, Reb: ${entry.reb}, Ast: ${entry.ast}
Min: ${entry.min}
Value for feature: ${entry.value}`}
                  />
                ))}
              </div>
            </div>

            {/* Flip icon in top-right */}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsFlipped(!isFlipped);
              }}
              style={{
                position: "absolute",
                top: "2px",
                right: "16px",
                fontSize: "24px",
                cursor: "pointer",
                color: "rgb(117 117 117)",
              }}
              title="Flip card"
            >
              ↻
            </div>
          </div>
        </div>

        {/* BACK FACE */}
        <div
          className="top-pick-card-face back"
          style={{ padding: "20px 15px", textAlign: "left" }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsFlipped(!isFlipped);
            }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "24px",
              cursor: "pointer",
              color: "rgb(117 117 117)",
            }}
            title="Flip card"
          >
            ↻
          </div>

          <h3 className="top-pick-card-back-card-title">{formattedName}</h3>
          <p className="top-pick-card-back-card-feature">
            {featureTitle}{" "}
            <span className={isOver ? "over" : "under"}>
              {arrow}
              {statusLabel}
            </span>
          </p>

          <div className="top-pick-card-back-stats">
            <p>
              <strong>Opponent:</strong> {abbreviationToTeamName[nextOpponent]}
            </p>
            <p>
              <strong>Minutes (L5 vs L15):</strong> {minutesDiffStr} min
            </p>
            <p>
              <strong>Impact of Injury:</strong> {impactStr} {feature}
            </p>
            <p>
              <strong>Last 2 Vs {abbreviationToTeamName[nextOpponent]}:</strong>{" "}
              {rollingValue2Mean.toFixed(2)} {feature}
            </p>
            <p>
              <strong>Home/Away L15:</strong> {rollingHome15.toFixed(2)}{" "}
              {feature}
            </p>

            {/* Title for the injury section */}
            <h3 className="top-pick-card-injury-title">Injury Impact</h3>
            <div className="top-pick-card-injury-details">
              <div className="top-pick-card-injury-header">
                <span>Name</span>
                <span>Impact</span>
                <span># Games</span>
              </div>
              {injuries.map((injury, index) => (
                <div key={index} className="top-pick-card-injury-row">
                  <span>{formatName(injury.name)}</span>
                  <span>{injury.impact}</span>
                  <span>{injury.games}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
