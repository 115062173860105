import React, { useState, useEffect } from 'react';
import './TopPicks.css';
import { fetchData } from '../../api';
import { useKeycloak } from '../../KeycloakProvider';
import { abbreviationToTeamName } from '../../components/TeamDictionary';
import { players } from '../../components/PlayerInfo';
import { getPlayerId } from '../../components/PlayerLookup';
import { teamsDict } from '../../components/TeamInfo';

const TopPicks = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const [topPicksData, setTopPicksData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState('');
  const { token, roles } = useKeycloak(); // Use roles from Keycloak
  const isPremiumUser = roles.includes('premium'); // Check if the user has the premium role

  useEffect(() => {
    const fetchPicks = async () => {
      try {
        const queryParams = new URLSearchParams({
          latest: 'true',
        });

        const data = await fetchData(`player-feature-predictions/filter_predictions/?${queryParams.toString()}`, token);

        setTopPicksData(data);
      } catch (error) {
        console.error('Failed to fetch picks:', error);
        setTopPicksData([]);
      }
    };

    if (token && isPremiumUser) {
      fetchPicks();
    }
  }, [token, isPremiumUser]);

  const homeTeamName = abbreviationToTeamName[homeTeamAbbr];
  const awayTeamName = abbreviationToTeamName[awayTeamAbbr];

  const matchPicks = topPicksData.filter(
    pick =>
      (pick.team_abbreviation === homeTeamAbbr || pick.team_abbreviation === awayTeamAbbr) &&
      (selectedFeature === '' || pick.feature === selectedFeature)
  );

  if (!isPremiumUser) {
    return (
      <div className="top-picks-restricted">
        <h2>This feature is only available to premium users</h2>
        <button
          className="subscribe-button"
          onClick={() => window.location.href = '/Subscription'}
        >
          Subscribe Now
        </button>
      </div>
    );
  }

  if (matchPicks.length === 0) {
    return <div>No top picks available for this matchup.</div>;
  }

  const sortedPicks = matchPicks.sort((a, b) => b.feature_probability - a.feature_probability);
  const top16Picks = sortedPicks.slice(0, 16);

  const formatPlayerName = name => {
    if (name.length > 15) {
      const [firstName, ...lastNames] = name.split(' ');
      return `${firstName.charAt(0)}. ${lastNames.join(' ')}`;
    }
    return name;
  };

  const featureMap = {
    pts: 'Points',
    ast: 'Assists',
    reb: 'Rebounds',
    ast_reb: 'Ast+Reb',
    pts_ast: 'Pts+Ast',
    pts_reb: 'Pts+Reb',
    pts_ast_reb: 'Pts+Ast+Reb',
  };

  const featureOptions = [
    { value: '', label: 'All Props' },
    { value: 'pts', label: 'Points' },
    { value: 'ast', label: 'Assists' },
    { value: 'reb', label: 'Rebounds' },
    { value: 'ast_reb', label: 'Ast+Reb' },
    { value: 'pts_ast', label: 'Pts+Ast' },
    { value: 'pts_reb', label: 'Pts+Reb' },
    { value: 'pts_ast_reb', label: 'Pts+Ast+Reb' },
  ];

  const renderPropString = pick => {
    const featureFullName = featureMap[pick.feature] || pick.feature;
    const overUnder = pick.over_under.charAt(0).toUpperCase() + pick.over_under.slice(1).toLowerCase();
    return `${pick.threshold} ${overUnder} ${featureFullName}`;
  };

  const renderPickRow = pick => {
    const playerInfo = players[getPlayerId(pick.player_name)];
    const logoBackgroundColor = playerInfo ? playerInfo.teamColors[0] : '#000000';

    return (
      <tr key={`${pick.team_abbreviation}-${pick.player_name}-${pick.feature}`}>
        <td>
          <div className="top-picks-player">
            <div
              className="player-logo-container"
              style={{ backgroundColor: logoBackgroundColor }}
            >
              <img
                src={`/player_logos/${getPlayerId(pick.player_name)}.png`}
                alt={pick.player_name}
                className="player-logo"
              />
            </div>
            <div className="player-info">
              <span className="player-name">{formatPlayerName(pick.player_name)}</span>
              <span className="player-prop">{renderPropString(pick)}</span>
            </div>
          </div>
        </td>
        <td>{Math.round(pick.feature_prediction)}</td>
        <td>{(pick.feature_probability * 100).toFixed(1)}%</td>
        <td>{pick.over_under.charAt(0).toUpperCase() + pick.over_under.slice(1).toLowerCase()}</td>
      </tr>
    );
  };

  return (
    <div className="top-picks-container">
      <div className="top-picks-header">
        <h2>Top Picks</h2>
        <select
          value={selectedFeature}
          onChange={e => setSelectedFeature(e.target.value)}
          className="prop-filter-select"
        >
          {featureOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="top-picks-table-container">
        <table className="top-picks-table">
          <thead>
            <tr>
              <th>Player</th>
              <th>Projection</th>
              <th>Confidence</th>
              <th>Pick</th>
            </tr>
          </thead>
          <tbody>{top16Picks.map(renderPickRow)}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPicks;
