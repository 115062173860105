import React, { useState, useEffect, useRef } from 'react';
import './InjuryReportTable.css';
import { usePlayerColors } from './PlayerColorContext';
import { combinedInfo } from '../components/CombinedInfo';
import { fetchData } from "../api";
import { useKeycloak } from '../KeycloakProvider';
import teamNameToFullName from '../components/TeamNameToFullName';

const subFeatureMap = {
  "Total": ["pts"],
  "AST": ["ast"],
  "REB": ["reb"],
  "BLK": ["blk"],
  "STL": ["stl"],
  "Pts+Ast": ["pts", "ast"],
  "Pts+Reb": ["pts", "reb"],
  "Ast+Reb": ["ast", "reb"],
  "Pts+Ast+Reb": ["pts", "ast", "reb"]
};

const visualFeatureMap = {
  "Total": "Points",
  "AST": "Assists",
  "REB": "Rebounds",
  "BLK": "Blocks",
  "STL": "Steals",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb"
};

const InjuryReportTable = ({ selectedFeature }) => {
  const { selectedPlayer, teamColors } = usePlayerColors();
  const { token } = useKeycloak();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [homeTeamData, setHomeTeamData] = useState(null);
  const [opponentTeamData, setOpponentTeamData] = useState(null);
  const [homeImpactData, setHomeImpactData] = useState({});
  const [opponentImpactData, setOpponentImpactData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamLogo, setSelectedTeamLogo] = useState('/default-logo.png');
  const [dataFetched, setDataFetched] = useState(false);

  const wrapperRef = useRef(null);

  // Utility for consistent team name mapping
  const getMappedTeamName = (teamName) =>
    teamName === "Los Angeles Clippers" ? "LA Clippers" : teamName;

  // Preload the selected team & logo if a player is chosen
  useEffect(() => {
    if (selectedPlayer) {
      const player = combinedInfo[selectedPlayer];
      if (player) {
        const teamName = player.teamName;
        const opponentName = player.opponentName;
        setTeamOptions([teamName, opponentName]);
        setSelectedTeam(teamName);
        setSelectedTeamLogo(player.teamLogoSrc);
      }
    }
  }, [selectedPlayer]);

  const displayTeamName = getMappedTeamName(teamNameToFullName[selectedTeam]);

  // 1) Fetch the “injury report” data (unchanged)
  useEffect(() => {
    if (selectedTeam && token) {
      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo?.opponentName;

      const fetchInjuryReport = async () => {
        try {
          const endpoint = `teams/injury_report_view/?team_name=${displayTeamName}`;
          const data = await fetchData(endpoint, token);

          if (isOpponentSelected) {
            setOpponentTeamData(
              data.filter((p) => p.team_name === displayTeamName)
            );
            setHomeTeamData([]);
          } else {
            setHomeTeamData(
              data.filter((p) => p.team_name === displayTeamName)
            );
            setOpponentTeamData([]);
          }

          // Update Team Logo if possible
          const teamInfo = Object.values(combinedInfo).find(
            (info) => info.teamName === selectedTeam
          );
          if (teamInfo) {
            setSelectedTeamLogo(teamInfo.teamLogoSrc);
          }
        } catch (error) {
          console.error('Error fetching injury report:', error);
        }
      };

      fetchInjuryReport();
    }
  }, [selectedTeam, token, selectedPlayer, displayTeamName]);

  // 2) Fetch the “impact” data (unchanged logic, just adding new field)
  useEffect(() => {
    let isActive = true;
    const fetchImpactData = async () => {
      setDataFetched(false);
      if (!selectedFeature || !token || !selectedPlayer) return;

      const playerInfo = combinedInfo[selectedPlayer];
      const isOpponentSelected = selectedTeam === playerInfo?.opponentName;

      const homeImpactResults = {};
      const opponentImpactResults = {};

      // Helper to sum multiple sub-features (unchanged)
      const fetchAndSumImpacts = async (
        mainPlayerId,
        impactPlayerId,
        teamName,
        subFeatures,
        endpointType
      ) => {
        let totalImpact = 0;
        let totalMinImpact = 0;
        let gamesWithoutStore = 0;
        // NEW: consecutive missed games in a row, storing last valid value
        let gamesWithoutInARowStore = 0;

        for (const feat of subFeatures) {
          try {
            if (endpointType === 'home') {
              const endpoint = `player-impact/get_player_impact/`;
              const params = new URLSearchParams({
                main_player_id: mainPlayerId,
                impact_player: impactPlayerId,
                team_name: teamName,
                feature: feat
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              if (data?.impact_value !== undefined) {
                totalImpact += data.impact_value;
              }
              if (data?.min_impact !== undefined) {
                totalMinImpact += data.min_impact;
              }
              if (data?.games_without !== undefined) {
                gamesWithoutStore = data.games_without;
              }
              // NEW: read the consecutive missed games from backend
              if (data?.games_without_in_a_row !== undefined) {
                gamesWithoutInARowStore = data.games_without_in_a_row;
              }
            } else {
              // Opponent side
              const endpoint = `team-impact/get_team_impact/`;
              const params = new URLSearchParams({
                player_name: impactPlayerId,
                team_name: teamName,
                feature: feat
              });
              const data = await fetchData(`${endpoint}?${params.toString()}`, token);

              if (data?.impact_allowed_value !== undefined) {
                totalImpact += data.impact_allowed_value;
              }
            }
          } catch (err) {
            console.error(
              `Error fetching impact for ${impactPlayerId} sub-feature ${feat}:`,
              err
            );
          }
        }

        // Round to 1 decimal
        totalImpact = Math.round(totalImpact * 10) / 10;
        totalMinImpact = Math.round(totalMinImpact * 10) / 10;

        return {
          totalImpact,
          totalMinImpact,
          gamesWithoutStore,
          gamesWithoutInARowStore
        };
      };

      // HOME team
      if (!isOpponentSelected && homeTeamData && homeTeamData.length > 0) {
        const subFeatures = subFeatureMap[selectedFeature] || [];
        for (const item of homeTeamData) {
          const { 
            totalImpact,
            totalMinImpact,
            gamesWithoutStore,
            gamesWithoutInARowStore
          } = await fetchAndSumImpacts(
            selectedPlayer,
            item.player_id,
            getMappedTeamName(teamNameToFullName[playerInfo.teamName]),
            subFeatures,
            'home'
          );

          if (isActive) {
            homeImpactResults[item.player_id] = {
              impactValue: isNaN(totalImpact) ? null : totalImpact,
              minImpactValue: isNaN(totalMinImpact) ? null : totalMinImpact,
              gamesWithout: gamesWithoutStore || 0,
              // NEW: store the consecutive missed games
              gamesWithoutInARow: gamesWithoutInARowStore || 0
            };
          }
        }
        if (isActive) {
          setHomeImpactData(homeImpactResults);
        }
      }

      // OPPONENT team
      if (isOpponentSelected && opponentTeamData && opponentTeamData.length > 0) {
        const subFeatures = subFeatureMap[selectedFeature] || [];
        for (const item of opponentTeamData) {
          const { totalImpact } = await fetchAndSumImpacts(
            null,
            item.player_id,
            getMappedTeamName(teamNameToFullName[selectedTeam]),
            subFeatures,
            'away'
          );
          if (isActive) {
            opponentImpactResults[item.player_id] = {
              impactAllowedValue: isNaN(totalImpact)
                ? null
                : Math.round(Math.abs(totalImpact) * 10) / 10
            };
          }
        }
        if (isActive) {
          setOpponentImpactData(opponentImpactResults);
        }
      }

      if (isActive) {
        setDataFetched(true);
      }
    };

    fetchImpactData();

    return () => {
      isActive = false;
    };
  }, [
    homeTeamData,
    opponentTeamData,
    selectedFeature,
    selectedPlayer,
    selectedTeam,
    token,
  ]);

  // Render text for main “impact”
  const renderImpactText = (impactValue) => {
    if (!dataFetched) return 'Loading...';
    if (impactValue === null || impactValue === 0 || isNaN(impactValue)) {
      return 'No impact';
    }
    return impactValue > 0
      ? `+ ${impactValue} ${visualFeatureMap[selectedFeature]}`
      : `- ${Math.abs(impactValue)} ${visualFeatureMap[selectedFeature]}`;
  };

  // Render text for “min_impact”
  const renderMinImpactText = (minImpactValue) => {
    if (!dataFetched) return 'Loading...';
    if (minImpactValue === null || isNaN(minImpactValue)) {
      return 'No impact';
    }
    return minImpactValue > 0
      ? `+ ${minImpactValue} Min`
      : `- ${Math.abs(minImpactValue)} Min`;
  };

  // Handle user changing team from the dropdown
  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    setIsEditing(false);
  };

  // Sort logic
  const comparePlayers = (a, b) => {
    const aHasImpact =
      a.impactValue !== null && a.impactValue !== 0 && !isNaN(a.impactValue);
    const bHasImpact =
      b.impactValue !== null && b.impactValue !== 0 && !isNaN(b.impactValue);
    if (aHasImpact && !bHasImpact) return -1;
    if (!aHasImpact && bHasImpact) return 1;
    if (aHasImpact && bHasImpact) return b.impactValue - a.impactValue;
    return compareStatus(a, b);
  };

  const compareStatus = (a, b) => {
    const statusPriority = { Out: 1, 'Day-To-Day': 2, 'N/A': 3 };
    const aStatusPriority = statusPriority[a.status] || 4;
    const bStatusPriority = statusPriority[b.status] || 4;
    return aStatusPriority - bStatusPriority;
  };

  return (
    <div className="injury-report-container" style={{ backgroundColor: 'white' }}>
      <div className="table-header" ref={wrapperRef} style={{ color: 'black' }}>
        <img src={selectedTeamLogo} alt="Team Logo" className="injury-logo" />
        <div className="title">Injury Report</div>
        <div
          className="selected-team-container"
          onClick={() => setIsEditing(!isEditing)}
        >
          <div className="selected-team">{selectedTeam || 'Select team...'}</div>
          <div className="dropdown-arrow-injury-report">▼</div>
        </div>
        {isEditing && (
          <div
            className="autocomplete-injury-table-container"
            style={{ backgroundColor: teamColors[0] || '#000' }}
          >
            {teamOptions.map((team, index) => (
              <div
                key={index}
                className="autocomplete-injury-table-item"
                onClick={() => handleTeamChange(team)}
                style={{ backgroundColor: teamColors[0] || '#000', color: 'white' }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = teamColors[1] || '#fff';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = teamColors[0] || '#000';
                }}
              >
                {team}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* HOME TEAM TABLE */}
      {selectedTeam === combinedInfo[selectedPlayer]?.teamName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
                <th>Min Impact</th>
                <th># Games</th>
                {/* NEW: consecutive missed games in a row */}
                <th>Last x</th>
              </tr>
            </thead>
            <tbody>
              {homeTeamData === null ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : homeTeamData.length > 0 ? (
                (() => {
                  // Combine homeTeamData with the impact data
                  const combinedHomeData = homeTeamData.map((item) => ({
                    ...item,
                    impactValue: homeImpactData[item.player_id]?.impactValue || null,
                    minImpactValue:
                      homeImpactData[item.player_id]?.minImpactValue || null,
                    gamesWithout:
                      homeImpactData[item.player_id]?.gamesWithout || 0,
                    // NEW: consecutive missed games in a row
                    gamesWithoutInARow:
                      homeImpactData[item.player_id]?.gamesWithoutInARow || 0
                  }));

                  combinedHomeData.sort(comparePlayers);

                  return combinedHomeData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.player_id || 'N/A'}</td>
                      <td>
                        <span
                          className={`status-indicator ${
                            item.status === 'Day-To-Day'
                              ? 'status-day-to-day'
                              : item.status === 'Out'
                              ? 'status-out'
                              : ''
                          }`}
                        ></span>
                        {item.status === "Day-To-Day" ? "DTD" : item.status|| 'N/A'}
                      </td>
                      <td>{renderImpactText(item.impactValue)}</td>
                      <td>{renderMinImpactText(item.minImpactValue)}</td>
                      <td>{item.gamesWithout}</td>
                      {/* Display the consecutive missed games */}
                      <td>{item.gamesWithoutInARow}</td>
                    </tr>
                  ));
                })()
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* OPPONENT TEAM TABLE */}
      {selectedTeam === combinedInfo[selectedPlayer]?.opponentName && (
        <div className="table-container">
          <table className="injury-report-table" style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Impact</th>
              </tr>
            </thead>
            <tbody>
              {opponentTeamData === null ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : opponentTeamData.length > 0 ? (
                (() => {
                  const combinedOpponentData = opponentTeamData.map((item) => ({
                    ...item,
                    impactAllowedValue:
                      opponentImpactData[item.player_id]?.impactAllowedValue || null
                  }));

                  combinedOpponentData.sort((a, b) => {
                    const aHasImpact =
                      a.impactAllowedValue !== null &&
                      a.impactAllowedValue !== 0 &&
                      !isNaN(a.impactAllowedValue);
                    const bHasImpact =
                      b.impactAllowedValue !== null &&
                      b.impactAllowedValue !== 0 &&
                      !isNaN(b.impactAllowedValue);
                    if (aHasImpact && !bHasImpact) return -1;
                    if (!aHasImpact && bHasImpact) return 1;
                    if (aHasImpact && bHasImpact) {
                      return b.impactAllowedValue - a.impactAllowedValue;
                    }
                    return compareStatus(a, b);
                  });

                  return combinedOpponentData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.player_id || 'N/A'}</td>
                      <td>
                        <span
                          className={`status-indicator ${
                            item.status === 'Day-To-Day'
                              ? 'status-day-to-day'
                              : item.status === 'Out'
                              ? 'status-out'
                              : ''
                          }`}
                        ></span>
                        {item.status === "Day-To-Day" ? "DTD" : item.status|| 'N/A'}
                      </td>
                      <td>{renderImpactText(item.impactAllowedValue)}</td>
                    </tr>
                  ));
                })()
              ) : (
                <tr>
                  <td colSpan="3">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InjuryReportTable;
