// Last15Chart.js
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import ReactECharts from 'echarts-for-react';
import { usePlayerColors } from './PlayerColorContext';
import useFetchL15ChartData from './data/Last15Data';
import './Last15Chart.css';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { players } from '../components/PlayerInfo';

const Last15Chart = ({ showSum, onToggle, selectedFeature }) => {
  const { teamColors, selectedPlayer, thresholds, setThresholds } = usePlayerColors();
  const chartData = useFetchL15ChartData(selectedPlayer, selectedFeature);
  const chartRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  const primaryColor = teamColors[0] || '#5470C6';
  const playerName = players[selectedPlayer]?.name || 'Unknown Player';
  const thresholdValue = thresholds[playerName]?.[selectedFeature];
  
  // Keep track of the constant (horizontal) line position
  const constantLine = useMemo(() => {
    if (thresholdValue !== undefined && thresholdValue !== null) {
      return Number(thresholdValue);
    }
    return 0;
  }, [thresholdValue]);

  useEffect(() => {
    setEditValue(constantLine);
  }, [selectedFeature, constantLine]);

  const handleConstantLineChange = useCallback(
    (newLineValue) => {
      setThresholds((prevThresholds) => ({
        ...prevThresholds,
        [playerName]: {
          ...prevThresholds[playerName],
          [selectedFeature]: newLineValue,
        },
      }));
      setEditValue(newLineValue);
    },
    [setThresholds, playerName, selectedFeature]
  );

  // Calculate points (PTS) from FG2M, FG3M, FTM arrays if they exist
  const calculatePts = useCallback(() => {
    if (chartData.FG2M && chartData.FG3M && chartData.FTM) {
      return chartData.FG2M.map(
        (fg2m, index) => fg2m * 2 + chartData.FG3M[index] * 3 + chartData.FTM[index]
      );
    }
    return chartData?.Total || [];
  }, [chartData]);

  // Decide which array to use based on selectedFeature
  const calculateSeriesData = useCallback(() => {
    if (!chartData) return [];
    const pts = calculatePts();

    switch (selectedFeature) {
      case 'Pts+Ast':
        if (chartData.AST) {
          return pts.map((pt, index) => pt + chartData.AST[index]);
        }
        return [];
      case 'Pts+Reb':
        if (chartData.REB) {
          return pts.map((pt, index) => pt + chartData.REB[index]);
        }
        return [];
      case 'Pts+Ast+Reb':
        if (chartData.AST && chartData.REB) {
          return pts.map((pt, index) => pt + chartData.AST[index] + chartData.REB[index]);
        }
        return [];
      case 'Ast+Reb':
        if (chartData.AST && chartData.REB) {
          return chartData.AST.map((ast, index) => ast + chartData.REB[index]);
        }
        return [];
      default:
        return chartData[selectedFeature] || [];
    }
  }, [chartData, selectedFeature, calculatePts]);

  const selectedSeries = useMemo(() => calculateSeriesData(), [calculateSeriesData]);

  // For the Y-axis maximum
  const maxTotalValue = useMemo(
    () => (selectedSeries.length > 0 ? Math.max(...selectedSeries) : 0),
    [selectedSeries]
  );
  const yAxisMax = useMemo(() => Math.ceil(maxTotalValue / 5) * 5 + 5, [maxTotalValue]);

  // For counting how many data points exceed the threshold line
  const totalDataPoints = useMemo(() => chartData?.Dates?.length || 0, [chartData]);
  const overCount = useMemo(
    () => selectedSeries.reduce((count, value) => count + (constantLine < value ? 1 : 0), 0),
    [selectedSeries, constantLine]
  );
  const isOverHalf = useMemo(() => overCount > totalDataPoints / 2, [overCount, totalDataPoints]);

  // Build the chart series for ECharts
  const createSeries = useCallback(() => {
    if (!chartData) return [];
    const series = [];
    const addBarSeries = (name, data, color, stack = null) => {
      series.push({
        name,
        type: 'bar',
        data,
        stack,
        itemStyle: { color },
        label: {
          show: true,
          position: stack ? 'insideTop' : 'top',
          fontWeight: 'bold',
          fontSize: 14,
          formatter: (params) => (params.value > 2 ? params.value : ''),
          z: 4,
        },
        emphasis: { focus: 'series' },
        z: 1,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
      });
    };

    const pts = calculatePts();

    // If user selected "Total", or combos, we handle separately
    if (selectedFeature === 'Total') {
      if (showSum) {
        addBarSeries('Total', chartData.Total, teamColors[0]);
      } else {
        ['FT', 'PT2', 'PT3'].forEach((type, index) => {
          if (chartData[type]) {
            addBarSeries(
              type === 'PT2' ? '2PT' : type,
              chartData[type],
              teamColors[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              'Ad'
            );
          }
        });
      }
    } else if (['Pts+Ast', 'Pts+Reb', 'Ast+Reb', 'Pts+Ast+Reb'].includes(selectedFeature)) {
      const featureMap = {
        'Pts+Ast': [['Pts', pts], ['Ast', chartData.AST]],
        'Pts+Reb': [['Pts', pts], ['Reb', chartData.REB]],
        'Ast+Reb': [['Ast', chartData.AST], ['Reb', chartData.REB]],
        'Pts+Ast+Reb': [['Pts', pts], ['Ast', chartData.AST], ['Reb', chartData.REB]],
      };
      if (showSum) {
        addBarSeries(selectedFeature, selectedSeries, teamColors[0]);
      } else {
        featureMap[selectedFeature].forEach(([name, data], index) => {
          if (data) {
            addBarSeries(
              name,
              data,
              teamColors[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              'total'
            );
          }
        });
      }
    } else {
      if (chartData[selectedFeature]) {
        addBarSeries(selectedFeature, chartData[selectedFeature], teamColors[0]);
      }
    }

    // Add the "Minutes" line
    series.push({
      name: 'Minutes',
      type: 'line',
      yAxisIndex: 1,
      data: chartData.Minutes.map((min) => parseFloat(min.toFixed(1))),
      lineStyle: {
        color: teamColors[1] || '#FF6347',
        width: 3,
        z: 3,
      },
      label: {
        show: true,
        position: 'top',
        fontWeight: 'bold',
        fontSize: 14,
        formatter: (params) => params.value,
        z: 4,
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: teamColors[1] || '#FF6347',
      },
    });

    return series;
  }, [showSum, teamColors, chartData, selectedFeature, selectedSeries, calculatePts]);

  // Construct the chart option
  const getChartOption = useCallback(() => {
    const series = [
      ...createSeries(),
      {
        name: 'Threshold',
        type: 'line',
        data: Array(totalDataPoints).fill(constantLine),
        lineStyle: {
          type: 'dashed',
          color: teamColors[2] || '#ff0000',
          width: 2,
        },
        showSymbol: false,
        z: 2,
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: teamColors[2] || '#ff0000',
        },
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
      },
    ];

    return {
      animation: true,
      animationDuration: 1000,
      animationEasing: 'cubicInOut',
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          if (!chartData) return '';
          const dataIndex = params[0].dataIndex;
          const date = chartData.Dates[dataIndex];
          const value = selectedSeries[dataIndex];
          const minutes = chartData.Minutes[dataIndex];
          return `${selectedFeature}: ${value}<br/>Minutes: ${minutes.toFixed(1)}<br/>Date: ${date}`;
        },
      },
      legend: {
        top: '0%',
        left: '2%',
        data: series.map((s) =>
          s.name === 'Minutes' || s.name === 'Threshold'
            ? { name: s.name, icon: 'circle' }
            : s.name
        ),
      },
      grid: { left: '3%', right: '4%', bottom: '15%', containLabel: true },
      xAxis: [
        {
          type: 'category',
          // IMPORTANT FIX: Compare HomeOrAway[index] to the string '1'
          data: chartData?.Opponents.map(
            (opp, index) =>
              `${chartData.HomeOrAway[index] === '1' ? 'vs' : '@'} ${opp}`
          ) || [],
          axisLabel: {
            rotate: 0,
            align: 'center',
            fontSize: 11,
            margin: 10,
            formatter: (value, index) => {
              if (!chartData?.Dates) return value;
              const date = chartData.Dates[index];
              // Example date format: 2024-11-20 -> '24-11-20'
              return `${value}\n${date.substring(2, 10).replace(/-/g, '-')}`;
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          max: yAxisMax,
          splitLine: { show: true, lineStyle: { color: '#ccc' } },
        },
        {
          type: 'value',
          min: 0,
          max: 60,
          splitLine: { show: false },
        },
      ],
      series,
    };
  }, [
    showSum,
    teamColors,
    chartData,
    selectedFeature,
    constantLine,
    createSeries,
    yAxisMax,
    totalDataPoints,
    selectedSeries,
  ]);

  // Update chart when data or config changes
  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance();
      chartInstance.setOption(getChartOption(), true);
    }
  }, [getChartOption]);

  const handleEditValueChange = useCallback((e) => setEditValue(e.target.value), []);
  const handleEditSubmit = useCallback(() => {
    const newValue = Number(editValue);
    handleConstantLineChange(newValue);
    setIsEditing(false);
  }, [editValue, handleConstantLineChange]);

  if (!chartData || Object.keys(chartData).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="player-chart-title">Last 15 Days Performance</div>
        <div className="player-toggle-container">
          <span>Total</span>
          <label className="switch" style={{ '--primary-color': primaryColor }}>
            <input type="checkbox" onChange={onToggle} checked={showSum} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      <ReactECharts
        ref={chartRef}
        option={getChartOption()}
        className="chart-container"
        style={{ height: '100%', width: '100%' }}
      />

      <div className="slider-container">
        <div className="input-group">
          <button onClick={() => handleConstantLineChange(constantLine - 1)}>
            <FaArrowCircleLeft />
          </button>
          <button onClick={() => handleConstantLineChange(constantLine + 1)}>
            <FaArrowCircleRight />
          </button>
        </div>
        <label htmlFor="constant-line-slider">Adjust Line: </label>
        <input
          type="range"
          id="constant-line-slider"
          min="0.5"
          max={yAxisMax + 0.5}
          step="1"
          value={constantLine}
          onChange={(e) => handleConstantLineChange(Number(e.target.value))}
        />
        <span className="slider-value">
          {isEditing ? (
            <input
              type="number"
              value={editValue}
              onChange={handleEditValueChange}
              onBlur={handleEditSubmit}
              onKeyPress={(e) => e.key === 'Enter' && handleEditSubmit()}
              autoFocus
            />
          ) : (
            <span onClick={() => setIsEditing(true)}>
              {constantLine.toFixed(1)}
            </span>
          )}
        </span>
      </div>

      <div className="over-count">
        Over Count:{' '}
        <span style={{ color: isOverHalf ? '#008000' : '#FF0000' }}>
          {overCount}
        </span>{' '}
        / {totalDataPoints}
      </div>
    </div>
  );
};

export default Last15Chart;
