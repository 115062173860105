import React, { useEffect, useState, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { fetchDefenseData } from './data/DefenseData';
import { useKeycloak } from '../KeycloakProvider';  // Get Keycloak token

const OpponentDefense = ({ selectedFeature }) => {
  const { selectedTeam, opponentName, teamColors } = usePlayerColors();
  const { token } = useKeycloak();  // Get the token for API requests
  
  // Use CSS variables for colors
  const primaryColor = teamColors[0] || 'var(--primary-color)';
  const secondaryColor = teamColors[1] || 'var(--secondary-color)';
  
  const [dateList, setDateList] = useState([]);
  const [opponentList, setOpponentList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [rollingAvgList, setRollingAvgList] = useState([]);
  const [yAxisValues, setYAxisValues] = useState({ min: 0, max: 100 });
  const dataCache = useRef({});

  const featureMapping = {
    'Total': 'team_pts_allowed',
    'AST': 'team_ast_allowed',
    'REB': 'team_reb_allowed',
    'BLK': 'team_blk_allowed',
    'STL': 'team_stl_allowed',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Ast+Reb': 'Ast+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb'
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!opponentName || !token) return;  // Ensure opponentName and token are available

      if (dataCache.current[opponentName]) {
        updateChartData(selectedFeature, dataCache.current[opponentName]);
        return;
      }

      try {
        const { teamData, yAxisValues } = await fetchDefenseData(opponentName, token);
        dataCache.current[opponentName] = { teamData, yAxisValues };
        updateChartData(selectedFeature, { teamData, yAxisValues });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [opponentName, selectedFeature, token]);  // Add token as a dependency

  const updateChartData = (selectedFeature, data) => {
    const { dates, opponents, values, rollingAvg } = data.teamData;
    const mappedFeature = featureMapping[selectedFeature];

    setDateList(dates);
    setOpponentList(opponents);
    setValueList(values[mappedFeature]);
    setRollingAvgList(rollingAvg[mappedFeature]);
    setYAxisValues(data.yAxisValues[mappedFeature] || { min: 0, max: 100 });
  };

  const getOption = () => {
    return {
      title: {
        left: '5%',
        top: '3%',
        text: `${opponentName} Defense with Rolling Average`,
        textStyle: {
          fontSize: 20,
          fontWeight: 'bold',
          color: 'var(--dark-text-color)'
        }
      },
      legend: {
        data: [
          {
            name: selectedFeature,
            icon: 'circle',
            itemStyle: { color: primaryColor }
          },
          {
            name: 'Rolling Average',
            icon: 'circle',
            itemStyle: { color: secondaryColor }
          }
        ],
        top: 45,
        left: '5.4%',
        textStyle: {
          color: 'var(--medium-text-color)'
        },
        itemWidth: 10,
        itemHeight: 10
      },
      grid: {
        top: 90
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const date = dateList[params[0].dataIndex];
          const opponent = params[0].name;
          const value = valueList[params[0].dataIndex];
          const rollingAverage = rollingAvgList[params[0].dataIndex];

          return `
            Date: ${date}<br/>
            Opponent: ${opponent}<br/>
            ${selectedFeature}: ${value.toFixed(2)}<br/>
            Rolling Average: ${rollingAverage.toFixed(2)}
          `;
        }
      },
      xAxis: {
        type: 'category',
        data: opponentList,
        axisLabel: {
          showMaxLabel: true,
          showMinLabel: true,
          interval: 0,
          rotate: 15,
          align: 'center',
          margin: 15,
          color: 'var(--dark-text-color)'
        }
      },
      yAxis: {
        min: yAxisValues.min,
        max: yAxisValues.max,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'var(--light-text-color)'
          }
        },
        axisLabel: {
          color: 'var(--dark-text-color)'
        }
      },
      series: [
        {
          name: selectedFeature,
          type: 'line',
          showSymbol: false,
          data: valueList,
          lineStyle: {
            width: 5,
            color: primaryColor
          },
          itemStyle: {
            color: primaryColor
          },
          smooth: true
        },
        {
          name: 'Rolling Average',
          type: 'line',
          showSymbol: false,
          data: rollingAvgList,
          lineStyle: {
            color: secondaryColor,
            width: 3,
            type: 'dashed'
          },
          itemStyle: {
            color: secondaryColor
          },
          smooth: true
        }
      ]
    };
  };

  return (
    <div id="chart" style={{ height: '102%', width: '100%' }}>
      <ReactEcharts 
        option={getOption()} 
        style={{ height: '105%', width: '105%', marginRight: '25px' }} 
        opts={{ renderer: 'svg', devicePixelRatio: 2 }}
      />
    </div>
  );
};

export default OpponentDefense;
