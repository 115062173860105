import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaTimesCircle, FaCoins } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PreviousBets.css';
import { fetchData } from '../api'; // Ensure fetchData handles token correctly
import { useKeycloak } from '../KeycloakProvider';
import { getPlayerId } from '../components/PlayerLookup';
import { teamNameToAbbreviation } from '../components/TeamDictionary';

const getRandomPercentage = () => (Math.random() * 10 + 48).toFixed(2);

const PreviousBets = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [betsData, setBetsData] = useState([]);
  const [playerDetails, setPlayerDetails] = useState({});
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7)) // Default to last 7 days
  );
  const [endDate, setEndDate] = useState(new Date());
  const [results, setResults] = useState({});
  const { token, keycloakId } = useKeycloak();

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const getResultIcon = (player) => {
    return player.isWin ? (
      <FaCheckCircle className="icon green" />
    ) : (
      <FaTimesCircle className="icon red" />
    );
  };

  const getResultForBet = (bet) => {
    if (bet.type === 'Singles') {
      const resultValue = bet.result.split('/').map(Number);
      const iconColor = resultValue[0] >= resultValue[1] / 2 ? 'green' : 'red';
      return <FaCoins className={`icon coin ${iconColor}`} />;
    } else if (bet.type === 'Parlay') {
      return bet.result === 'win' ? (
        <FaCheckCircle className="icon green" />
      ) : (
        <FaTimesCircle className="icon red" />
      );
    }
    return null;
  };

  const fetchTickets = async () => {
    try {
      const start = startDate.toISOString().split('T')[0];
      const end = endDate.toISOString().split('T')[0];

      const queryParams = new URLSearchParams({
        start_date: start,
        end_date: end,
        keycloak_id: keycloakId,
      });

      const url = `user-picks/filter_tickets_view/?${queryParams.toString()}`;
      const response = await fetchData(url, token, 'GET');


      if (response && response.tickets) {
        setTickets(response.tickets);
        fetchResultsForTickets(response.tickets);
      } else {
        console.error('No tickets found in the response.');
        setTickets([]);
        setBetsData([]);
        setPlayerDetails({});
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const fetchResultsForTickets = async (tickets) => {
    const resultsData = {};

    await Promise.all(
      tickets.map(async (ticket) => {
        const playerId = getPlayerId(ticket.player_bet_on);
        const metrics = ['pts']; // Update this based on your API
        const opponent = teamNameToAbbreviation[ticket.opponent];

        const queryParams = new URLSearchParams({
          player_id: playerId,
          metrics: metrics.join(','),
          x: 1,
          opponent: opponent,
        });

        const url = `players/filter_by_player/?${queryParams.toString()}`;
        try {
          const resultData = await fetchData(url, token, 'GET');

          if (resultData && resultData.length > 0) {
            // Store only the `pts` value, or whatever metric is relevant
            resultsData[ticket.id] = resultData[0]?.pts || 'DNP';
          } else {
            resultsData[ticket.id] = 'DNP'; // Default message if no result found
          }
        } catch (error) {
          console.error(`Error fetching result for ticket ID ${ticket.id}:`, error);
          resultsData[ticket.id] = 'Error'; // Default error message
        }
      })
    );

    setResults(resultsData);
  };

  const processTickets = (tickets) => {
    const groupedTickets = tickets.reduce((acc, ticket) => {
      if (!acc[ticket.ticket_id]) {
        acc[ticket.ticket_id] = [];
      }
      acc[ticket.ticket_id].push(ticket);
      return acc;
    }, {});

    const betsDataArray = [];
    const playerDetailsObj = {};

    Object.keys(groupedTickets).forEach((ticketId) => {
      const ticketGroup = groupedTickets[ticketId];
      const firstTicket = ticketGroup[0];
      const betType = ticketGroup.length > 1 ? 'Parlay' : 'Singles';
      const betDate = firstTicket.game_date;
      const invested = firstTicket.amount_bet ? `${firstTicket.amount_bet}$` : '';
      const potentialReturn = firstTicket.potential_return ? `${firstTicket.potential_return}$` : '';

      const total = ticketGroup.length;
      let wins = 0;
      const playerDetailsArray = [];

      ticketGroup.forEach((ticket) => {
        const result = results[ticket.id];
        const resultValue = parseFloat(result);
        const thresholdValue = parseFloat(ticket.threshold);
        const isOver = ticket.over_under === 'Over';

        let isWin = false;
        if (!isNaN(resultValue) && !isNaN(thresholdValue)) {
          if (isOver && resultValue > thresholdValue) {
            isWin = true;
          } else if (!isOver && resultValue < thresholdValue) {
            isWin = true;
          }
        }

        if (isWin) {
          wins += 1;
        }

        playerDetailsArray.push({
          player: ticket.player_bet_on,
          team: ticket.team,
          vs: ticket.opponent,
          prop: ticket.metric,
          odds: ticket.odds,
          line: ticket.threshold,
          pick: ticket.over_under,
          modelPick: parseFloat(ticket.prediction).toFixed(0),
          result: result,
          isWin: isWin,
          modelProbability: parseFloat(ticket.probability).toFixed(3)*100.0,
        });
      });

      let betResult = '';
      if (betType === 'Parlay') {
        const isWin = wins === total;
        betResult = isWin ? 'win' : 'loss';
      } else {
        betResult = `${wins}/${total}`;
      }

      betsDataArray.push({
        id: ticketId,
        date: betDate,
        type: betType,
        players: total,
        invested: invested,
        return: potentialReturn,
        result: betResult,
      });

      playerDetailsObj[ticketId] = playerDetailsArray;
    });

    setBetsData(betsDataArray);
    setPlayerDetails(playerDetailsObj);
  };

  useEffect(() => {
    fetchTickets();
  }, [startDate, endDate]);

  useEffect(() => {
    if (tickets.length > 0 && Object.keys(results).length > 0) {
      processTickets(tickets);
    } else {
      setBetsData([]);
      setPlayerDetails({});
    }
  }, [tickets, results]);

  return (
    <div className="previous-bets-container">
      <h2 className="table-title">Previous Picks</h2>

      <div className="date-picker-container">
        <label>Start Date:</label>
        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
        <label>End Date:</label>
        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        <button className="apply-filter-btn" onClick={fetchTickets}>
          Apply Filter
        </button>
      </div>

      {betsData.length > 0 ? (
        <table className="bets-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Players</th>
              <th>Invested</th>
              <th>Potential Return</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {betsData.map((bet, index) => (
              <React.Fragment key={bet.id}>
                <tr className="bet-row" onClick={() => handleRowClick(index)}>
                  <td>{bet.date}</td>
                  <td>{bet.type}</td>
                  <td>{bet.players}</td>
                  <td>{bet.invested}</td>
                  <td>{bet.return}</td>
                  <td className="ticket-result">
                    {bet.result}
                    <span>{getResultForBet(bet)}</span>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr className="expanded-row">
                    <td colSpan="6">
                      <table className="player-details-table">
                        <thead>
                          <tr>
                            <th>Player</th>
                            <th>Team</th>
                            <th>VS</th>
                            <th>Prop</th>
                            <th>Odds</th>
                            <th>Line</th>
                            <th>Pick</th>
                            <th>Model Pick</th>
                            <th>Result</th>
                            <th>Model Probability</th>
                          </tr>
                        </thead>
                        <tbody>
                          {playerDetails[bet.id]?.map((player, pIndex) => (
                            <tr key={pIndex}>
                              <td className="player-name">{player.player}</td>
                              <td>{player.team}</td>
                              <td>{player.vs}</td>
                              <td>{player.prop}</td>
                              <td>{player.odds}</td>
                              <td>{player.line}</td>
                              <td>{player.pick}</td>
                              <td>{player.modelPick}</td>
                              <td className="result">
                                {player.result}
                                <span>{getResultIcon(player)}</span>
                              </td>
                              <td>{player.modelProbability}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No bets found for the selected date range.</p>
      )}
    </div>
  );
};

export default PreviousBets;
