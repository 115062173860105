import React from "react";
import { Link } from "react-router-dom"; // for navigation links
import "./PostSubscription.css";         // your new CSS file

const PostSubscription = () => {
  return (
    <div className="post-subscription-page font-main">
      <h1 className="main-color">Thank You!</h1>
      <p>Your subscription has been successfully processed.</p>
      <p>
        We appreciate your support! If you have any questions or concerns, please contact us at{" "}
        <a href="mailto:showstone@showstone.io" className="contact-link">
            showstone@showstone.io
        </a>{" "}
        or join our{" "}
        <a href="https://discord.gg/xegmyZqZ" target="_blank" rel="noopener noreferrer" className="contact-link">
            Discord
        </a>.
        </p>


      <div className="call-to-action-container">
        <h2 className="main-color">Dive into the action!</h2>
        <div className="navigation-links">
          <Link to="/PlayerDashboard" className="nav-link">
            Player Dashboard
          </Link>
          <Link to="/TopPicks" className="nav-link">
            Top Picks
          </Link>
          <Link to="/Projections" className="nav-link">
            Projections
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostSubscription;
