import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePlayerColors } from './PlayerColorContext';
import { combinedInfo } from "../components/CombinedInfo";
import { useKeycloak } from '../KeycloakProvider'; // Import Keycloak
import "./PlayerID.css";
import { fetchData } from "../api";
const PlayerID = ({ className = "" }) => {
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [playerStats, setPlayerStats] = useState(null);
  const {
    selectedPlayer,
    setSelectedPlayer,
    setOpponentName,
    teamColors,
    opponentTeamColors,
    opponentLogoSrc,
    opponentName,
    setSelectedTeam
  } = usePlayerColors();
  const wrapperRef = useRef(null);
  const { token } = useKeycloak(); // Get Keycloak token

  const SearchIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7" stroke="white" strokeWidth="2"/>
      <line x1="13.4142" y1="13.5858" x2="18.4142" y2="18.5858" stroke="white" strokeWidth="2"/>
    </svg>
  );

  useEffect(() => {
    if (!selectedPlayer) {
      const initialPlayer = '1630162'; //Object.keys(combinedInfo)[0];
      setSelectedPlayer(initialPlayer);
      setOpponentName(combinedInfo[initialPlayer].opponentName);
      setSelectedTeam(combinedInfo[initialPlayer].teamName);
    }
  }, [selectedPlayer, setSelectedPlayer, setOpponentName, setSelectedTeam]);

  useEffect(() => {
    const fetchPlayerStats = async () => {
      if (!selectedPlayer || !token) return;
      try {
        const endpoint = `players_overall/get_player/?player_id=${selectedPlayer}`;
        const data = await fetchData(endpoint, token);
        setPlayerStats(data);
      } catch (err) {
        console.error('Error fetching player stats:', err);
      }
    };

    fetchPlayerStats();
  }, [selectedPlayer, token]);

  const handlePlayerChange = (value) => setInputValue(value);
  const handlePlayerSelect = (val) => {
    setSelectedPlayer(val);
    setOpponentName(combinedInfo[val].opponentName);
    setSelectedTeam(combinedInfo[val].teamName);
    setInputValue("");
    setIsEditing(false);
  };

  const filteredPlayers = Object.keys(combinedInfo)
    .filter((key) => combinedInfo[key].name.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => combinedInfo[a].name.localeCompare(combinedInfo[b].name));

  const player = combinedInfo[selectedPlayer] || {};

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (key) => {
    handlePlayerSelect(key);
    const items = document.querySelectorAll('.autocomplete-item');
    items.forEach(item => item.classList.remove('clicked'));
    document.getElementById(key).classList.add('clicked');
  };

  const teamColor = teamColors[0] || '#000';
  const highlightColor = teamColors[1] || '#fff';

  const valueChangeColor = playerStats && playerStats.pct_change && parseFloat(playerStats.pct_change) < 0 ? 'red' : 'green';
  const [firstName, lastName] = player.name ? player.name.split(' ') : ["Select", "player..."];

  return (
    <div className="playerCard">
      <div className={`player-id2 ${className}`}>
        <div className="player-id3">
          <div className="i-d-marker" style={{ backgroundColor: teamColors[0] || 'transparent' }} />
          <div className="i-d-content">
            <div className="i-d-header" ref={wrapperRef}>
              <div className="selected-player-container" onClick={() => setIsEditing(!isEditing)}>
                <div className="selected-player" style={{ color: 'white' }}>
                  <span className="first-name">{firstName}</span>
                  <span className="last-name">{lastName}</span>
                </div>
              </div>
              {isEditing && (
                <div className="autocomplete-container" style={{ backgroundColor: teamColor }}>
                  <div className="autocomplete-item" style={{ backgroundColor: teamColor, color: 'white' }}>
                    <input
                      type="text"
                      placeholder="Search player..."
                      value={inputValue}
                      onChange={(e) => handlePlayerChange(e.target.value)}
                      className="autocomplete-search-input"
                      style={{ backgroundColor: teamColor, color: 'white' }}
                      autoFocus
                    />
                  </div>
                  <div className="autocomplete-scroll-container" style={{ backgroundColor: teamColor, scrollbarColor: `${highlightColor} ${teamColor}` }}>
                    {filteredPlayers.map((key) => (
                      <div
                        key={key}
                        id={key}
                        className={`autocomplete-item ${selectedPlayer === key ? 'highlighted' : ''}`}
                        onClick={() => handleItemClick(key)}
                        style={{ backgroundColor: teamColor, color: 'white' }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = highlightColor}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = teamColor}
                      >
                        {combinedInfo[key].name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="timeline-button-wrapper">
                <div className="timeline-button2"></div>
              </div>
            </div>
          </div>
          <div className="monthly-filter">
            <div className="minesota-1-group">
              <img className="minesota-1-icon1" alt="" src={player.teamLogoSrc || ''} />
              <img className="fotor-bg-remover-2024042919351-icon1" alt="" src={player.imageSrc || ''} />
            </div>
          </div>
          <div className="content1">
            <div className="separator1" />
            <div className="stats-container">
              <div className="stat-item">
                <div className="percentage-value">
                  <div className="sent1">
                    <div className="percentage-stat">
                      <div className="stat-label">
                        <div className="fg-percentage1" style={{ color: teamColor }}>FG Percentage</div>
                      </div>
                      <img className="percentage-stat-child" loading="lazy" alt="" src="" />
                    </div>
                    <div className="stat-value">
                      <b className="percentage-value1" style={{ color: teamColor }}>
                        {playerStats && playerStats.fgm_mean_rolling_30_games && playerStats.fga_mean_rolling_30_games
                          ? ((playerStats.fgm_mean_rolling_30_games / playerStats.fga_mean_rolling_30_games) * 100).toFixed(1) + '%'
                          : 'N/A'}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="value-wrapper">
                <div className="label6" style={{ color: teamColor }}>Scoring Trend</div>
                <b className="value" style={{ color: valueChangeColor }}>
                  {playerStats ? (playerStats.pct_change * 100).toFixed(1) + '%' : 'N/A'}
                </b>
              </div>
            </div>
            <div className="m-p-g-value-wrapper">
              <div className="m-p-g-value">
                <div className="received-item">
                  <div className="recieved1">
                    <div className="received-label">
                      <div className="mpg1">MPG</div>
                    </div>
                    <b className="m-p-g">{playerStats ? playerStats.mpg : 'N/A'}</b>
                  </div>
                </div>
                <img className="vector-icon1" loading="lazy" alt="" src="/vector-1.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PlayerID.propTypes = {
  className: PropTypes.string,
};

export default PlayerID;
