import React from "react";
import "./ScoreCard.css";

const ScoreCard = ({ metric, scores, homeLogo, awayLogo }) => {
  return (
    <div className="score-card">
      <div className="score-card-header">
        <div className="feature-column">{metric}</div>
        <div className="quarter-column">Q1</div>
        <div className="quarter-column">Q2</div>
        <div className="quarter-column">Q3</div>
        <div className="quarter-column">Q4</div>
      </div>
      <div className="score-row">
        <div className="feature-column team-logo">
          <img src={homeLogo} alt="Home Logo" className="team-logo-image" />
        </div>
        {scores.team1.map((score, i) => (
          <div key={i} className="quarter-column">
            {score}
          </div>
        ))}
      </div>
      <div className="score-row">
        <div className="feature-column team-logo">
          <img src={awayLogo} alt="Away Logo" className="team-logo-image" />
        </div>
        {scores.team2.map((score, i) => (
          <div key={i} className="quarter-column">
            {score}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoreCard;
