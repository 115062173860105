import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import './ParlayBuilder.css';

const ParlayBuilder = ({ parlayPlayers, removeFromParlay, clearParlay, keycloakId, ticketId, token }) => {
  const confirmParlay = async () => {
    if (parlayPlayers.length === 0) {
      alert("Add players to parlay before confirming.");
      return;
    }

    try {
      // Validate required fields for each player
      for (const player of parlayPlayers) {
        if (!player.game_date || !player.next_opponent || !player.over_under) {
          alert(
            `Missing required fields for player ${player.player_name || "unknown"}: ` +
            `${!player.game_date ? "game_date " : ""}` +
            `${!player.next_opponent ? "opponent " : ""}` +
            `${!player.over_under ? "over_under" : ""}`
          );
          return;
        }
      }

      // Save user picks (ticket and keycloak association)
      await fetchData('user-picks/', token, 'POST', {
        keycloak_id: keycloakId,
        ticket_id: ticketId,
      });

      // Save each parlay player details
      await Promise.all(
        parlayPlayers.map((player) => {
          const payload = {
            ticket_id: ticketId,
            keycloak_id: keycloakId,
            player_bet_on: player.player_name,
            opponent: player.next_opponent,
            game_date: player.game_date,
            odds: parseFloat(player.odds),
            threshold: parseFloat(player.threshold),
            over_under: player.over_under,
            prediction: player.feature_prediction || 23.5,
            probability: parseFloat(player.feature_probability),
            bet_type: 'parlay',
            timestamp: new Date().toISOString(),
          };

          return fetchData('bet-details/', token, 'POST', payload);
        })
      );

      alert("Parlay confirmed and saved.");
      clearParlay();
    } catch (error) {
      console.error("Error saving parlay:", error);
      alert("An error occurred while saving your parlay. Please try again.");
    }
  };
  
  const handleRemovePlayer = (playerToRemove) => {
  
    removeFromParlay(playerToRemove.name);
  };

  return (
    <div className="parlay-builder-container">
      <div className="parlay-builder-header">
        <h3>Parlay Builder</h3>
        <div className="parlay-builder-top-buttons">
          <button className="parlay-builder-refresh-btn" onClick={clearParlay}>
            🔄
          </button>
        </div>
      </div>

      <span className="parlay-builder-legs">{parlayPlayers.length} Legs</span>

      {!parlayPlayers.length ? (
        <div className="parlay-builder-empty-message">Add players to parlay</div>
      ) : (
        <table className="parlay-builder-table">
          <thead>
            <tr>
              <th>Player</th>
              <th>Pick</th>
              <th>Odds</th>
              <th>Threshold</th>
              <th>Probability</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {parlayPlayers.map((player, index) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player.over_under}</td>
                <td>{player.odds}</td>
                <td>{player.threshold}</td>
                <td>{(player.feature_probability * 100).toFixed(1)}%</td> {/* Rounded to 1 decimal place */}
                <td>
                  <button
                    onClick={() => handleRemovePlayer(player)} // Ensures only this player is removed
                    className="parlay-builder-remove-btn"
                  >
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="parlay-builder-bottom">
        <button className="parlay-builder-confirm-btn" onClick={confirmParlay}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ParlayBuilder;
