import React, { useEffect, useState } from "react";
import "./QuarterDetails.css";

const QuarterDetails = ({ quarters, title, homeColor, awayColor, metric }) => {
  const [groupedQuarters, setGroupedQuarters] = useState([]);

  useEffect(() => {
    console.log("[DEBUG] QuarterDetails useEffect - quarters:", quarters, "metric:", metric);
    if (!quarters || typeof quarters !== "object") {
      console.error("[DEBUG] Invalid quarters data:", quarters);
      return;
    }

    // Convert { Q1: [...], Q2: [...], E1: [...] } into an array
    const grouped = Object.entries(quarters).reduce((acc, [quarterKey, actions]) => {
      if (!Array.isArray(actions)) {
        console.warn(`[DEBUG] Invalid actions array for quarter ${quarterKey}:`, actions);
        return acc;
      }
      // Show events even if 0? Then remove this filter.
      const filteredActions = actions.filter((a) => a[metric] > 0);
      if (filteredActions.length > 0) {
        acc.push({ quarter: quarterKey, actions: filteredActions });
      }
      return acc;
    }, []);

    // Sort quarter keys: Q1 < Q2 < Q3 < Q4 < E1 < E2 ...
    const sortedGroupedQuarters = grouped.sort((a, b) => {
      const numA = parseInt(a.quarter.replace(/[^\d]/g, ""), 10);
      const numB = parseInt(b.quarter.replace(/[^\d]/g, ""), 10);
      return numA - numB;
    });

    console.log("[DEBUG] sortedGroupedQuarters:", sortedGroupedQuarters);
    setGroupedQuarters(sortedGroupedQuarters);
  }, [quarters, metric]);

  return (
    <div className="quarter-details">
      <h4 className="quarter-title">{title}</h4>
      {groupedQuarters.length === 0 ? (
        <p>No data available for this game.</p>
      ) : (
        groupedQuarters.map(({ quarter, actions }) => (
          <div key={quarter} className="quarter-section">
            <div className="quarter-header">
              <span>{quarter}</span>
            </div>
            <div className="quarter-content">
              {actions.map((action, index) => {
                const isHomePlayer = !action.isOpponent;
                return (
                  <div key={index} className="action-row">
                    {/* HOME side (left) */}
                    <div className="home-player" style={{ color: homeColor }}>
                      {isHomePlayer && action.player}
                    </div>
                    <div className="home-stats" style={{ color: homeColor }}>
                      {isHomePlayer && `${action[metric] || 0}`}
                    </div>

                    {/* TIME in the center */}
                    <div className="time">
                      <span>{action.time || "N/A"}</span>
                    </div>

                    {/* AWAY side (right) */}
                    <div className="away-stats" style={{ color: awayColor }}>
                      {!isHomePlayer && `${action[metric] || 0}`}
                    </div>
                    <div className="away-player" style={{ color: awayColor }}>
                      {!isHomePlayer && action.player}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default QuarterDetails;
