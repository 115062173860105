import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PremiumFeature.css';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { Lock } from 'lucide-react';
import { fetchData } from '../api';
import { useKeycloak } from '../KeycloakProvider';

const PremiumFeature = ({ selectedFeature }) => {
  const { teamColors, selectedPlayer } = usePlayerColors();
  const { isAuthenticated, roles, token } = useKeycloak(); 
  const primaryColor = teamColors[0] || '#4f46e5';
  const secondaryColor = teamColors[1] || '#818cf8';
  const playerName = players[selectedPlayer]?.name || 'Top Picks';

  const [data, setData] = useState([]);
  const isPremiumUser = roles.includes('premium'); // Adjust logic as needed

  // Map your "selectedFeature" from the Dashboard to the raw fields
  const selectedFeatureMap = {
    Total: 'pts',
    AST: 'ast',
    REB: 'reb',
    'Pts+Ast': 'pts_ast',
    'Pts+Reb': 'pts_reb',
    'Ast+Reb': 'ast_reb',
    'Pts+Ast+Reb': 'pts_ast_reb',
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!token) {
        console.error('User not authenticated or token missing');
        return;
      }
      try {
        // Build query with player_name
        const queryParams = new URLSearchParams({
          latest: 'true',
          player_name: players[selectedPlayer]?.name,
        });

        const response = await fetchData(
          `player-feature-predictions/filter_predictions/?${queryParams.toString()}`,
          token
        );

        // Sort descending by feature_probability
        const sortedData = response.sort(
          (a, b) => b.feature_probability - a.feature_probability
        );
        setData(sortedData);
      } catch (error) {
        console.error('Failed to fetch player predictions:', error);
        setData([]);
      }
    };

    fetchPlayerData();
  }, [selectedPlayer, isAuthenticated, token]);

  /**
   * Shorter mapping for combined features:
   *  pts_ast -> p+a
   *  pts_reb -> p+r
   *  ast_reb -> a+r
   *  pts_ast_reb -> p+a+r
   */
  const mapFeatureName = (feature) => {
    const featureMap = {
      pts: 'Points',
      ast: 'Assists',
      reb: 'Rebounds',
      pts_ast: 'p+a',
      pts_reb: 'p+r',
      ast_reb: 'a+r',
      pts_ast_reb: 'p+a+r',
    };
    return featureMap[feature] || feature; 
  };

  // Round odds to 2 decimals if numeric
  const formatOdds = (oddsValue) => {
    if (!oddsValue) return 'N/A';
    const num = parseFloat(oddsValue);
    if (isNaN(num)) return 'N/A';
    return num.toFixed(2);
  };

  // Round the projection to an integer
  const formatProjection = (projValue) => {
    if (projValue === null || projValue === undefined) return 'N/A';
    const num = parseFloat(projValue);
    if (isNaN(num)) return 'N/A';
    // Round to integer
    return Math.round(num).toString();
  };

  return (
    <div className="premium-feature-container">
      <div className="premium-feature-header">
        <h2>{playerName} Top Picks</h2>
        {!isPremiumUser && (
          <>
            <span className="premium-feature-subtext">(Buy premium to unlock)</span>
            <Link
              to="/Subscription"
              className="premium-feature-premium"
              style={{ backgroundColor: secondaryColor }}
            >
              Unlock
            </Link>
          </>
        )}
      </div>
      <div className="premium-feature-table-container">
      <table className="premium-feature-table">
        <thead>
          <tr>
            <th>Bet</th>
            <th>Pick</th>
            <th>Proj</th> {/* Updated header from "Projection" to "Proj" */}
            <th>Odds</th>
            <th>Conf</th> {/* Updated header from "Probability" to "Conf" */}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => {
              // For the bar width, we do a slight skew above or below 50%
              const adjustedProbability =
                item.feature_probability >= 0.5
                  ? (item.feature_probability - 0.5) * 1.5 + 0.5
                  : (item.feature_probability - 0.5) * 1.2 + 0.5;
              const barWidth = Math.max(0, Math.min(adjustedProbability * 100, 100));

              // Check if this row is the selectedFeature
              const isSelectedRow =
                item.feature === selectedFeatureMap[selectedFeature];

              // Convert odds to 2 decimals
              const displayedOdds = formatOdds(item.odds);
              // Round projection to integer
              const displayedProjection = formatProjection(item.feature_prediction);

              return (
                <tr
                  key={index}
                  className={isSelectedRow ? 'selected-row' : ''}
                >
                  <td>
                    {isPremiumUser ? (
                      `${mapFeatureName(item.feature)} ${item.threshold}`
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    {isPremiumUser ? (
                      item.over_under
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    {isPremiumUser ? (
                      displayedProjection
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    {isPremiumUser ? (
                      displayedOdds
                    ) : (
                      <>
                        <Lock size={16} color={secondaryColor} /> Locked
                      </>
                    )}
                  </td>
                  <td>
                    <div className="premium-feature-probability-bar">
                      <div
                        className="premium-feature-probability-bar-inner"
                        style={{
                          width: `${barWidth}%`,
                          backgroundColor: primaryColor,
                        }}
                      ></div>
                      <span className="premium-feature-probability-tooltip">
                        {(item.feature_probability * 100).toFixed(1)}%
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
    </div>
    
  );
};

export default PremiumFeature;
