import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import './NavigationMenu.css';

const NavigationMenu = () => {
  const [
    isAuthenticated,
    getToken,
    username,
    roles,
    loading,
    login,
    logout,
  ] = useAuth();

  const { colorBlind, setColorBlind } = usePlayerColors();

  // 1) Load Color Blind state from localStorage or fallback to context
  const [colorBlindMode, setColorBlindMode] = useState(() => {
    const stored = localStorage.getItem('colorBlindMode');
    return stored !== null ? JSON.parse(stored) : colorBlind;
  });

  // 2) Load American Odds preference from localStorage or default to true
  const [americanOdds, setAmericanOdds] = useState(() => {
    const stored = localStorage.getItem('americanOdds');
    return stored !== null ? JSON.parse(stored) : true;
  });

  // 3) Sync Color Blind Mode to localStorage & context
  useEffect(() => {
    localStorage.setItem('colorBlindMode', JSON.stringify(colorBlindMode));
    setColorBlind(colorBlindMode);
  }, [colorBlindMode, setColorBlind]);

  // 4) Sync American Odds to localStorage
  useEffect(() => {
    localStorage.setItem('americanOdds', JSON.stringify(americanOdds));
  }, [americanOdds]);

  // 5) For toggling the mobile menu (hamburger)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleColorBlindToggle = () => {
    setColorBlindMode((prev) => !prev);
  };

  const handleOddsToggle = () => {
    const newValue = !americanOdds;
    setAmericanOdds(newValue);
    // Dispatch a custom event to notify other components
    window.dispatchEvent(new Event("oddsChange"));
  };

  // Avoid rendering the navigation while still loading the authentication status
  if (loading) {
    return;
  }

  // 6) If user is NOT authenticated => only show "Login" button
  if (!isAuthenticated) {
    return (
      <nav className="nav-menu-navigation-menu">
        <div className="nav-menu-container">
          <div className="nav-menu-brand">
            <img src="/big_logo.png" alt="Logo" className="nav-menu-logo" />
          </div>
          {/* Login button, on the right side for mobile or desktop */}
          <div className="nav-menu-right">
            <button onClick={login} className="nav-menu-link-button">
              Login
            </button>
          </div>
        </div>
      </nav>
    );
  }

  // 7) If user is authenticated => show full menu
  return (
    <nav className="nav-menu-navigation-menu">
      <div className="nav-menu-container">
        {/* ---------- BRAND / LOGO ---------- */}
        <div className="nav-menu-brand">
          <img src="/big_logo.png" alt="Logo" className="nav-menu-logo" />
        </div>

        {/* 
          Hamburger button -> visible only on mobile via CSS. 
          Toggling sets mobileMenuOpen => hides/shows links below.
        */}
        <div 
          className="nav-menu-hamburger" 
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          &#9776; {/* Unicode hamburger icon */}
        </div>

        {/* 
          Nav links 
          If "mobileMenuOpen" => add a class "nav-menu-links-mobile-open" 
          so we can show/hide it in CSS for mobile. 
        */}
        <div 
          className={`nav-menu-links ${mobileMenuOpen ? "nav-menu-links-mobile-open" : ""}`}
        >
          {/* ---------- TOP PICKS (highlighted) ---------- */}
          <Link to="/TopPicks" className="nav-menu-link nav-menu-highlighted">
            Top Picks
          </Link>

          {/* ---------- DASHBOARDS dropdown ---------- */}
          <div className="nav-menu-link nav-menu-dropdown">
            Dashboards
            <div className="nav-menu-dropdown-content">
              <Link to="/PlayerDashboard" className="nav-menu-dropdown-item">
                Player Dashboard
              </Link>
              <Link to="/TeamDashboard" className="nav-menu-dropdown-item">
                Team Dashboard
              </Link>
            </div>
          </div>

          {/* ---------- MODEL dropdown ---------- */}
          <div className="nav-menu-link nav-menu-dropdown">
            Model
            <div className="nav-menu-dropdown-content">
              <Link to="/Projections" className="nav-menu-dropdown-item">
                Projections
              </Link>
              <Link to="/Results" className="nav-menu-dropdown-item">
                Results
              </Link>
              <Link to="/HistoricalResults" className="nav-menu-dropdown-item">
                Historical Results
              </Link>
            </div>
          </div>

          {/* ---------- LIVE TRACKER ---------- */}
          <Link to="/Live" className="nav-menu-link">
            Live Tracker
          </Link>

          {/* ---------- PLAYER SELECTION dropdown ---------- */}
          <div className="nav-menu-link nav-menu-dropdown">
            Player Selection
            <div className="nav-menu-dropdown-content">
              <Link to="/TicketAnalyzer" className="nav-menu-dropdown-item">
                Player Selection
              </Link>
              <Link to="/TicketWatcher" className="nav-menu-dropdown-item">
                Your Pick History
              </Link>
              <Link to="/SelectedPlayers" className="nav-menu-dropdown-item">
                Selected Players
              </Link>
            </div>
          </div>

          {/* ---------- USER MENU ---------- */}
          <div className="nav-menu-link nav-menu-user-menu">
            {username}
            <div className="nav-menu-dropdown-content">
            <div className="nav-menu-user-info">
        <span
          onClick={handleLogout}
          className="nav-menu-logout-link"
        >
          Sign Out
        </span>

            <span
              onClick={() => window.location.href = '/Subscription'} // Navigate to /Subscription
              className="nav-menu-subscription-link"
            >
              Manage Subscription
            </span>



            </div>
            </div>
          </div>

          {/* ---------- PREFERENCES (gear icon) ---------- */}
          <div className="nav-menu-link nav-menu-dropdown nav-menu-preferences" style={{ fontSize: "18px" }}>
            <span style={{ cursor: "pointer" }}>⚙</span>
            <div className="nav-menu-dropdown-content nav-menu-preferences-content">
              {/* Color Blind Toggle */}
              <div className="nav-menu-colorblind-switch">
                <label className="nav-menu-switch">
                  <input
                    type="checkbox"
                    checked={colorBlindMode}
                    onChange={handleColorBlindToggle}
                  />
                  <span className="nav-menu-slider nav-menu-round"></span>
                </label>
                <span>Color Blind Mode</span>
              </div>
              {/* American Odds Toggle */}
              <div className="nav-menu-american-odds-switch" style={{ marginTop: "8px" }}>
                <label className="nav-menu-switch">
                  <input
                    type="checkbox"
                    checked={americanOdds}
                    onChange={handleOddsToggle}
                  />
                  <span className="nav-menu-slider nav-menu-round"></span>
                </label>
                <span>American Odds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationMenu;
