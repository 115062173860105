import React, { useEffect, useState, useMemo } from "react";
import { usePlayerColors } from "./PlayerColorContext"; // Adjust path as necessary
import { useKeycloak } from "../KeycloakProvider"; // Provides 'token'
import "./PlayerLogsHistorical.css"; 
import { teamsDict } from "../components/TeamInfo.js"; // Ensure path is correct
import teamAbbreviationToFullName from "../components/TeamFullNameDictionary.js";
import { FaExpand, FaCompress } from "react-icons/fa";
import { fetchData } from "../api";
import { players } from "../components/PlayerInfo"; // Import player info

const featureMap = {
  Points: "PTS",
  Assists: "AST",
  Rebounds: "REB",
  Steals: "STEALS",
  Blocks: "BLK",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb",
};

const featureReverseMap = {
  PTS: "Points",
  AST: "Assists",
  REB: "Rebounds",
  STEALS: "Steals",
  BLK: "Blocks",
  "Pts+Ast": "Pts+Ast",
  "Pts+Reb": "Pts+Reb",
  "Ast+Reb": "Ast+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb",
};

const summationFeatures = ["Pts+Ast", "Pts+Reb", "Ast+Reb", "Pts+Ast+Reb"];

const PlayerLogsHistorical = ({ selectedFeature, setSelectedFeature }) => {
  const { selectedPlayer, thresholds, adjustedThresholds } = usePlayerColors();
  const { token } = useKeycloak();

  const [playerLogs, setPlayerLogs] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [opponentFilter, setOpponentFilter] = useState("");
  const [homeOrAwayFilter, setHomeOrAwayFilter] = useState("");
  const [minutesFilter, setMinutesFilter] = useState("");
  const [minutesCondition, setMinutesCondition] = useState(">=");
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Map the selectedPlayer ID to playerName
  const playerName = players[selectedPlayer]?.name || "Unknown Player";

  // Get thresholds
  const thresholdValue = thresholds[playerName]?.[selectedFeature];
  const adjustedThresholdValue = adjustedThresholds[playerName]?.[selectedFeature];

  // Use adjusted threshold if available, else fetched threshold, else 0
  const constantLine = useMemo(() => {
    if (adjustedThresholdValue !== undefined && adjustedThresholdValue !== null) {
      return Number(adjustedThresholdValue);
    } else if (thresholdValue !== undefined && thresholdValue !== null) {
      return Number(thresholdValue);
    }
    return 0;
  }, [adjustedThresholdValue, thresholdValue]);

  useEffect(() => {
    const fetchPlayerLogs = async () => {
      if (selectedPlayer && !cache[selectedPlayer] && token) {
        setIsLoading(true);
        const metrics = [
          "fg2a", "fg3a", "ftm", "fta", "ts_percent",
          "fg2m", "opponent", "fg3m", "game_date",
          "ast", "reb", "blk", "stl", "home_or_away",
          "min", "plus_minus"
        ];
        const queryString = metrics.map((m) => `metrics=${m}`).join("&");
        const endpoint = `players/filter_by_player/?player_id=${selectedPlayer}&${queryString}&x=30`;

        try {
          const response = await fetchData(endpoint, token);
          // Sort by game_date descending
          const data = response.sort((a, b) => new Date(b.game_date) - new Date(a.game_date));

          const logs = data.map((d) => {
            const teamAbbrev = teamAbbreviationToFullName[d.opponent];
            const teamName = teamAbbrev ? teamAbbrev.split(" ").pop() : d.opponent;
            const teamInfo = teamsDict[teamName] || {};
            return {
              date: d.game_date,
              opponent: d.opponent,
              fg2a: d.fg2a,
              fg3a: d.fg3a,
              fta: d.fta,
              fg2m: d.fg2m,
              fg3m: d.fg3m,
              ftm: d.ftm,
              ts: d["ts_percent"],
              points: d.fg2m * 2 + d.fg3m * 3 + d.ftm,
              assists: d.ast,
              rebounds: d.reb,
              blocks: d.blk,
              steals: d.stl,
              plusMinus: d.plus_minus,
              homeOrAway: d.home_or_away,
              minutes: d.min.toFixed(1),
              opponentLogo: teamInfo.logo || "/default-logo.png",
              opponentFullName: teamInfo.fullName || d.opponent,
            };
          });

          setCache((prev) => ({ ...prev, [selectedPlayer]: logs }));
          setPlayerLogs(logs);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching player logs:", error);
          setIsLoading(false);
        }
      } else if (cache[selectedPlayer]) {
        // Use cached data
        setPlayerLogs(cache[selectedPlayer]);
        setIsLoading(false);
      }
    };

    // Reset filters when player changes
    setOpponentFilter("");
    setHomeOrAwayFilter("");
    setMinutesFilter("");

    fetchPlayerLogs();
  }, [selectedPlayer, cache, token]);

  // Filtered data
  const filteredLogs = playerLogs
    .filter((log) => !opponentFilter || log.opponent === opponentFilter)
    .filter((log) => !homeOrAwayFilter || log.homeOrAway === homeOrAwayFilter)
    .filter(
      (log) =>
        !minutesFilter ||
        (minutesCondition === ">=" ? log.minutes >= minutesFilter : log.minutes <= minutesFilter)
    );

  // Convert selectedFeature => PTS/REB/AST/etc.
  const adjustedFeature =
    selectedFeature === "Total"
      ? "PTS"
      : featureMap[selectedFeature] || selectedFeature;

  const getFeatureValue = (game, feature) => {
    const calculatePercentage = (made, attempted) => {
      if (!attempted) return "0.0%";
      return `${((made / attempted) * 100).toFixed(1)}%`;
    };

    switch (feature) {
      case "PTS":
        return game.points;
      case "AST":
        return game.assists;
      case "REB":
        return game.rebounds;
      case "STEALS":
        return game.steals;
      case "BLK":
        return game.blocks;
      case "Pts+Ast":
        return game.points + game.assists;
      case "Pts+Reb":
        return game.points + game.rebounds;
      case "Ast+Reb":
        return game.assists + game.rebounds;
      case "Pts+Ast+Reb":
        return game.points + game.assists + game.rebounds;
      case "MIN":
        return game.minutes;
      case "2PT":
        return `${game.fg2m}/${game.fg2a} (${calculatePercentage(game.fg2m, game.fg2a)})`;
      case "3PT":
        return `${game.fg3m}/${game.fg3a} (${calculatePercentage(game.fg3m, game.fg3a)})`;
      case "FT":
        return `${game.ftm}/${game.fta} (${calculatePercentage(game.ftm, game.fta)})`;
      case "TS (%)":
        return `${(game.ts * 100).toFixed(1)}%`;
      case "+/-":
        return game.plusMinus;
      default:
        return game[feature.toLowerCase()] || 0;
    }
  };

  const getColorForDifference = (difference) => {
    const positiveColor = "rgba(144, 238, 144, 0.25)"; // Light green
    const negativeColor = "rgba(255, 160, 122, 0.25)"; // Light red
    return difference > 0 ? positiveColor : negativeColor;
  };

  const getTableHeaders = () => {
    const headers = [
      "PTS",
      "REB",
      "AST",
      "STEALS",
      "BLK",
      "MIN",
      "2PT",
      "3PT",
      "FT",
      "TS (%)",
      "+/-",
    ];
    if (summationFeatures.includes(adjustedFeature)) {
      // If the selected feature is a summation (Pts+Ast, etc.), we don't hide it in the table,
      // but we also don't want to duplicate it. This snippet filters out summation features
      // if they exist in the base headers (which they normally don't).
      return headers.filter((header) => !summationFeatures.includes(header));
    } else {
      return headers.filter((header) => header !== adjustedFeature);
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div
      className={`player-logs-historical-container ${
        isFullScreen ? "full-screen" : ""
      }`}
    >
      <button className="popout-button" onClick={toggleFullScreen}>
        {isFullScreen ? <FaCompress /> : <FaExpand />}
      </button>
      <h3 className="player-logs-historical-title">Player Logs Historical</h3>

      {/* 
        Filters are now placed INSIDE the same container that holds the table,
        so they remain flexible and visually within the same 'box'.
      */}
      <div className="player-logs-table-container">
        <div className="filters-container">
          <label>
            Opponent:
            <select
              className="filter-select"
              onChange={(e) => setOpponentFilter(e.target.value)}
              value={opponentFilter}
            >
              <option value="">All</option>
              {[...new Set(playerLogs.map((log) => log.opponent))].map(
                (opponent) => (
                  <option key={opponent} value={opponent}>
                    {opponent}
                  </option>
                )
              )}
            </select>
          </label>
          <label>
            Home/Away:
            <select
              className="filter-select"
              onChange={(e) => setHomeOrAwayFilter(e.target.value)}
              value={homeOrAwayFilter}
            >
              <option value="">All</option>
              <option value="1">Home</option>
              <option value="0">Away</option>
            </select>
          </label>
          <label>
            Minutes
            <div className="minutes-filter-container">
              <select
                className="filter-select"
                onChange={(e) => setMinutesCondition(e.target.value)}
                value={minutesCondition}
              >
                <option value=">=">&gt;=</option>
                <option value="<=">&lt;=</option>
              </select>
              <input
                type="number"
                className="filter-input"
                onChange={(e) => setMinutesFilter(e.target.value)}
                value={minutesFilter}
              />
            </div>
          </label>
          <div className="feature-dropdown-container">
            <label htmlFor="feature-select">Select Filter: </label>
            <select
              id="feature-select"
              value={featureReverseMap[selectedFeature]}
              onChange={(e) => setSelectedFeature(e.target.value)}
            >
              {Object.keys(featureMap).map((feature) => (
                <option key={feature} value={feature}>
                  {feature}
                </option>
              ))}
            </select>
          </div>
        </div>

        {isLoading ? (
          <div>Loading...</div>
        ) : filteredLogs.length === 0 ? (
          <div>No data available</div>
        ) : (
          <table className="player-logs-historical-table">
            <thead>
              <tr>
                <th className="player-logs-historical-header">Date</th>
                <th className="player-logs-historical-header">VS</th>
                <th className="player-logs-historical-header">Line</th>
                <th className="player-logs-historical-header">{adjustedFeature}</th>
                {getTableHeaders().map((feature, idx) => (
                  <th key={idx} className="player-logs-historical-header">
                    {feature}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLogs.map((game, index) => {
                const featureValue = getFeatureValue(game, adjustedFeature);
                const difference = featureValue - constantLine;
                const color = getColorForDifference(difference);

                return (
                  <tr key={index} className="player-logs-historical-row">
                    <td className="player-logs-historical-cell">{game.date}</td>
                    <td className="player-logs-historical-cell">
                      {game.homeOrAway === "1" ? (
                        <>
                          <span>vs</span>
                          <img
                            src={game.opponentLogo}
                            alt="Opponent Logo"
                            className="opponent-logo"
                          />
                        </>
                      ) : (
                        <>
                          <span>@</span>
                          <img
                            src={game.opponentLogo}
                            alt="Opponent Logo"
                            className="opponent-logo"
                          />
                        </>
                      )}
                    </td>

                    <td
                      className="player-logs-historical-cell"
                      style={{ backgroundColor: color }}
                    >
                      {constantLine}
                    </td>
                    <td
                      className="player-logs-historical-cell"
                      style={{ backgroundColor: color }}
                    >
                      {featureValue}
                    </td>

                    {getTableHeaders().map((feat, i) => {
                      const val = getFeatureValue(game, feat);
                      const isHighlighted = feat === adjustedFeature;
                      const diff = val - constantLine;
                      const featColor = getColorForDifference(diff);

                      return (
                        <td
                          key={i}
                          className="player-logs-historical-cell"
                          style={{
                            backgroundColor: isHighlighted ? featColor : "transparent",
                            color: isHighlighted ? "white" : "inherit",
                          }}
                        >
                          {val}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PlayerLogsHistorical;
