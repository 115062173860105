// useFetchTeamChartData.js
import { useState, useEffect, useRef } from "react";
import { fetchData } from '../../api';  // Import the fetchData utility

const useFetchTeamChartData = (teamAbbreviation, token, x = 15) => {
  const [chartData, setChartData] = useState({
    team_points: [],
    team_fg2a: [],
    team_fg2m: [],
    team_fg3a: [],
    team_fg3m: [],
    team_oreb: [],
    team_dreb: [],
    team_reb: [],
    team_ast: [],
    team_tov: [],
    team_stl: [],
    team_blk: [],
    team_TS_percent: [],
    Dates: [],
    team_points_allowed: [],
    team_fg2a_allowed: [],
    team_fg2m_allowed: [],
    team_fg3a_allowed: [],
    team_fg3m_allowed: [],
    team_oreb_allowed: [],
    team_dreb_allowed: [],
    team_reb_allowed: [],
    team_ast_allowed: [],
    team_tov_allowed: [],
    team_stl_allowed: [],
    team_blk_allowed: [],
    team_TS_percent_allowed: [],
    // Added fields
    team_fgm: [],
    team_fga: [],
    team_ftm: [],
    team_fta: [],
    team_fgm_allowed: [],
    team_fga_allowed: [],
    team_ftm_allowed: [],
    team_fta_allowed: [],
  });

  const dataCache = useRef({});

  useEffect(() => {
    if (teamAbbreviation && token && !dataCache.current[teamAbbreviation]) {
      const metrics = [
        "team_pts", "team_fg2a", "team_fg2m", "team_fg3a", "team_fg3m",
        "team_oreb", "team_dreb", "team_reb", "team_ast", "team_tov",
        "team_stl", "team_blk", "team_TS_percent", "team_points_allowed",
        "team_fg2a_allowed", "team_fg2m_allowed", "team_fg3a_allowed",
        "team_fg3m_allowed", "team_oreb_allowed", "team_dreb_allowed",
        "team_reb_allowed", "team_ast_allowed", "team_tov_allowed",
        "team_stl_allowed", "team_blk_allowed", "team_TS_percent_allowed",
        "game_date",
        // Added metrics
        "team_fgm", "team_fga", "team_ftm", "team_fta",
        "team_fgm_allowed", "team_fga_allowed", "team_ftm_allowed", "team_fta_allowed",
      ];
      const queryString = metrics.map(metric => `metrics=${metric}`).join("&");

      fetchData(`teams/filter_by_team/?team_abbreviation=${teamAbbreviation}&${queryString}&x=${x}`, token)
        .then(data => {
          const sortedData = data
            .sort((a, b) => new Date(a.game_date) - new Date(b.game_date))
            .slice(0, x);

          const newChartData = {
            team_points: sortedData.map(d => d.team_pts),
            team_fg2a: (sortedData.map(d => d.team_fga) - sortedData.map(d => d.team_fg3a)) * 2  ,
            team_fg2m: (sortedData.map(d => d.team_fgm) - sortedData.map(d => d.team_fg3m))*2,
            team_fg3a: sortedData.map(d => d.team_fg3a),
            team_fg3m: sortedData.map(d => d.team_fg3m)*3,
            team_oreb: sortedData.map(d => d.team_oreb),
            team_dreb: sortedData.map(d => d.team_dreb),
            team_reb: sortedData.map(d => d.team_reb),
            team_ast: sortedData.map(d => d.team_ast),
            team_tov: sortedData.map(d => d.team_tov),
            team_stl: sortedData.map(d => d.team_stl),
            team_blk: sortedData.map(d => d.team_blk),
            team_TS_percent: sortedData.map(d => d.team_TS_percent),
            Dates: sortedData.map(d => d.game_date),
            team_points_allowed: sortedData.map(d => d.team_points_allowed),
            team_fg2a_allowed: sortedData.map(d => d.team_fg2a_allowed),
            team_fg2m_allowed: sortedData.map(d => d.team_fg2m_allowed),
            team_fg3a_allowed: sortedData.map(d => d.team_fg3a_allowed),
            team_fg3m_allowed: sortedData.map(d => d.team_fg3m_allowed),
            team_oreb_allowed: sortedData.map(d => d.team_oreb_allowed),
            team_dreb_allowed: sortedData.map(d => d.team_dreb_allowed),
            team_reb_allowed: sortedData.map(d => d.team_reb_allowed),
            team_ast_allowed: sortedData.map(d => d.team_ast_allowed),
            team_tov_allowed: sortedData.map(d => d.team_tov_allowed),
            team_stl_allowed: sortedData.map(d => d.team_stl_allowed),
            team_blk_allowed: sortedData.map(d => d.team_blk_allowed),
            team_TS_percent_allowed: sortedData.map(d => d.team_TS_percent_allowed),
            // Added fields
            team_fgm: sortedData.map(d => d.team_fgm),
            team_fga: sortedData.map(d => d.team_fga),
            team_ftm: sortedData.map(d => d.team_ftm),
            team_fta: sortedData.map(d => d.team_fta),
            team_fgm_allowed: sortedData.map(d => d.team_fgm_allowed),
            team_fga_allowed: sortedData.map(d => d.team_fga_allowed),
            team_ftm_allowed: sortedData.map(d => d.team_ftm_allowed),
            team_fta_allowed: sortedData.map(d => d.team_fta_allowed),
          };

          dataCache.current[teamAbbreviation] = newChartData;
          setChartData(newChartData);
        })
        .catch(error => console.error("Error fetching data:", error));
    } else if (dataCache.current[teamAbbreviation]) {
      setChartData(dataCache.current[teamAbbreviation]);
    }
  }, [teamAbbreviation, token, x]);

  return chartData;
};

export const fetchDataTeams = async (teamAbbreviation, token) => {
  try {
    const metrics = [
      "team_points", "team_fga", "team_ftm", "team_fgm", "team_fg3a",
      "team_fg3m", "team_oreb", "team_dreb", "team_reb", "team_ast",
      "team_tov", "team_stl", "team_blk", "team_TS_percent",
      "team_points_allowed", "team_fg2a_allowed", "team_fg2m_allowed",
      "team_fg3a_allowed", "team_fg3m_allowed", "team_oreb_allowed",
      "team_dreb_allowed", "team_reb_allowed", "team_ast_allowed",
      "team_tov_allowed", "team_stl_allowed", "team_blk_allowed",
      "team_TS_percent_allowed",
      // Added metrics
      "team_fgm", "team_fga", "team_ftm", "team_fta",
      "team_fgm_allowed", "team_fga_allowed", "team_ftm_allowed", "team_fta_allowed",
    ];
    const queryString = metrics.map(metric => `metrics=${metric}`).join("&");

    const data = await fetchData(`teams/filter_by_team/?team_abbreviation=${teamAbbreviation}&${queryString}&x=15`, token);

    return data.map(item => ({
      game_date: item.game_date,
      team_points: item.team_pts,
      team_fg2a: (item.team_fga - item.team_fg3a)*2,
      team_fg2m: (item.team_fgm - item.team_fg3m)*2,
      team_fg3a: item.team_fg3a,
      team_fg3m: item.team_fg3m*3,
      team_oreb: item.team_oreb,
      team_dreb: item.team_dreb,
      team_reb: item.team_reb,
      team_ast: item.team_ast,
      team_tov: item.team_tov,
      team_stl: item.team_stl,
      team_ftm: item.team_ftm,
      team_fta: item.team_fta,
      team_blk: item.team_blk,
      team_TS_percent: item.team_TS_percent,
      team_points_allowed: item.team_pts_allowed,
      team_fg2a_allowed: item.team_fg2a_allowed,
      team_fg2m_allowed: item.team_fg2m_allowed,
      team_fg3a_allowed: item.team_fg3a_allowed,
      team_fg3m_allowed: item.team_fg3m_allowed,
      team_oreb_allowed: item.team_oreb_allowed,
      team_dreb_allowed: item.team_dreb_allowed,
      team_reb_allowed: item.team_reb_allowed,
      team_ast_allowed: item.team_ast_allowed,
      team_tov_allowed: item.team_tov_allowed,
      team_stl_allowed: item.team_stl_allowed,
      team_blk_allowed: item.team_blk_allowed,
      team_TS_percent_allowed: item.team_TS_percent_allowed,
      team_abbreviation: item.team_abbreviation,
      opponent: item.opponent,
      // Added fields
      team_fgm: item.team_fgm,
      team_fga: item.team_fga,
      team_fgm_allowed: item.team_fgm_allowed,
      team_fga_allowed: item.team_fga_allowed,
      team_ftm_allowed: item.team_ftm_allowed,
      team_fta_allowed: item.team_fta_allowed,
    }));
  } catch (error) {
    console.error("Error fetching team data:", error);
    return [];
  }
};

export const fetchTeamPlayers = async (teamAbbreviation, gameDate, token) => {
  try {
    const metrics = [
      "pts", "fg2a", "fg2m", "ftm", "fg3a", "fg3m",
      "oreb", "dreb", "reb", "ast", "tov", "stl", "blk",
      // Added metrics
      "fga", "fgm", "fta",
    ];
    const queryString = metrics.map(metric => `metrics=${metric}`).join("&");

    const data = await fetchData(`teams/filter_by_team_and_date/?team_abbreviation=${teamAbbreviation}&game_date=${gameDate}&${queryString}`, token);

    return data.map(item => ({
      player_name: item.player_name,
      pts: item.pts,
      fg2a: item.fg2a,
      fg2m: item.fg2m,
      fg3a: item.fg3a,
      fg3m: item.fg3m,
      ftm: item.ftm,
      fta: item.fta,
      oreb: item.oreb,
      dreb: item.dreb,
      reb: item.reb,
      ast: item.ast,
      tov: item.tov,
      stl: item.stl,
      blk: item.blk,
      opponent: item.opponent,
      team: `team_${teamAbbreviation}`,
      // Added fields
      fgm: item.fgm,
      fga: item.fga,
    }));
  } catch (error) {
    console.error("Error fetching team players data:", error);
    return [];
  }
};

export default useFetchTeamChartData;
