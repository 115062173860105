import React, { createContext } from "react";
import { Auth0Provider as Auth0ReactProvider, useAuth0 } from "@auth0/auth0-react";

/**
 * Optional context if you need to provide additional data in the future.
 */
const Auth0Context = createContext(null);

/**
 * Auth0Provider:
 * - Reads environment variables for domain, clientId, audience.
 * - Uses window.location.origin as redirect URI (no /callback).
 * - On successful login, calls onRedirectCallback => navigates to the stored route or home.
 */
export const Auth0Provider = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    console.error("Auth0 environment variables are missing.");
    return null;
  }

  // Called by Auth0 after login completes
  const onRedirectCallback = (appState) => {
    // If user had a saved route, go there. Otherwise, fallback home.
    window.location.replace(appState?.returnTo || window.location.origin);
  };

  return (
    <Auth0ReactProvider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Context.Provider value={{}}>
        {children}
      </Auth0Context.Provider>
    </Auth0ReactProvider>
  );
};

/**
 * useAuth0Context:
 * A custom hook that returns a simplified Auth0 API:
 * - isAuthenticated, isLoading, user
 * - token() => returns an access token
 * - login() => triggers Auth0 login
 * - logout() => triggers Auth0 logout
 */
export const useAuth0Context = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return {
    isAuthenticated,
    isLoading,
    user,

    /**
     * Safely returns an access token. If user not logged in, returns null.
     */
    token: async () => {
      try {
        return await getAccessTokenSilently();
      } catch (error) {
        return null;
      }
    },

    /**
     * login: Accepts an optional "returnTo" string for redirect after login.
     * If invalid or missing, defaults to window.location.href (which is a string).
     */
    login: (returnTo) => {
      let safeReturnTo = window.location.href; // fallback
      // If the caller passed a valid string, use that
      if (typeof returnTo === "string" && returnTo.trim().length > 0) {
        safeReturnTo = returnTo;
      }
      // Pass only serializable data in appState
      loginWithRedirect({
        appState: { returnTo: safeReturnTo },
      });
    },

    /**
     * logout: returns the user to window.location.origin by default.
     */
    logout: () => logout({ returnTo: window.location.origin }),
  };
};
