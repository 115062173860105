import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactEcharts from 'echarts-for-react';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { fetchDefenseData } from './data/DefenseData';
import { useKeycloak } from '../KeycloakProvider';

const TeamDefense = ({ selectedFeature }) => {
  const { selectedTeam, teamColors } = usePlayerColors();
  const primaryColor = teamColors[0] || 'var(--primary-color)';
  const secondaryColor = teamColors[1] || 'var(--secondary-color)';
  const { token } = useKeycloak();
  const [chartData, setChartData] = useState(null);
  const [yAxisValues, setYAxisValues] = useState({ min: 0, max: 100 });
  const [loading, setLoading] = useState(true); // Added state for loading indication
  const dataCache = useRef({});
  const isDataFetched = useRef(false);

  const featureMapping = {
    'Total': 'team_pts_allowed',
    'AST': 'team_ast_allowed',
    'REB': 'team_reb_allowed',
    'BLK': 'team_blk_allowed',
    'STL': 'team_stl_allowed',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Ast+Reb': 'Ast+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb'
  };

  const fetchData = useCallback(async () => {
    if (!selectedTeam || isDataFetched.current) return;

    try {
      setLoading(true); // Set loading state to true before fetching
      const { teamData, yAxisValues } = await fetchDefenseData(selectedTeam, token);
      dataCache.current = teamData;
      setYAxisValues(yAxisValues);
      isDataFetched.current = true;
      updateChartData(selectedFeature);
      setLoading(false); // Set loading state to false after fetching
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading state is reset even if there's an error
    }
  }, [selectedTeam, token, selectedFeature]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isDataFetched.current) {
      updateChartData(selectedFeature);
    }
  }, [selectedFeature]);

  const updateChartData = useCallback((feature) => {
    if (!dataCache.current || !dataCache.current.values) return;

    const mappedFeature = featureMapping[feature];
    const { dates, opponents, values, rollingAvg } = dataCache.current;

    setChartData({
      dates,
      opponents,
      values: values[mappedFeature],
      rollingAvg: rollingAvg[mappedFeature]
    });
    setYAxisValues(prevYAxisValues => ({
      ...prevYAxisValues,
      [mappedFeature]: prevYAxisValues[mappedFeature] || { min: 0, max: 100 }
    }));
  }, []);

  const getOption = () => {
    if (!chartData) return {};

    const { dates, opponents, values, rollingAvg } = chartData;

    return {
      title: {
        left: '20px',
        text: `${selectedTeam} Defense with Rolling Average`,
        top: 10,
        textStyle: {
          fontSize: 20,
          fontWeight: 'bold',
          color: 'var(--dark-text-color)'
        }
      },
      legend: {
        data: [
          {
            name: selectedFeature,
            icon: 'circle',
            itemStyle: { color: primaryColor }
          },
          {
            name: 'Rolling Average',
            icon: 'circle',
            itemStyle: { color: secondaryColor }
          }
        ],
        top: 45,
        left: '20px',
        textStyle: {
          color: 'var(--medium-text-color)'
        },
        itemWidth: 10,
        itemHeight: 10
      },
      grid: {
        top: 90
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const date = dates[params[0].dataIndex];
          const opponent = params[0].name;
          const points = values[params[0].dataIndex];
          const rollingAverage = rollingAvg[params[0].dataIndex];

          return `
            Date: ${date}<br/>
            Opponent: ${opponent}<br/>
            ${selectedFeature}: ${points.toFixed(2)}<br/>
            Rolling Average: ${rollingAverage.toFixed(2)}
          `;
        }
      },
      xAxis: {
        type: 'category',
        data: opponents,
        axisLabel: {
          showMaxLabel: true,
          showMinLabel: true,
          interval: 0,
          rotate: 15,
          align: 'center',
          margin: 15,
          color: 'var(--dark-text-color)'
        }
      },
      yAxis: {
        min: yAxisValues[featureMapping[selectedFeature]]?.min,
        max: yAxisValues[featureMapping[selectedFeature]]?.max,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'var(--light-text-color)'
          }
        },
        axisLabel: {
          color: 'var(--dark-text-color)'
        }
      },
      series: [
        {
          name: selectedFeature,
          type: 'line',
          showSymbol: false,
          data: values,
          lineStyle: {
            width: 5,
            color: primaryColor
          },
          itemStyle: {
            color: primaryColor
          },
          smooth: true
        },
        {
          name: 'Rolling Average',
          type: 'line',
          showSymbol: false,
          data: rollingAvg,
          lineStyle: {
            color: secondaryColor,
            width: 3,
            type: 'dashed'
          },
          itemStyle: {
            color: secondaryColor
          },
          smooth: true
        }
      ]
    };
  };

  if (loading) {
    return <div>Loading defense data...</div>; // Display loading indicator while fetching
  }

  return (
    <div id="chart" style={{ height: '102%', width: '100%' }}>
      <ReactEcharts 
        option={getOption()} 
        style={{ height: '105%', width: '105%', marginRight: '25px' }} 
        opts={{ renderer: 'svg', devicePixelRatio: 2 }}
      />
    </div>
  );
};

export default TeamDefense;
