import React, { useState, useEffect } from "react";
import { useKeycloak } from "../KeycloakProvider"; 
import { fetchData } from "../api";
import "./HistoricalResults.css";

const allPossibleFeatures = [
  { label: "PTS", value: "pts" },
  { label: "AST", value: "ast" },
  { label: "REB", value: "reb" },
  { label: "Pts+Ast", value: "pts_ast" },
  { label: "Pts+Reb", value: "pts_reb" },
  { label: "Ast+Reb", value: "ast_reb" },
  { label: "Pts+Ast+Reb", value: "pts_ast_reb" },
];

const HistoricalResults = () => {
  const { token } = useKeycloak();
  const [data, setData] = useState({});
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(true);

  // 1. Filters
  //    Default: probability=70, money=100, aggregation=weekly
  //    Default selectedFeatures: ["pts", "ast", "reb"]
  const [filters, setFilters] = useState({
    probability: 70,
    money: 100,
    aggregation_level: "weekly",
  });

  // Keep the same default features
  const [selectedFeatures, setSelectedFeatures] = useState(["pts", "ast", "reb"]);

  // Add a single state for toggling the custom dropdown
  const [featureDropdownOpen, setFeatureDropdownOpen] = useState(false);

  // 2. Fetch data from API whenever filters change
  useEffect(() => {
    const fetchResults = async () => {
      if (!token) {
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const queryParams = new URLSearchParams(filters);
        const response = await fetchData(
          `player-prediction-results/filter_daily_results/?${queryParams}`,
          token
        );
        setData(response.aggregated_data || {});
        setTotals(response.totals || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchResults();
  }, [filters, token]);

  // 3. Handle numeric/single select filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // 4. Toggle an individual feature (checkbox style)
  const handleFeatureCheckboxChange = (value) => {
    setSelectedFeatures((prev) => {
      if (prev.includes(value)) {
        // remove if checked
        return prev.filter((f) => f !== value);
      } else {
        // add if unchecked
        return [...prev, value];
      }
    });
  };

  /**
   * 5. KPI Calculation
   *    Only sum up accuracy/profit/games for the *selectedFeatures*.
   */
  const calculateOverallKpi = () => {
    let sumOfHits = 0;
    let sumOfGames = 0;
    let sumOfProfit = 0;

    selectedFeatures.forEach((feature) => {
      const acc = totals.accuracy?.[feature] || 0; // 0-100
      const games = totals.games?.[feature] || 0;
      const profit = totals.profit?.[feature] || 0;

      sumOfHits += (acc / 100) * games;
      sumOfGames += games;
      sumOfProfit += profit;
    });

    const overallAccuracy =
      sumOfGames > 0 ? (sumOfHits / sumOfGames) * 100 : 0;

    return {
      overallAccuracy,
      overallProfit: sumOfProfit,
      overallGames: sumOfGames,
    };
  };

  // 6. Render KPI Cards for TOTAL + each selected feature
  const renderKPI = () => {
    const { overallAccuracy, overallProfit, overallGames } =
      calculateOverallKpi();

    return (
      <div className="kpi-container">
        {/* TOTAL KPI card */}
        <div
          key="total"
          className={`kpi-card ${
            overallProfit >= 0 ? "profitable" : "non-profitable"
          }`}
        >
          <p className="kpi-title">TOTAL</p>
          <p className="kpi-accuracy">
            Accuracy: <span>{overallAccuracy.toFixed(1)}%</span>
          </p>
          <p className="kpi-profit">
            Profit: ${overallProfit.toFixed(2)}
          </p>
          <p className="kpi-games">
            Games: {overallGames}
          </p>
        </div>

        {/* KPI for each SELECTED feature */}
        {selectedFeatures.map((feature) => {
          const accuracy = totals.accuracy?.[feature] || 0;
          const profit = totals.profit?.[feature] || 0;
          const games = totals.games?.[feature] || 0;

          return (
            <div
              key={feature}
              className={`kpi-card ${
                profit >= 0 ? "profitable" : "non-profitable"
              }`}
            >
              <p className="kpi-title">{feature.toUpperCase()}</p>
              <p className="kpi-accuracy">
                Accuracy: <span>{accuracy.toFixed(1)}%</span>
              </p>
              <p className="kpi-profit">
                Profit: ${profit.toFixed(2)}
              </p>
              <p className="kpi-games">
                Games: {games}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * 7. Table: Show only the selected features in the rows
   */
  const renderTable = () => {
    const periods = Object.keys(data); 
    if (periods.length === 0) {
      return <p>No data found.</p>;
    }

    // unique features from the server
    const aggregatedFeatures = [
      ...new Set(periods.flatMap((period) => Object.keys(data[period]))),
    ];

    // only keep features that the user selected
    const filteredFeatures = aggregatedFeatures.filter((f) =>
      selectedFeatures.includes(f)
    );

    return (
      <div className="table-wrapper">
        <table className="historical-results-table">
          <thead>
            <tr>
              <th>Feature</th>
              {periods.map((period) => (
                <th key={period}>{period}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredFeatures.map((feature) => (
              <tr key={feature}>
                <td>{feature.toUpperCase()}</td>
                {periods.map((period) => {
                  const rowData = data[period][feature] || {
                    accuracy: 0,
                    profit: 0,
                    games: 0,
                  };
                  return (
                    <td
                      key={period}
                      className={
                        rowData.profit >= 0 ? "profitable" : "non-profitable"
                      }
                    >
                      <div>Acc: {rowData.accuracy?.toFixed(1) || 0}%</div>
                      <div>Profit: ${rowData.profit?.toFixed(2) || 0}</div>
                    </td>
                  );
                })}
                {/* Totals column for this feature */}
                <td
                  className={
                    (totals.profit?.[feature] || 0) >= 0
                      ? "profitable"
                      : "non-profitable"
                  }
                >
                  <div>
                    Acc:{" "}
                    {totals.accuracy?.[feature]
                      ? totals.accuracy[feature].toFixed(1)
                      : 0}
                    %
                  </div>
                  <div>
                    Profit: $
                    {totals.profit?.[feature]
                      ? totals.profit[feature].toFixed(2)
                      : 0}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // 8. Loading / Auth states
  if (loading) {
    return <div className="loading">Loading...</div>;
  }
  if (!token) {
    return (
      <div className="historical-results-container">
        <h1>Please log in to view historical results.</h1>
      </div>
    );
  }

  /**
   * 9. Custom Multi-Select:
   *    Shows "X selected", toggles a simple checkbox list on click.
   *    We keep the rest of the code the same, no extra hooks.
   */

  // Count how many are selected
  const selectedCount = selectedFeatures.length;

  const toggleFeatureDropdown = () => {
    setFeatureDropdownOpen(!featureDropdownOpen);
  };

  return (
    <div className="historical-results-container">
      <h1>Historical Results Dashboard</h1>

      {/* Filters */}
      <div className="hr-filters">
        {/* Probability Filter */}
        <div className="hr-filter-item">
          <label htmlFor="probability">Probability (%)</label>
          <input
            id="probability"
            type="number"
            name="probability"
            value={filters.probability}
            onChange={handleFilterChange}
          />
        </div>

        {/* Money Filter */}
        <div className="hr-filter-item">
          <label htmlFor="money">Money ($)</label>
          <input
            id="money"
            type="number"
            name="money"
            value={filters.money}
            onChange={handleFilterChange}
          />
        </div>

        {/* Aggregation Level Filter */}
        <div className="hr-filter-item">
          <label htmlFor="aggregation">Aggregation</label>
          <select
            id="aggregation"
            name="aggregation_level"
            value={filters.aggregation_level}
            onChange={handleFilterChange}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>

        {/* Custom Multi-Select for Features */}
        <div className="hr-filter-item">
          <label>Features</label>
          {/* The clickable area */}
          <div className="hr-multi-select-control" onClick={toggleFeatureDropdown}>
            <span>
              {selectedCount === 0 ? "None selected" : `${selectedCount} selected`}
            </span>
            <div className="hr-multi-select-arrow" />
          </div>

          {/* If open, show checkboxes */}
          {featureDropdownOpen && (
            <div className="hr-multi-select-options">
              {allPossibleFeatures.map((feat) => {
                const isChecked = selectedFeatures.includes(feat.value);
                return (
                  <div key={feat.value} className="hr-option">
                    <label>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleFeatureCheckboxChange(feat.value)}
                      />
                      {feat.label}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* KPI */}
      {renderKPI()}

      {/* Table */}
      {renderTable()}
    </div>
  );
};

export default HistoricalResults;
