import { teamsDict } from './TeamInfo';
import schedule from '../matchup_dashboard_components/data/nba_schedule.json'; // Import raw JSON file

// Helper function to generate random picks and top picks
const generateMockPicks = () => {
  return {
    picks: Math.floor(Math.random() * 60) + 30, // Random picks between 30 and 90
    topPicks: Math.floor(Math.random() * 15) + 5 // Random top picks between 5 and 20
  };
};

// Helper function to generate a matchup object
const generateMatchup = (homeTeam, awayTeam, date, time, picks, topPicks) => {
  return {
    matchup: `${awayTeam} @ ${homeTeam}`, // Away team vs Home team format
    date,
    time,
    picks,
    topPicks
  };
};

/**
 * Return "today" in EST as a YYYY-MM-DD string.
 *   e.g., "2025-01-11"
 */
const getTodayInEST = () => {
  // Convert current system time to an EST string
  const nowESTString = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
  // Build a new Date from that EST string
  const nowEST = new Date(nowESTString);
  // Return the YYYY-MM-DD portion
  return nowEST.toISOString().split('T')[0];
};

// Function to extract the first occurrence of each team's matchup for *today in EST*
const extractFirstMatchupsForToday = (schedule) => {
  const seenTeams = new Set();
  const matchups = [];

  // 1) Get today's date in EST (YYYY-MM-DD)
  const todayEST = getTodayInEST();

  schedule.forEach(game => {
    const {
      'Home Team': homeTeam,
      'Away Team': awayTeam,
      Date: date,
      'Tip-off Time': time
    } = game;

    // Skip games not on today's EST date or with "TBD" time
    if (date !== todayEST || time === 'TBD') {
      return;
    }

    // Only add the first occurrence of a matchup for each team
    if (!seenTeams.has(homeTeam) && !seenTeams.has(awayTeam)) {
      seenTeams.add(homeTeam);
      seenTeams.add(awayTeam);

      const { picks, topPicks } = generateMockPicks(); // Generate mock picks and top picks

      // Create the matchup using the original date and time (already in EST)
      matchups.push(generateMatchup(homeTeam, awayTeam, date, time, picks, topPicks));
    }
  });

  // 2) Optional: Sort the matchups by time string if desired
  //    This is purely alphabetical, so if times are like "7:30 PM ET", "10:00 PM ET", 
  //    it should mostly work — but won't be perfect if times have inconsistent formatting.
  //
  // matchups.sort((a, b) => a.time.localeCompare(b.time));

  return matchups;
};

// Generate today's matchups in EST
export const matchups = extractFirstMatchupsForToday(schedule);
