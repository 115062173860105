import React from "react";

const CookiePolicy = () => {
  return (
    <div
      style={{
        fontFamily: "'Montserrat', sans-serif",
        lineHeight: 1.6,
        color: "#333",
        padding: "20px",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--main-color)",
          textAlign: "center",
        }}
      >
        Cookie Policy
      </h1>
      <p>
        <strong>Last Updated:</strong> 12/14/2024
      </p>

      <p>
        This Cookie Policy explains how <strong>Showstone</strong>
        (“we,” “our,” “us”) uses cookies and similar tracking technologies on
        our website. By using our website, you consent to the use of cookies as
        described in this policy.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device when you
        visit a website. They help the website recognize your device and store
        information about your preferences or actions.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>
        2. Types of Cookies We Use
      </h2>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These cookies are necessary for
          the website to function and cannot be switched off in our systems.
          They are usually set in response to your actions, such as setting
          your privacy preferences, logging in, or filling out forms.
        </li>
        <li>
          <strong>Performance Cookies:</strong> These cookies collect
          information about how visitors use our website, such as which pages
          are visited most often. This helps us improve the performance and
          user experience of our site.
        </li>
        <li>
          <strong>Functional Cookies:</strong> These cookies enable the website
          to provide enhanced functionality and personalization. They may be
          set by us or by third-party providers whose services we use.
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>
        3. How We Use Cookies
      </h2>
      <p>
        We use cookies to enhance your browsing experience, analyze website
        traffic, and serve targeted advertisements. Cookies help us understand
        how you interact with our site, improve functionality, and remember
        your preferences for future visits.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>4. Managing Cookies</h2>
      <p>
        You can manage your cookie preferences through your browser settings.
        Most browsers allow you to:
      </p>
      <ul>
        <li>Accept or reject all cookies.</li>
        <li>Delete specific cookies.</li>
        <li>Set preferences for certain websites.</li>
      </ul>
      <p>
        Please note that disabling cookies may impact your experience on our
        website and limit the functionality available to you.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>
        5. Third-Party Cookies
      </h2>
      <p>
        We may allow third-party service providers, such as analytics or
        advertising companies, to place cookies on your device. These cookies
        are governed by the third party's privacy and cookie policies. For
        example:
      </p>
      <ul>
        <li>
          <strong>Google Analytics:</strong> Tracks website usage and
          performance. See{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google’s Privacy Policy
          </a>{" "}
          for details.
        </li>
        <li>
          <strong>Advertising Partners:</strong> Deliver targeted ads based on
          your interests and browsing behavior.
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>
        6. Updates to This Cookie Policy
      </h2>
      <p>
        We may update this Cookie Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory
        reasons. Please review this page periodically for the latest
        information.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>7. Contact Us</h2>
      <p>
        If you have questions about this Cookie Policy, contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:showstone@showstone.io">showstone@showstone.io</a>
        </li>
 
      </ul>
    </div>
  );
};

export default CookiePolicy;
