import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlayerColors } from './main_dashboard_components/PlayerColorContext'; // Adjust the path as necessary
import './PlayerHeatmap.css';

const mockPlayers = [
  { id: 203954, line: { PTS: 20.5, AST: 5.5, BLK: 2.5, STL: 1.5 } },
  { id: 2544, line: { PTS: 18.5, AST: 6.5, BLK: 1.5, STL: 2.5 } },
  { id: 1630162, line: { PTS: 20.5, AST: 5.5, BLK: 2.5, STL: 1.5 } },
  { id: 1629627, line: { PTS: 18.5, AST: 6.5, BLK: 1.5, STL: 2.5 } },
  { id: 203992, line: { PTS: 20.5, AST: 5.5, BLK: 2.5, STL: 1.5 } },
  { id: 1627936, line: { PTS: 18.5, AST: 6.5, BLK: 1.5, STL: 2.5 } },
  { id: 203999, line: { PTS: 20.5, AST: 5.5, BLK: 2.5, STL: 1.5 } },
  { id: 1627832, line: { PTS: 18.5, AST: 6.5, BLK: 1.5, STL: 2.5 } },
  // Add more players as needed
];

const PlayerHeatmap = () => {
  const [players, setPlayers] = useState(mockPlayers);
  const [selectedStat, setSelectedStat] = useState('PTS');
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const { setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataForPlayers = async () => {
      const updatedPlayers = await Promise.all(
        players.map(async player => {
          const metrics = ['pts', 'ast', 'blk', 'stl', 'game_date', 'player_name'];
          const queryString = metrics.map(metric => `metrics=${metric}`).join('&');

          const response = await fetch(`http://localhost/api/players/filter_by_player/?player_id=${player.id}&${queryString}&x=15`);
          const data = await response.json();

          const sortedData = data.sort((a, b) => new Date(a.game_date) - new Date(b.game_date)).slice(0, 15);

          const PTS = sortedData.map(d => d.pts);
          const AST = sortedData.map(d => d.ast);
          const BLK = sortedData.map(d => d.blk);
          const STL = sortedData.map(d => d.stl);
          const player_name = sortedData[0]?.player_name || 'Unknown'; // Get player name from the first game data

          return { ...player, name: player_name, stats: { PTS, AST, BLK, STL } };
        })
      );
      setPlayers(updatedPlayers);
    };

    fetchDataForPlayers();
  }, []);

  useEffect(() => {
    // Sort players by the number of times the selected stat is above the line
    const sorted = [...players].sort((a, b) => {
      const aStats = a.stats && a.stats[selectedStat] ? a.stats[selectedStat] : [];
      const bStats = b.stats && b.stats[selectedStat] ? b.stats[selectedStat] : [];
      const aAbove = aStats.filter(value => value >= a.line[selectedStat]).length;
      const bAbove = bStats.filter(value => value >= b.line[selectedStat]).length;
      return bAbove - aAbove;
    });
    setSortedPlayers(sorted);
  }, [players, selectedStat]);

  const handlePlayerClick = (playerId) => {
    setSelectedPlayer(playerId);
    navigate('/PlayerDashboard');
  };

  const calculateSaturation = (difference) => {
    const maxDifference = 10; // Adjust based on expected maximum difference
    const saturation = Math.min(Math.abs(difference) / maxDifference, 0.35) * 100; // Max saturation 50%
    return saturation;
  };

  return (
    <div className="player-heatmap-container">
      <div className="button-container">
        <button onClick={() => setSelectedStat('PTS')}>PTS</button>
        <button onClick={() => setSelectedStat('AST')}>AST</button>
        <button onClick={() => setSelectedStat('BLK')}>BLK</button>
        <button onClick={() => setSelectedStat('STL')}>STL</button>
      </div>
      <h2 className="heatmap-title">Last 15 Vs Line ({selectedStat})</h2>
      <div className="heatmap-table-wrapper">
        <table className="player-heatmap-table">
          <thead>
            <tr>
              <th>Player</th>
              {Array.from({ length: 15 }, (_, i) => <th key={i}>G{i + 1}</th>)}
            </tr>
          </thead>
          <tbody>
            {sortedPlayers.map((player, index) => (
              <tr key={index}>
                <td>
                  <a onClick={() => handlePlayerClick(player.id)} className="player-link">
                    {player.name}
                  </a>
                </td>
                {(player.stats && player.stats[selectedStat] ? player.stats[selectedStat] : Array(15).fill(null)).map((value, i) => {
                  const difference = value !== null ? (value - player.line[selectedStat]).toFixed(1) : null;
                  const saturation = value !== null ? calculateSaturation(difference) : 0;
                  const cellColor = value !== null && value >= player.line[selectedStat] ? `hsl(120, ${saturation}%, 50%)` : `hsl(0, ${saturation}%, 50%)`;
                  return (
                    <td key={i} className="heatmap-cell" style={{ backgroundColor: cellColor }}>
                      {difference !== null ? `${difference > 0 ? '+' : ''}${difference}` : '-'}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerHeatmap;
