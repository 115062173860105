import { useState, useEffect, useRef } from 'react';
import { fetchData } from '../../api';  // Import your utility
import { useKeycloak } from '../../KeycloakProvider';  // Import the Keycloak context

const useFetchL15ChartData = (selectedPlayer, selectedFeature, x = 15) => {
  const [chartData, setChartData] = useState({});
  const dataCache = useRef({});
  const { token } = useKeycloak();  // Get the token from Keycloak

  useEffect(() => {
    
    if (selectedPlayer && selectedFeature && token) {  // Ensure token is available
      const cacheKey = `${selectedPlayer}-${selectedFeature}`;
      if (!dataCache.current[cacheKey]) {
        

        const commonMetrics = ['game_date', 'opponent', 'min', 'home_or_away'];
        let featureMetrics = [];

        switch (selectedFeature) {
          case 'Total':
            featureMetrics = ['ftm', 'fg2m', 'fg3m', 'pts', 'min'];
            break;
          case 'Pts+Ast':
            featureMetrics = ['pts', 'ast'];
            break;
          case 'Pts+Reb':
            featureMetrics = ['pts', 'reb'];
            break;
          case 'Pts+Ast+Reb':
            featureMetrics = ['pts', 'ast', 'reb'];
            break;
          case 'Ast+Reb':
            featureMetrics = ['ast', 'reb'];
            break;
          case 'AST':
            featureMetrics = ['ast', 'min', 'passes', 'touches'];
            break;
          case 'REB':
            featureMetrics = ['reb', 'oreb', 'dreb'];
            break;
          case 'BLK':
            featureMetrics = ['blk'];
            break;
          case 'STL':
            featureMetrics = ['stl'];
            break;
          default:
            console.error('Unknown feature:', selectedFeature);
            return;
        }

        const metrics = [...commonMetrics, ...featureMetrics];
        const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
        const endpoint = `players/filter_by_player/?player_id=${selectedPlayer}&${queryString}&x=${x}`;

        // Call the fetchData function and pass the token
        fetchData(endpoint, token)
          .then(data => {
            const sortedData = data.sort((a, b) => new Date(a.game_date) - new Date(b.game_date)).slice(0, x);

            let newChartData = {
              Dates: sortedData.map(d => d.game_date),
              Opponents: sortedData.map(d => d.opponent),
              Minutes: sortedData.map(d => d.min),
              HomeOrAway: sortedData.map(d => d.home_or_away),
            };

            switch (selectedFeature) {
              case 'Total':
                newChartData.FT = sortedData.map(d => d.ftm);
                newChartData.PT2 = sortedData.map(d => d.fg2m * 2);
                newChartData.PT3 = sortedData.map(d => d.fg3m * 3);
                newChartData.Total = sortedData.map(d => d.ftm + d.fg2m * 2 + d.fg3m * 3);
                break;
              case 'Pts+Ast':
                newChartData.Total = sortedData.map(d => d.pts);
                newChartData.AST = sortedData.map(d => d.ast);
                break;
              case 'Pts+Reb':
                newChartData.Total = sortedData.map(d => d.pts);
                newChartData.REB = sortedData.map(d => d.reb);
                break;
              case 'Pts+Ast+Reb':
                newChartData.Total = sortedData.map(d => d.pts);
                newChartData.AST = sortedData.map(d => d.ast);
                newChartData.REB = sortedData.map(d => d.reb);
                break;
              case 'Ast+Reb':
                newChartData.AST = sortedData.map(d => d.ast);
                newChartData.REB = sortedData.map(d => d.reb);
                break;
              case 'AST':
                newChartData.AST = sortedData.map(d => d.ast);
                break;
              case 'REB':
                newChartData.REB = sortedData.map(d => d.reb);
                break;
              case 'BLK':
                newChartData.BLK = sortedData.map(d => d.blk);
                break;
              case 'STL':
                newChartData.STL = sortedData.map(d => d.stl);
                break;
              default:
                console.error('Unknown feature:', selectedFeature);
                return;
            }

            dataCache.current[cacheKey] = newChartData;
            setChartData(newChartData);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } else {
        setChartData(dataCache.current[cacheKey]);
      }
    }
  }, [selectedPlayer, selectedFeature, x, token]);  // Make sure to include token as a dependency

  return chartData;
};

export default useFetchL15ChartData;
