import React, { useState } from 'react';
import './Leaderboard.css';

const easternConferenceData = [
  { rank: 1, team: 'Boston Celtics', logo: '/team_logos/celtics.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 2, team: 'Orlando Magic', logo: '/team_logos/magic.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 3, team: 'Toronto Raptors', logo: '/team_logos/raptors.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 4, team: 'Detroit Pistons', logo: '/team_logos/pistons.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 5, team: 'Chicago Bulls', logo: '/team_logos/bulls.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 6, team: 'Washington Wizards', logo: '/team_logos/wizards.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 7, team: 'Brooklyn Nets', logo: '/team_logos/nets.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 8, team: 'New York Knicks', logo: '/team_logos/knicks.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 9, team: 'Indiana Pacers', logo: '/team_logos/pacers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 10, team: 'Miami Heat', logo: '/team_logos/heat.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 11, team: 'Cleveland Cavaliers', logo: '/team_logos/cavaliers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 12, team: 'Milwaukee Bucks', logo: '/team_logos/bucks.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 13, team: 'Atlanta Hawks', logo: '/team_logos/hawks.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 14, team: 'Philadelphia 76ers', logo: '/team_logos/76ers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 15, team: 'Charlotte Hornets', logo: '/team_logos/hornets.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] }
];
  
const westernConferenceData = [
  { rank: 1, team: 'Los Angeles Lakers', logo: '/team_logos/lakers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 2, team: 'Golden State Warriors', logo: '/team_logos/warriors.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 3, team: 'Phoenix Suns', logo: '/team_logos/suns.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 4, team: 'Denver Nuggets', logo: '/team_logos/nuggets.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 5, team: 'Dallas Mavericks', logo: '/team_logos/mavericks.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 6, team: 'Portland Trail Blazers', logo: '/team_logos/blazers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 7, team: 'San Antonio Spurs', logo: '/team_logos/spurs.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 8, team: 'Utah Jazz', logo: '/team_logos/jazz.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 9, team: 'Houston Rockets', logo: '/team_logos/rockets.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 10, team: 'Oklahoma City Thunder', logo: '/team_logos/thunder.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 11, team: 'Minnesota Timberwolves', logo: '/team_logos/timberwolves.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 12, team: 'New Orleans Pelicans', logo: '/team_logos/pelicans.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 13, team: 'Sacramento Kings', logo: '/team_logos/kings.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 14, team: 'Memphis Grizzlies', logo: '/team_logos/grizzlies.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] },
  { rank: 15, team: 'Los Angeles Clippers', logo: '/team_logos/clippers.png', W: 43, L: 21, WinPercent: '51%', GB: 0, Conf: '21-5', Div: '21-5', Home: '21-5', Away: '21-5', L15: '21-5', Streak: 'W2', form: ['L', 'W', 'W', 'W', 'W'] }
];
  

const getFormClass = (result) => {
  switch (result) {
    case 'W': return 'win';
    case 'D': return 'draw';
    case 'L': return 'loss';
    default: return '';
  }
};

const Leaderboard = () => {
  const [conference, setConference] = useState('Eastern');

  const handleToggle = () => {
    setConference((prevConference) => (prevConference === 'Eastern' ? 'Western' : 'Eastern'));
  };

  const data = conference === 'Eastern' ? easternConferenceData : westernConferenceData;

  return (
    <div className="leaderboard-container">
      <div className="conference-header">
        <img src={conference === 'Eastern' ? 'east.png' : 'west.png'} alt="Conference Logo" className="conference-logo" />
        <span>{conference} Conference</span>
        <button onClick={handleToggle} className="toggle-arrow">{conference === 'Eastern' ? '▶' : '◀'}</button>
      </div>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Team</th>
            <th>W</th>
            <th>L</th>
            <th>Win%</th>
            <th>GB</th>
            <th>Conf</th>
            <th>Div</th>
            <th>Home</th>
            <th>Away</th>
            <th>L15</th>
            <th>Streak</th>
            <th>Form</th>
          </tr>
        </thead>
        <tbody>
          {data.map((team, index) => (
            <tr key={index} className={`rank-${team.rank}`}>
              <td>
                <span className={`team-rank rank-${team.rank}`}>{team.rank}</span>
                <img src={team.logo} alt={team.team} className="team-logo" />
                {team.team}
              </td>
              <td>{team.W}</td>
              <td>{team.L}</td>
              <td>{team.WinPercent}</td>
              <td>{team.GB}</td>
              <td>{team.Conf}</td>
              <td>{team.Div}</td>
              <td>{team.Home}</td>
              <td>{team.Away}</td>
              <td>{team.L15}</td>
              <td>{team.Streak}</td>
              <td>
                <div className="form-container">
                  {team.form.map((result, i) => (
                    <span key={i} className={`form-dot ${getFormClass(result)}`}></span>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;