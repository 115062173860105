// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useKeycloak, AuthWrapper } from './KeycloakProvider';
import PlayerDashboard from './dashboards/PlayerDashboard';
import TeamDashboard from './dashboards/TeamDashboard';
import PlayerID from './main_dashboard_components/PlayerID';
import { PlayerColorProvider } from './main_dashboard_components/PlayerColorContext';
import Leaderboard from './Leaderboard';
import SelectedPlayers from './SelectedPlayers';
import HeatMap from './PlayerHeatmap';
import TicketWatcher from './dashboards/TicketWatcher';
import TicketAnalyzer from './dashboards/TicketAnalyzer';
import MatchupDashboard from './dashboards/MatchupDashboard';
import MatchupMenu from './components/MatchupMenu';
import NavigationMenu from './components/NavigationMenu';
import TicketsPage from './dashboards/TicketsPage';
import PlayerFeatureTable from './predictions_components/PlayerFeatureTable';
import PredictionResultsTable from './predictions_components/PredictionResultsTable';
import LiveData from './LiveData.js';
import SubscriptionPage from './paypal_components/SubscriptionPage.js';
import CookieConsent from 'react-cookie-consent';
import appInsights from './AppInsights.js';
import Footer from './Footer.js';
import PrivacyPolicy from './policies/PrivacyPolicy.js';
import CookiePolicy from './policies/CookiePolicy.js';
import TermsOfService from './policies/TermsOfService.js';
import TopPickCards from './predictions_components/TopPickCards.js';
import HistoricalResults from './predictions_components/HistoricalResults.js';
import Login from './Login.js';
import LandingPage from './LandingPage.js';
import PostSubscription from './paypal_components/PostSubscription.js';

const ProtectedRoute = ({ children }) => {
  const { authenticated, loading, keycloak } = useKeycloak();

  if (!authenticated) {
    keycloak?.login(); // Trigger login if not authenticated
    return null;
  }

  return children;
};

const App = () => {
  const [showMatchupMenu, setShowMatchupMenu] = useState(true);
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  // -- New State for Beta Popup --
  const [showBetaPopup, setShowBetaPopup] = useState(false);

  /**
   * Show or hide the matchup menu on scroll
   */
  useEffect(() => {
    const handleScroll = () => {
      setShowMatchupMenu(window.scrollY <= 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /**
   * On mount, check cookie to see if user previously accepted/declined
   */
  useEffect(() => {
    const consentCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('preLoginConsent='));
    const consent = consentCookie?.split('=')[1] === 'true';
    setAnalyticsEnabled(consent);
  }, []);

  /**
   * Whenever analyticsEnabled changes, toggle both Azure App Insights and GA
   */
  useEffect(() => {
    if (analyticsEnabled) {
      // Enable Azure App Insights telemetry
      appInsights.config.disableTelemetry = false;
      if (window.appInsights) {
        window.appInsights.setEnabled(true);
      }

      // Dynamically load the GA script
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-BFGVRNERGT';
      document.head.appendChild(gaScript);

      gaScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-BFGVRNERGT');
        console.log('Google Analytics is enabled');
      };
    } else {
      // Disable Azure App Insights
      appInsights.config.disableTelemetry = true;
      if (window.appInsights) {
        window.appInsights.setEnabled(false);
      }

      // Optional: remove any existing GA script from DOM
      const existingGaScript = document.querySelector(
        'script[src*="googletagmanager.com/gtag/js"]'
      );
      if (existingGaScript) {
        existingGaScript.remove();
        console.log('Google Analytics script removed');
      }
    }
  }, [analyticsEnabled]);

  /**
   * User accepts cookies => write cookie, enable analytics
   */
  const handleAcceptCookies = () => {
    document.cookie = `preLoginConsent=true; path=/;`;
    setAnalyticsEnabled(true);
    console.log('Cookie Tracking Enabled');
  };

  /**
   * User declines cookies => write cookie, disable analytics
   */
  const handleDeclineCookies = () => {
    document.cookie = `preLoginConsent=false; path=/;`;
    setAnalyticsEnabled(false);
    console.log('Cookie Tracking Disabled');
  };

  // -- BETA POPUP Logic (Show every 48h) --
  useEffect(() => {
    const lastShown = localStorage.getItem('betaPopupLastSeen');
    const now = Date.now();
    const FORTY_EIGHT_HOURS = 48 * 60 * 60 * 1000; // in ms

    if (!lastShown) {
      // never shown => show now
      setShowBetaPopup(true);
    } else {
      const diff = now - parseInt(lastShown, 10);
      if (diff > FORTY_EIGHT_HOURS) {
        // last shown > 48 hrs => show again
        setShowBetaPopup(true);
      }
    }
  }, []);

  const handleCloseBetaPopup = () => {
    setShowBetaPopup(false);
    localStorage.setItem('betaPopupLastSeen', Date.now().toString());
  };

  return (
    <AuthWrapper>
      <PlayerColorProvider>
        <Router>
          <div className="App">
            <MatchupMenu visible={showMatchupMenu} />
            <NavigationMenu />

            {/* Popup for Beta Thank-You */}
            {showBetaPopup && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 9999,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#fff',
                    width: '90%',
                    maxWidth: '500px',
                    borderRadius: '8px',
                    padding: '20px',
                    fontFamily: 'var(--font-main)',
                    textAlign: 'center',
                  }}
                >
                  <h2 style={{ color: 'var(--main-color)' }}>
                    Thank You for Participating in Our Beta!
                  </h2>
                  <p style={{ marginTop: '1rem', lineHeight: '1.4em' }}>
                    We’re excited to announce our official launch. Your feedback has helped
                    us refine our analytics and grow our community. If you enjoyed exploring
                    our website and want to unlock all of our features, we invite you to
                    subscribe to our Premium plan. Your support will help us scale, add more
                    features, and continue delivering data-driven insights for NBA fans.
                  </p>
                  <button
                    style={{
                      marginTop: '1.5rem',
                      backgroundColor: 'var(--main-color)',
                      color: '#fff',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontFamily: 'var(--font-main)',
                      fontSize: '1rem',
                    }}
                    onClick={() => {
                      // close popup & navigate
                      handleCloseBetaPopup();
                      window.location.href = '/Subscription';
                    }}
                  >
                    Go Premium
                  </button>
                  <button
                    style={{
                      marginLeft: '15px',
                      marginTop: '1.5rem',
                      backgroundColor: '#ccc',
                      color: '#333',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontFamily: 'var(--font-main)',
                      fontSize: '1rem',
                    }}
                    onClick={handleCloseBetaPopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {/* Cookie Consent Banner */}
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline Non-Essential Cookies"
              enableDeclineButton
              style={{
                background: 'var(--main-color)',
                color: 'white',
                fontFamily: 'var(--font-main)',
                textAlign: 'center',
              }}
              buttonStyle={{
                background: 'var(--detail-color)',
                color: 'white',
                fontFamily: 'var(--font-roboto)',
                border: 'none',
                borderRadius: '5px',
                padding: '10px 20px',
                cursor: 'pointer',
              }}
              declineButtonStyle={{
                background: 'transparent',
                color: 'white',
                fontFamily: 'var(--font-roboto)',
                border: '1px solid white',
                borderRadius: '5px',
                padding: '10px 20px',
                cursor: 'pointer',
              }}
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              buttonWrapperStyle={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                gap: '10px',
              }}
              onAccept={handleAcceptCookies}
              onDecline={handleDeclineCookies}
            >
              <div style={{ fontFamily: 'var(--font-main)', fontSize: '16px' }}>
                We use essential cookies to provide core functionality, 
                such as security, session management, and accessibility. 
                These cookies cannot be turned off and are always active. 
                By accepting, you agree to additional cookies for analytics and 
                improvements. Declining means you will only be tracked with 
                essential cookies necessary for the site to function. 
                Learn more in our <a href="/CookiePolicy" style={{ color: 'var(--detail-color)' }}>Cookie Policy</a>.
              </div>
            </CookieConsent>

            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/CookiePolicy" element={<CookiePolicy />} />
              <Route path="/TermsOfService" element={<TermsOfService />} />
              <Route path="/Live" element={<LiveData />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Subscription" element={<SubscriptionPage />} />
              <Route path="/PostSubscription" element={<PostSubscription />} />

              {/* Protected Routes */}
              <Route
                path="/PlayerDashboard"
                element={
                  <ProtectedRoute>
                    <PlayerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PlayerDashboard/:playerId"
                element={
                  <ProtectedRoute>
                    <PlayerID />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TeamDashboard"
                element={
                  <ProtectedRoute>
                    <TeamDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/HistoricalResults"
                element={
                  <ProtectedRoute>
                    <HistoricalResults />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TopPicks"
                element={
                  <ProtectedRoute>
                    <TopPickCards />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/MatchupDashboard"
                element={
                  <ProtectedRoute>
                    <MatchupDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Leaderboard"
                element={
                  <ProtectedRoute>
                    <Leaderboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketWatcher"
                element={
                  <ProtectedRoute>
                    <TicketWatcher />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/TicketAnalyzer"
                element={
                  <ProtectedRoute>
                    <TicketAnalyzer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/SelectedPlayers"
                element={
                  <ProtectedRoute>
                    <SelectedPlayers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/HeatMap"
                element={
                  <ProtectedRoute>
                    <HeatMap />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Tickets"
                element={
                  <ProtectedRoute>
                    <TicketsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Projections"
                element={
                  <ProtectedRoute>
                    <PlayerFeatureTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Results"
                element={
                  <ProtectedRoute>
                    <PredictionResultsTable />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
          <Footer />
        </Router>
      </PlayerColorProvider>
    </AuthWrapper>
  );
};

export default App;
