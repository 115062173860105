import React, { useState, useEffect } from 'react';
import { scheduleData } from './data/ScheduleData'; // Import scheduleData function
import './Schedule.css';
import { teamsDict } from '../components/TeamInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import { useKeycloak } from '../KeycloakProvider'; // Import the Keycloak context

const Schedule = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const { token } = useKeycloak(); // Get the Keycloak instance with the token
  const [selectedTeam, setSelectedTeam] = useState(homeTeamAbbr);
  const [teamSchedule, setTeamSchedule] = useState([]);

  useEffect(() => {
    setSelectedTeam(homeTeamAbbr);
  }, [homeTeamAbbr, awayTeamAbbr]);

  useEffect(() => {
    const fetchTeamSchedule = async () => {
      if (token && selectedTeam) {
        const schedule = await scheduleData(selectedTeam, token);
        if (schedule && schedule[selectedTeam]) {
          setTeamSchedule(schedule[selectedTeam]);
        } else {
          setTeamSchedule([]); // Fallback to an empty array if no data
        }
      }
    };

    fetchTeamSchedule();
  }, [token, selectedTeam]);

  const homeTeamFullName = Object.keys(teamNameToAbbreviation).find(
    key => teamNameToAbbreviation[key] === homeTeamAbbr
  );
  const awayTeamFullName = Object.keys(teamNameToAbbreviation).find(
    key => teamNameToAbbreviation[key] === awayTeamAbbr
  );

  if (!teamSchedule || teamSchedule.length === 0) {
    return <div>No schedule available for this team.</div>;
  }

  return (
    <div className="schedule-container">
      <h2 style={{ fontFamily:'var(--font-main)', color:'#4F4F4F' ,textAlign: 'left', marginLeft: '22px' }}>Schedule</h2>

      <div className="schedule-team-toggle">
        <button
          className={`schedule-team-button ${selectedTeam === homeTeamAbbr ? 'active' : ''}`}
          onClick={() => setSelectedTeam(homeTeamAbbr)}
        >
          <img
            src={`/team_logos/${homeTeamFullName.toLowerCase()}.png`}
            alt={`${homeTeamFullName} logo`}
            className="schedule-team-logo-button"
          />
          {homeTeamFullName}
        </button>
        <button
          className={`schedule-team-button ${selectedTeam === awayTeamAbbr ? 'active' : ''}`}
          onClick={() => setSelectedTeam(awayTeamAbbr)}
        >
          <img
            src={`/team_logos/${awayTeamFullName.toLowerCase()}.png`}
            alt={`${awayTeamFullName} logo`}
            className="schedule-team-logo-button"
          />
          {awayTeamFullName}
        </button>
      </div>

      <div className="schedule-table-wrapper">
        <table className="schedule-table">
          <thead>
            <tr>
              <th>DATE</th>
              <th>VS</th>
              <th>SCORE</th>
              <th>POINTS</th>
            </tr>
          </thead>
          <tbody>
            {teamSchedule.map((game, index) => {
              const opponentFullName = Object.keys(teamNameToAbbreviation).find(
                key => teamNameToAbbreviation[key] === game.opponent
              );

              const isHomeTeam = game.location === 'Home';
              const [teamPts, opponentPts] = game.score.split('-');
              const teamPtsStyle = {
                color: game.teamPts > game.opponentPts ? '#28a745' : '#dc3545', // Green if won, red if lost
                fontWeight: 600
              };

              return (
                <tr key={index}>
                  <td>{game.date}</td>
                  <td>
                    {isHomeTeam ? (
                      <>
                        vs <img
                          src={`/team_logos/${opponentFullName.toLowerCase()}.png`}
                          alt={`${opponentFullName} logo`}
                          className="schedule-team-logo-mini"
                        />
                        {teamNameToAbbreviation[opponentFullName]}
                      </>
                    ) : (
                      <>
                        @ <img
                          src={`/team_logos/${opponentFullName.toLowerCase()}.png`}
                          alt={`${opponentFullName} logo`}
                          className="schedule-team-logo-mini"
                        />
                        {teamNameToAbbreviation[opponentFullName]}
                      </>
                    )}
                  </td>
                  <td>
                    <span style={teamPtsStyle}>{teamPts}</span>-{opponentPts}
                  </td>
                  <td>
                    {game.pointsTotal}{' '}
                    {game.pointsTotal >= 240 ? (
                      <span className="schedule-green">+</span>
                    ) : (
                      <span className="schedule-red">-</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Schedule;