import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { teamsDict } from '../../components/TeamInfo';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import './TeamComparisonBarChart.css';
import useFetchTeamChartData from '../../team_dashboard_components/data/TeamPointsDrilldownData';
import { useKeycloak } from '../../KeycloakProvider'; // Import the Keycloak context

// Function to calculate contrast ratio
const getContrastRatio = (color1, color2) => {
  const getLuminance = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
  };

  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

// Function to select the most distinct away team color
const selectMostDistinctColor = (homeColor, awayColors) => {
  let maxContrast = 0;
  let distinctColor = awayColors[0];

  awayColors.forEach((color) => {
    const contrast = getContrastRatio(homeColor, color);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      distinctColor = color;
    }
  });

  return distinctColor;
};

const features = [
  { key: 'team_points', label: 'PPG' },
  { key: 'team_ast', label: 'APG' },
  { key: 'team_reb', label: 'RPG' },
  { key: 'team_stl', label: 'STL' },
  { key: 'team_fg3_pct', label: '3PT%' },
  { key: 'team_ft_pct', label: 'FT%' },
  { key: 'team_fg_pct', label: 'FG%' },
  { key: 'team_blk', label: 'BLK' },
  { key: 'team_tov', label: 'TOV' },
];

const percentageFeatures = ['team_fg_pct', 'team_ft_pct', 'team_fg3_pct'];

const TeamComparisonBarChart = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const [selectedFeature, setSelectedFeature] = useState('team_points');
  const [options, setOptions] = useState({});
  const { token } = useKeycloak(); // Get the token from Keycloak
  const homeTeamData = useFetchTeamChartData(homeTeamAbbr, token, 15);
  const awayTeamData = useFetchTeamChartData(awayTeamAbbr, token, 15);

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const homeTeamFullName = reverseTeamAbbreviation[homeTeamAbbr];
  const awayTeamFullName = reverseTeamAbbreviation[awayTeamAbbr];

  const homeTeamInfo = teamsDict[homeTeamFullName];
  const awayTeamInfo = teamsDict[awayTeamFullName];

  const homeTeamColor = homeTeamInfo.colors[0] || '#ff7f0e';
  const awayTeamColor = selectMostDistinctColor(homeTeamColor, awayTeamInfo.colors.slice(0, 2));

  useEffect(() => {
    if (homeTeamData && awayTeamData && homeTeamData.Dates && awayTeamData.Dates) {
      let homeData = [];
      let awayData = [];
      let yAxisMax;
      let yAxisMin = 0;
      let isPercentageFeature = percentageFeatures.includes(selectedFeature);

      // Check if required data fields are available
      const isDataAvailable = (teamData, fields) => {
        return fields.every((field) => teamData[field]);
      };
   
      if (isPercentageFeature) {
        if (selectedFeature === 'team_fg_pct') {
          // Check if required fields exist
          if (
            isDataAvailable(homeTeamData, ['team_fgm', 'team_fga']) &&
            isDataAvailable(awayTeamData, ['team_fgm', 'team_fga'])
          ) {
            // FG% = FGM / FGA * 100
            homeData = homeTeamData.team_fgm.map((fgm, index) => {
              const fga = homeTeamData.team_fga[index];
              return fga ? ((fgm / fga) * 100).toFixed(1) : 0;
            });
            awayData = awayTeamData.team_fgm.map((fgm, index) => {
              const fga = awayTeamData.team_fga[index];
              return fga ? ((fgm / fga) * 100).toFixed(1) : 0;
            });
            yAxisMax = 100;
          } else {
            console.error('FGM or FGA data is missing for one of the teams.');
            return;
          }
        } else if (selectedFeature === 'team_ft_pct') {
          // Check if required fields exist
          if (
            isDataAvailable(homeTeamData, ['team_ftm', 'team_fta']) &&
            isDataAvailable(awayTeamData, ['team_ftm', 'team_fta'])
          ) {
            // FT% = FTM / FTA * 100
            homeData = homeTeamData.team_ftm.map((ftm, index) => {
              const fta = homeTeamData.team_fta[index];
              return fta ? ((ftm / fta) * 100).toFixed(1) : 0;
            });
            awayData = awayTeamData.team_ftm.map((ftm, index) => {
              const fta = awayTeamData.team_fta[index];
              return fta ? ((ftm / fta) * 100).toFixed(1) : 0;
            });
            yAxisMax = 100;
          } else {
            console.error('FTM or FTA data is missing for one of the teams.');
            return;
          }
        } else if (selectedFeature === 'team_fg3_pct') {
          // Check if required fields exist
          if (
            isDataAvailable(homeTeamData, ['team_fg3m', 'team_fg3a']) &&
            isDataAvailable(awayTeamData, ['team_fg3m', 'team_fg3a'])
          ) {
            // 3PT% = FG3M / FG3A * 100
            homeData = homeTeamData.team_fg3m.map((fg3m, index) => {
              const fg3a = homeTeamData.team_fg3a[index];
              return fg3a ? ((fg3m / fg3a) * 100).toFixed(1) : 0;
            });
            awayData = awayTeamData.team_fg3m.map((fg3m, index) => {
              const fg3a = awayTeamData.team_fg3a[index];
              return fg3a ? ((fg3m / fg3a) * 100).toFixed(1) : 0;
            });
            yAxisMax = 100;
          } else {
            console.error('FG3M or FG3A data is missing for one of the teams.');
            return;
          }
        }
      } else {
        // For other features
        if (homeTeamData[selectedFeature] && awayTeamData[selectedFeature]) {
          homeData = homeTeamData[selectedFeature];
          awayData = awayTeamData[selectedFeature];
          const allValues = [...homeData, ...awayData];
          const maxValue = Math.max(...allValues);
          yAxisMax = maxValue + maxValue * 0.1;
        } else {
          console.error(`Data for ${selectedFeature} is missing for one of the teams.`);
          return;
        }
      }

      const newOptions = {
        title: {
          text: `Team Comparison - Last 15 Games (${features.find(f => f.key === selectedFeature)?.label})`,
          left: 'center',
          top: 0,
        },
        legend: {
          data: [homeTeamFullName, awayTeamFullName],
          top: 30,
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            return params
              .map((item) => {
                const value = isPercentageFeature ? `${item.value}%` : item.value;
                return `${item.marker} ${item.seriesName}: ${value}`;
              })
              .join('<br/>');
          },
        },
        xAxis: {
          type: 'category',
          data: homeTeamData.Dates.map((date, index) => `Game ${index + 1}`),
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
        },
        yAxis: {
          type: 'value',
          max: yAxisMax,
          min: yAxisMin,
        },
        series: [
          {
            name: homeTeamFullName,
            type: 'bar',
            data: homeData,
            itemStyle: {
              color: homeTeamColor,
            },
            label: {
              show: true,
              position: 'top',
              formatter: ({ value }) => (isPercentageFeature ? `${value}%` : value),
              fontSize: 10,
              color: '#333',
            },
          },
          {
            name: awayTeamFullName,
            type: 'bar',
            data: awayData,
            itemStyle: {
              color: awayTeamColor,
            },
            label: {
              show: true,
              position: 'top',
              formatter: ({ value }) => (isPercentageFeature ? `${value}%` : value),
              fontSize: 10,
              color: '#333',
            },
          },
        ],
      };
      setOptions(newOptions);
    }
  }, [
    selectedFeature,
    homeTeamData,
    awayTeamData,
    homeTeamFullName,
    awayTeamFullName,
    homeTeamColor,
    awayTeamColor,
  ]);

  return (
    <div className="team-comparison-bar-chart-container">
      <div className="team-comparison-bar-feature-button-container">
        {features.map((feature) => (
          <button
            key={feature.key}
            onClick={() => setSelectedFeature(feature.key)}
            className={`team-comparison-bar-feature-button ${
              selectedFeature === feature.key ? 'selected' : ''
            }`}
          >
            {feature.label}
          </button>
        ))}
      </div>
      {options && options.series ? (
        <ReactECharts option={options} style={{ height: '400px' }} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default TeamComparisonBarChart;
