import React, { useState, useMemo, useEffect } from 'react';
import useFetchPlayerProps from './data/PlayerPropData'; // Custom hook to fetch player prop data
import { usePlayerColors } from './PlayerColorContext'; // Context for getting selected player
import { players } from '../components/PlayerInfo'; // For player name lookup
import './PlayerPropsTable.css';

const PlayerPropsTable = ({ selectedFeature }) => {
  const { selectedPlayer } = usePlayerColors();

  // Resolve the player's name
  const playerName = players[selectedPlayer]?.name || "Unknown Player";
  const playerProps = useFetchPlayerProps(selectedFeature, playerName);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Track whether we use American or European odds
  // We'll read from localStorage on mount...
  const [useAmericanOdds, setUseAmericanOdds] = useState(true);

  // 1) On mount, read the "americanOdds" setting from localStorage
  useEffect(() => {
    const storedValue = localStorage.getItem('americanOdds');
    if (storedValue !== null) {
      setUseAmericanOdds(JSON.parse(storedValue));
    }
  }, []);

  // 2) Also listen for "oddsChange" events from NavigationMenu
  useEffect(() => {
    const handleOddsChange = () => {
      const storedValue = localStorage.getItem('americanOdds');
      if (storedValue !== null) {
        setUseAmericanOdds(JSON.parse(storedValue));
      }
    };

    // Listen for custom event
    window.addEventListener('oddsChange', handleOddsChange);

    return () => {
      window.removeEventListener('oddsChange', handleOddsChange);
    };
  }, []);

  // Sorting logic
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortClass = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? 'sort-ascending' : 'sort-descending';
    }
    return '';
  };

  // Convert from European to American
  const convertToAmericanOdds = (decimalOdds) => {
    if (decimalOdds >= 2.0) {
      return `+${Math.round((decimalOdds - 1) * 100)}`;
    } else {
      return `${Math.round(-100 / (decimalOdds - 1))}`;
    }
  };

  // Memoize sorted data
  const sortedPlayerProps = useMemo(() => {
    if (playerProps && playerProps.length > 0 && sortConfig.key) {
      return [...playerProps].sort((a, b) => {
        if (sortConfig.key === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return sortConfig.direction === 'ascending'
            ? dateA - dateB
            : dateB - dateA;
        }

        const valueA = a[sortConfig.key];
        const valueB = b[sortConfig.key];

        if (valueA < valueB) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return playerProps;
  }, [playerProps, sortConfig]);

  return (
    <div className="player-props-container">
      <table className="player-props-table">
        <thead>
          <tr>
            <th
              onClick={() => requestSort('sportsbook')}
              className={getSortClass('sportsbook')}
            >
              Sportsbook
            </th>
            <th
              onClick={() => requestSort('over_threshold')}
              className={getSortClass('over_threshold')}
            >
              Over Threshold
            </th>
            <th
              onClick={() => requestSort('over_odds')}
              className={getSortClass('over_odds')}
            >
              Over Odds
            </th>
            <th
              onClick={() => requestSort('under_threshold')}
              className={getSortClass('under_threshold')}
            >
              Under Threshold
            </th>
            <th
              onClick={() => requestSort('under_odds')}
              className={getSortClass('under_odds')}
            >
              Under Odds
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedPlayerProps && sortedPlayerProps.map((prop, idx) => {
            // Determine the displayed odds
            const overOdds = useAmericanOdds
              ? convertToAmericanOdds(prop.over_odds)
              : prop.over_odds.toFixed(2);

            const underOdds = useAmericanOdds
              ? convertToAmericanOdds(prop.under_odds)
              : prop.under_odds.toFixed(2);

            return (
              <tr key={idx}>
                <td>{prop.sportsbook}</td>
                <td>{prop.over_threshold}</td>
                <td>{overOdds}</td>
                <td>{prop.under_threshold}</td>
                <td>{underOdds}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerPropsTable;
