import { useEffect } from 'react';
import { useKeycloak } from './KeycloakProvider'; // Adjust the path to your KeycloakProvider file

const Login = () => {
  const { keycloak } = useKeycloak(); // Access the Keycloak context

  useEffect(() => {
    if (keycloak?.login) {
      keycloak.login(); // Automatically invoke the login method
    } else {
      console.error('Login function is not available.');
    }
  }, [keycloak]);

  return null; // No UI is rendered
};

export default Login;
