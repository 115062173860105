import { useState, useEffect, useRef } from 'react';
import { usePlayerColors } from '../PlayerColorContext';
import { teamNameToAbbreviation } from '../../components/TeamDictionary';
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak context
import { fetchData } from '../../api';  // Import your API utility

const useFetchPositionData = (selectedFeature) => {
  const [positionData, setPositionData] = useState({});
  const dataCache = useRef({});
  const { opponentName } = usePlayerColors();
  const { token } = useKeycloak();  // Get the token from Keycloak
 
  useEffect(() => {
    if (selectedFeature && teamNameToAbbreviation[opponentName] && token) {  // Ensure token is available
      const cacheKey = `position-data-${selectedFeature}-${teamNameToAbbreviation[opponentName]}`;
      
      if (!dataCache.current[cacheKey]) {
        

        // Encode the feature parameter
        const encodedFeature = encodeURIComponent(selectedFeature);
    
        // Construct the fetch URL
        const fetchURL = `position/?feature=${encodedFeature}&opponent=${teamNameToAbbreviation[opponentName]}`;

        // Fetch the data using the API utility
        fetchData(fetchURL, token)
          .then(data => {
            if (data.length === 0) {
              console.error('No position data received');
              return;
            }

            const newPositionData = {
              Positions: data.map(item => item.position),
              FeatureValues: [],
              Ranks: [],
            };

            data.forEach(item => {
              let value = 0;
              let rank = 0;

              // Normalize the feature name
              const featureUpper = selectedFeature.replace(' ', '+').toUpperCase();

              switch (featureUpper) {
                case 'TOTAL':
                  value = item['pts_position_vs_opponent_rolling_30_mean'];
                  rank = item['pts_last_30_rank'];
                  break;
                case 'AST':
                  value = item['ast_position_vs_opponent_rolling_30_mean'];
                  rank = item['ast_last_30_rank'];
                  break;
                case 'REB':
                  value = item['reb_position_vs_opponent_rolling_30_mean'];
                  rank = item['reb_last_30_rank'];
                  break;
                case 'BLK':
                  value = item['blk_position_vs_opponent_rolling_30_mean'];
                  rank = item['blk_last_30_rank'];
                  break;
                case 'STL':
                  value = item['stl_position_vs_opponent_rolling_30_mean'];
                  rank = item['stl_last_30_rank'];
                  break;
                case 'PTS+AST':
                  value = item['pts_position_vs_opponent_rolling_30_mean'] + item['ast_position_vs_opponent_rolling_30_mean'];
                  rank = item['pts_ast_last_30_rank'];
                  break;
                case 'PTS+REB':
                  value = item['pts_position_vs_opponent_rolling_30_mean'] + item['reb_position_vs_opponent_rolling_30_mean'];
                  rank = item['pts_reb_last_30_rank'];
                  break;
                case 'AST+REB':
                  value = item['reb_position_vs_opponent_rolling_30_mean'] + item['ast_position_vs_opponent_rolling_30_mean'];
                  rank = item['reb_ast_last_30_rank'];
                  break;
                case 'PTS+AST+REB':
                  value = item['pts_position_vs_opponent_rolling_30_mean'] + item['ast_position_vs_opponent_rolling_30_mean'] + item['reb_position_vs_opponent_rolling_30_mean'];
                  rank = item['pts_reb_ast_last_30_rank'];
                  break;
                default:
                  console.error('Unknown feature:', featureUpper);
                  value = 0;
                  rank = 0;
                  break;
              }

              newPositionData.FeatureValues.push(value);
              newPositionData.Ranks.push(rank);
            });

            dataCache.current[cacheKey] = newPositionData;
            setPositionData(newPositionData);
          })
          .catch(error => {
            console.error('Error fetching position data:', error);
          });
      } else {
        setPositionData(dataCache.current[cacheKey]);
      }
    }
  }, [selectedFeature, opponentName, token]);  // Add token as a dependency

  return positionData;
};

export default useFetchPositionData;
