import React, { useState, useEffect } from "react";
import "./LiveData.css";

/**
 * Single row of player data (Desktop => 9 columns).
 */
function LivePlayerRow({ player, allGames, removePlayer, updatePlayer }) {
  // 1) Locate the player's data in "allGames"
  let foundGame = null;
  let foundPlayer = null;
  for (const game of allGames) {
    const p = game.players.find((pl) => pl.name.trim() === player.name.trim());
    if (p) {
      foundGame = game;
      foundPlayer = p;
      break;
    }
  }

  // If not found => placeholders
  if (!foundPlayer) {
    return (
      <div className="live-player-row">
        <div className="live-player-info">N/A</div>
        <div className="live-player-minutes">N/A</div>
        <div className="live-player-gametime">N/A</div>
        <div className="live-player-status">N/A</div>
        <div className="live-player-feature">N/A</div>
        <div className="live-player-threshold">N/A</div>
        <div className="live-player-pick">N/A</div>
        <div className="live-player-bar-container">No Data</div>
        <div className="live-player-remove">
          <button onClick={() => removePlayer(player.id)} className="live-remove-btn">
            🗑
          </button>
        </div>
      </div>
    );
  }

  // 2) "live" value
  const liveValue = foundPlayer[player.feature] || 0;

  // 3) Threshold (Line)
  const thresholdNum = parseFloat(player.threshold) || 0;

  // Over/Under logic => bar color
  const isUnderFail = (player.pick === "under" && liveValue > thresholdNum);
  const isPickMet =
    (player.pick === "over" && liveValue >= thresholdNum) ||
    (player.pick === "under" && liveValue <= thresholdNum);

  let barFillColor = "#ccc";
  let barTextColor = "#000";
  if (isPickMet) {
    barFillColor = "var(--main-color)"; // purple
    barTextColor = "#fff";
  } else if (isUnderFail) {
    barFillColor = "red";
    barTextColor = "#fff";
  }

  // Additional info
  const minutes = foundPlayer.minutes || "0:00";
  const gameStatus = foundGame.gameInfo?.status || "N/A";
  const statusValue = foundPlayer.starter ? "in-game" : "bench";

  return (
    <div className="live-player-row">
      {/* Desktop columns => 1..9 */}
      {/* 1 => name */}
      <div className="live-player-info">{foundPlayer.name.trim()}</div>
      {/* 2 => minutes */}
      <div className="live-player-minutes">{minutes}</div>
      {/* 3 => clock */}
      <div className="live-player-gametime">{gameStatus}</div>
      {/* 4 => status */}
      <div className="live-player-status">{statusValue}</div>
      {/* 5 => feature (prop) */}
      <div className="live-player-feature">
        <select
          value={player.feature}
          onChange={(e) => updatePlayer(player.id, "feature", e.target.value)}
          className="live-styled-select live-feature-select"
        >
          <option value="pts">pts</option>
          <option value="ast">ast</option>
          <option value="reb">reb</option>
          <option value="pts+ast">pts+ast</option>
          <option value="pts+reb">pts+reb</option>
          <option value="ast+reb">ast+reb</option>
          <option value="pts+ast+reb">pts+ast+reb</option>
        </select>
      </div>
      {/* 6 => threshold (Line) */}
      <div className="live-player-threshold">
        <input
          type="text"
          value={player.threshold}
          onChange={(e) => updatePlayer(player.id, "threshold", e.target.value)}
          className="live-styled-input live-threshold-input"
        />
      </div>
      {/* 7 => pick */}
      <div className="live-player-pick">
        <select
          value={player.pick}
          onChange={(e) => updatePlayer(player.id, "pick", e.target.value)}
          className="live-styled-select live-pick-select"
        >
          <option value="over">Over</option>
          <option value="under">Under</option>
        </select>
      </div>
      {/* 8 => bar */}
      <div className="live-player-bar-container">
        <div
          className="live-player-bar-fill"
          style={{
            width: thresholdNum
              ? `${Math.min((liveValue / thresholdNum) * 100, 100)}%`
              : "0%",
            backgroundColor: barFillColor,
            color: barTextColor,
          }}
        >
          <span className="live-player-bar-value">{liveValue}</span>
        </div>
      </div>
      {/* 9 => remove */}
      <div className="live-player-remove">
        <button onClick={() => removePlayer(player.id)} className="live-remove-btn">
          🗑
        </button>
      </div>
    </div>
  );
}

function LiveData() {
  const [allGames, setAllGames] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const [team, setTeam] = useState("");
  const [matchup, setMatchup] = useState("");
  const [teamsMap, setTeamsMap] = useState({});
  const [availableTeams, setAvailableTeams] = useState([]);
  const [availableMatchups, setAvailableMatchups] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);

  // Load from localStorage
  useEffect(() => {
    const saved = localStorage.getItem("selectedPlayers");
    if (saved) {
      try {
        setSelectedPlayers(JSON.parse(saved));
      } catch (err) {
        console.error("Error parsing localStorage:", err);
      }
    }
  }, []);

  // Save to localStorage
  useEffect(() => {
    localStorage.setItem("selectedPlayers", JSON.stringify(selectedPlayers));
  }, [selectedPlayers]);

  // Fetch all data once
  const fetchAllData = async () => {
    try {
      const res = await fetch("/api/live-data/");
      const data = await res.json();
      const updated = data.games.map((g) => ({
        ...g,
        players: g.players.filter((p) => p.pts || p.ast || p.reb),
      }));
      setAllGames(updated);

      // Build sets and map
      const teamMap = {};
      const matchSet = new Set();

      updated.forEach((game) => {
        matchSet.add(game.matchup);
        if (!teamMap[game.home_team]) teamMap[game.home_team] = new Set();
        if (!teamMap[game.away_team]) teamMap[game.away_team] = new Set();

        game.players.forEach((p) => {
          teamMap[p.team].add(p.name.trim());
        });
      });

      const finalTeamsMap = {};
      for (const [tname, setOfNames] of Object.entries(teamMap)) {
        finalTeamsMap[tname] = [...setOfNames];
      }
      setTeamsMap(finalTeamsMap);
      setAvailableTeams(Object.keys(finalTeamsMap));
      setAvailableMatchups([...matchSet]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  // Recompute availablePlayers whenever team/matchup changes
  useEffect(() => {
    if (team) {
      // Only that team
      const arr = teamsMap[team] || [];
      setAvailablePlayers(arr);
    } else if (matchup) {
      // Both teams from that matchup
      let homeT = "";
      let awayT = "";
      for (const g of allGames) {
        if (g.matchup === matchup) {
          homeT = g.home_team;
          awayT = g.away_team;
          break;
        }
      }
      const setHome = homeT ? new Set(teamsMap[homeT] || []) : new Set();
      const setAway = awayT ? new Set(teamsMap[awayT] || []) : new Set();
      const union = new Set([...setHome, ...setAway]);
      setAvailablePlayers([...union]);
    } else {
      // All
      const allNames = new Set();
      for (const arr of Object.values(teamsMap)) {
        arr.forEach((name) => allNames.add(name));
      }
      setAvailablePlayers([...allNames]);
    }
  }, [team, matchup, teamsMap, allGames]);

  // Add a new row
  const addPlayer = (playerName) => {
    if (!playerName) return;
    const id = `${playerName}-${Date.now()}`;
    setSelectedPlayers((prev) => [
      ...prev,
      { id, name: playerName.trim(), feature: "pts", threshold: "10", pick: "over" },
    ]);
  };

  // Update row
  const updatePlayer = (playerId, key, value) => {
    setSelectedPlayers((prev) =>
      prev.map((p) => (p.id === playerId ? { ...p, [key]: value } : p))
    );
  };

  // Remove row
  const removePlayer = (playerId) => {
    setSelectedPlayers((prev) => prev.filter((p) => p.id !== playerId));
  };

  return (
    <div className="live-data-container">
      <h1>Live NBA Data</h1>

      {/* Filters */}
      <div className="live-filters">
        <select
          value={team}
          onChange={(e) => {
            setTeam(e.target.value);
            if (e.target.value) setMatchup("");
          }}
          className="live-styled-select"
        >
          <option value="">All Teams</option>
          {availableTeams.map((t) => (
            <option key={t} value={t}>{t}</option>
          ))}
        </select>

        <select
          value={matchup}
          onChange={(e) => {
            setMatchup(e.target.value);
            if (e.target.value) setTeam("");
          }}
          className="live-styled-select"
        >
          <option value="">All Matchups</option>
          {availableMatchups.map((m) => (
            <option key={m} value={m}>{m}</option>
          ))}
        </select>

        <select
          onChange={(e) => {
            addPlayer(e.target.value);
            e.target.value = "";
          }}
          value=""
          className="live-styled-select"
        >
          <option value="" disabled>Select Player</option>
          {availablePlayers.map((p) => (
            <option key={p} value={p}>{p}</option>
          ))}
        </select>
      </div>

      {/* Desktop header => 9 columns */}
      <div className="live-player-header">
        <div className="live-player-info">Player</div>
        <div className="live-player-minutes">Min</div>
        <div className="live-player-gametime">Clock</div>
        <div className="live-player-status">Status</div>
        <div className="live-player-feature">Prop</div>
        <div className="live-player-threshold">Line</div>
        <div className="live-player-pick">Pick</div>
        <div className="live-player-bar-header">Performance</div>
        <div className="live-player-remove"></div>
      </div>

      <div className="live-player-view">
        {selectedPlayers.map((pl) => (
          <LivePlayerRow
            key={pl.id}
            player={pl}
            allGames={allGames}
            removePlayer={removePlayer}
            updatePlayer={updatePlayer}
          />
        ))}
      </div>
    </div>
  );
}

export default LiveData;
