import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { usePlayerColors } from '../main_dashboard_components/PlayerColorContext';
import { fetchDataTeams, fetchTeamPlayers } from './data/TeamVersusTeamChartData';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import './TeamVersusTeamChart.css';
import teamAbbreviationToFullName from '../components/TeamFullNameDictionary.js';
import { useKeycloak } from '../KeycloakProvider';

const TeamVersusTeamChart = ({ selectedFeature }) => {
  const { teamColors, selectedTeam, opponentName } = usePlayerColors();
  const chartRef = useRef(null);
  const [cachedTeamsData, setCachedTeamsData] = useState({});
  const [inDrillDown, setInDrillDown] = useState(false);
  const [currentGameDate, setCurrentGameDate] = useState(null);
  const [showTotal, setShowTotal] = useState(true);
  const [currentTeamAbbreviation, setCurrentTeamAbbreviation] = useState(null);
  const { token } = useKeycloak();
  const opponentAbbreviation = teamNameToAbbreviation[opponentName];

  useEffect(() => {
    if (!token) {
      console.warn(
        'Token is null in TeamVersusTeamChart. Ensure that the Keycloak provider is set up correctly.'
      );
    }
  }, [token]);

  // --------------------------------
  // Helper functions
  // --------------------------------
  const formatDate = (date) => {
    const d = new Date(date);
    const year = String(d.getFullYear()).slice(-2);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getPlayerInitials = (fullName) =>
    fullName
      ? fullName
          .split(' ')
          .map((part) => part[0])
          .join('')
      : '';

  const destroyChart = () => {
    if (chartRef.current) {
      const existingChart = echarts.getInstanceByDom(chartRef.current);
      if (existingChart) {
        existingChart.dispose();
      }
    }
  };

  // --------------------------------
  // Resize listener => chart.resize()
  // --------------------------------
  useEffect(() => {
    const handleResize = () => {
      if (!chartRef.current) return;
      const instance = echarts.getInstanceByDom(chartRef.current);
      if (instance) {
        instance.resize();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // --------------------------------
  // Go back to Teams (undo drilldown)
  // --------------------------------
  const goBackToTeams = useCallback(async () => {
    if (!token) {
      console.warn('Token is null when trying to go back to teams.');
      return;
    }

    setInDrillDown(false);
    setCurrentGameDate(null);

    let updatedTeamsData;
    if (cachedTeamsData[currentTeamAbbreviation]) {
      updatedTeamsData = cachedTeamsData[currentTeamAbbreviation];
    } else {
      updatedTeamsData = await fetchDataTeams(
        currentTeamAbbreviation,
        opponentAbbreviation,
        token
      );
      updatedTeamsData = updatedTeamsData.sort(
        (a, b) => new Date(a.game_date) - new Date(b.game_date)
      );
      setCachedTeamsData((prevCache) => ({
        ...prevCache,
        [currentTeamAbbreviation]: updatedTeamsData,
      }));
    }

    const featureMap = {
      Total: 'team_points',
      AST: 'team_ast',
      REB: 'team_reb',
      BLK: 'team_blk',
      STL: 'team_stl',
      'Pts+Ast': ['team_points', 'team_ast'],
      'Pts+Reb': ['team_points', 'team_reb'],
      'Ast+Reb': ['team_ast', 'team_reb'],
      'Pts+Ast+Reb': ['team_points', 'team_ast', 'team_reb'],
    };
    const feature = featureMap[selectedFeature] || 'team_points';

    const updatedTeamOption = {
      xAxis: {
        type: 'category',
        data: updatedTeamsData.map(
          (item) => `${formatDate(item.game_date)}\n${item.opponent}`
        ),
        axisLabel: {
          rotate: 0,
          align: 'center',
          fontSize: 11.5,
        },
      },
      yAxis: {
        type: 'value',
        minInterval: 1,
      },
      // Increase left/right/bottom for more padding
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '20%',
        containLabel: true,
      },
      legend: {
        data: Array.isArray(feature)
          ? feature.map((f) => f.toUpperCase())
          : [selectedFeature.toUpperCase()],
        top: '12%',
        left: 'center',
        textStyle: {
          color: '#000',
        },
        z: 10,
      },
      series: createSeries(updatedTeamsData, feature),
    };

    const myChart = echarts.getInstanceByDom(chartRef.current);
    if (
      updatedTeamOption &&
      updatedTeamOption.series &&
      updatedTeamOption.series.length > 0
    ) {
      myChart.setOption(updatedTeamOption);
    }
    enableChartClick(myChart);
  }, [
    cachedTeamsData,
    selectedFeature,
    teamColors,
    currentTeamAbbreviation,
    opponentAbbreviation,
    token,
  ]);

  // --------------------------------
  // Create series
  // --------------------------------
  const createSeries = (data, feature, isPlayerData = false) => {
    const series = [];
    const filteredData = data.filter((item) => {
      if (Array.isArray(feature)) {
        return feature.some((f) => item[f] >= 2);
      }
      return item[feature] >= 2;
    });

    // We'll define a helper for the "bar" object
    const makeBarSeries = (barData, color, name = '', stack = null) => {
      return {
        name,
        type: 'bar',
        stack,
        label: {
          show: true,
          position: stack ? 'insideTop' : 'top',
          formatter: '{c}',
        },
        data: barData,
        // This helps spacing between bars
        barCategoryGap: '30%',
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        itemStyle: {
          color,
        },
      };
    };

    if (showTotal && !inDrillDown) {
      // Summation bar
      if (Array.isArray(feature)) {
        const barData = filteredData.map((item) => ({
          value: feature.reduce((sum, f) => sum + item[f], 0),
          name: item.game_date || item.player_name,
          opponent: item.opponent,
        }));
        series.push(makeBarSeries(barData, teamColors[0]));
      } else {
        const barData = filteredData.map((item) => ({
          value: isPlayerData ? item[feature] : item[feature],
          name: item.game_date || item.player_name,
          opponent: item.opponent,
        }));
        series.push(makeBarSeries(barData, teamColors[0]));
      }
    } else if (Array.isArray(feature)) {
      // Stacked bars
      feature.forEach((feat, index) => {
        const barData = filteredData.map((item) => item[feat]);
        series.push(
          makeBarSeries(
            barData,
            teamColors[index % teamColors.length],
            feat.toUpperCase().replace('TEAM_', ''),
            'total'
          )
        );
      });
    } else {
      // Single bar, not total
      const barData = filteredData.map((item) => ({
        value: isPlayerData ? item[feature] : item[feature],
        name: item.game_date || item.player_name,
        opponent: item.opponent,
      }));
      series.push(makeBarSeries(barData, teamColors[0]));
    }
    return series;
  };

  // --------------------------------
  // Initialize or re-init the chart
  // --------------------------------
  const initializeChart = useCallback(
    async (teamAbbreviation, data = null, isPlayerData = false, gameDate = null) => {
      if (!token) {
        console.warn('Token is null during chart initialization.');
        return;
      }
      destroyChart();
      const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

      let teamsData = data || cachedTeamsData[teamAbbreviation];
      if (!teamsData) {
        teamsData = await fetchDataTeams(teamAbbreviation, opponentAbbreviation, token);
        teamsData = teamsData.sort((a, b) => new Date(a.game_date) - new Date(b.game_date));
        setCachedTeamsData((prev) => ({
          ...prev,
          [teamAbbreviation]: teamsData,
        }));
      }

      const featureMap = {
        Total: 'team_points',
        AST: 'team_ast',
        REB: 'team_reb',
        BLK: 'team_blk',
        STL: 'team_stl',
        'Pts+Ast': ['team_points', 'team_ast'],
        'Pts+Reb': ['team_points', 'team_reb'],
        'Ast+Reb': ['team_ast', 'team_reb'],
        'Pts+Ast+Reb': ['team_points', 'team_ast', 'team_reb'],
      };
      const feature = featureMap[selectedFeature] || 'team_points';

      const xAxisData = isPlayerData
        ? teamsData.filter((item) => item.game_date === gameDate).map((item) => item.player_name)
        : teamsData.map(
            (item) => `${formatDate(item.game_date)}\n${item.opponent}`
          );

      const teamOption = {
        id: 'teams',
        title: {
          text: `${selectedFeature} vs ${
            teamAbbreviationToFullName[opponentAbbreviation] || 'Unknown'
          }`,
          left: '2%',
          top: '5%',
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const idx = params[0].dataIndex;
            const item = teamsData[idx];
            let content = `${item.game_date}<br>${item.opponent}<br>`;
            params.forEach((p) => {
              content += `${p.seriesName || selectedFeature}: ${p.value}<br>`;
            });
            return content;
          },
        },
        legend: {
          data: Array.isArray(feature)
            ? feature.map((f) => f.toUpperCase().replace('TEAM_', ''))
            : [selectedFeature.toUpperCase()],
          top: '12%',
          left: 'center',
          textStyle: { color: '#000' },
          z: 10,
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            rotate: 0,
            align: 'center',
            fontSize: 11.5,
            formatter: isPlayerData ? (value) => getPlayerInitials(value) : undefined,
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%',
          top: '20%',
          containLabel: true,
        },
        series: createSeries(teamsData, feature, isPlayerData),
      };

      if (teamOption && teamOption.series && teamOption.series.length > 0) {
        myChart.setOption(teamOption);
      }
      enableChartClick(myChart);
      setCurrentTeamAbbreviation(teamAbbreviation);
    },
    [
      cachedTeamsData,
      selectedFeature,
      teamColors,
      currentTeamAbbreviation,
      showTotal,
      token,
      opponentAbbreviation,
    ]
  );

  // --------------------------------
  // Chart click => drilldown to players
  // --------------------------------
  const handleChartClick = useCallback(
    async (params) => {
      if (!token) {
        console.warn('Token is null when handling chart click.');
        return;
      }
      const dataItem = params.data;
      if (!dataItem || !dataItem.name) {
        console.error('Data item is missing or undefined:', dataItem);
        return;
      }

      setInDrillDown(true);
      setCurrentGameDate(dataItem.name);

      const teamPlayers = await fetchTeamPlayers(
        currentTeamAbbreviation,
        dataItem.name,
        opponentAbbreviation,
        token
      );

      const featureMap = {
        Total: 'pts',
        AST: 'ast',
        REB: 'reb',
        BLK: 'blk',
        STL: 'stl',
        'Pts+Ast': ['pts', 'ast'],
        'Pts+Reb': ['pts', 'reb'],
        'Ast+Reb': ['ast', 'reb'],
        'Pts+Ast+Reb': ['pts', 'ast', 'reb'],
        Points: ['fg2m', 'fg3m', 'ftm'],
      };
      const feature = featureMap[selectedFeature];

      const createPlayerSeries = () => {
        const s = [];
        const filteredPlayers = teamPlayers.filter((it) => {
          if (Array.isArray(feature)) {
            return feature.some((f) => it[f] >= 2);
          }
          return it[feature] >= 2;
        });

        if (Array.isArray(feature)) {
          feature.forEach((feat, index) => {
            s.push({
              name: feat.toUpperCase(),
              type: 'bar',
              stack: 'total',
              data: filteredPlayers.map((it2) => it2[feat]),
              itemStyle: { color: teamColors[index % teamColors.length] },
              label: {
                show: true,
                position: 'insideTop',
                formatter: (pp) => (pp.value > 2 ? pp.value : ''),
              },
              barCategoryGap: '30%',
            });
          });
        } else {
          s.push({
            name: selectedFeature,
            type: 'bar',
            data: filteredPlayers.map((it2) => it2[feature]),
            itemStyle: { color: teamColors[0] },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}',
            },
            barCategoryGap: '30%',
          });
        }
        return s;
      };

      destroyChart();
      const myChart = echarts.init(chartRef.current, null, { devicePixelRatio: 2 });

      const playerOption = {
        id: `team_${currentTeamAbbreviation}_${dataItem.name}`,
        title: {
          text: `${dataItem.name} vs ${
            teamAbbreviationToFullName[opponentAbbreviation] || 'Unknown'
          }`,
          left: '1%',
          top: '5%',
        },
        tooltip: {
          trigger: 'axis',
          formatter: (p) => {
            const idx = p[0].dataIndex;
            const playerName = teamPlayers[idx]?.player_name;
            let content = `${playerName}<br>${dataItem.name}<br>`;
            p.forEach((pp) => {
              content += `${pp.seriesName}: ${pp.value}<br>`;
            });
            return content;
          },
        },
        legend: {
          data: Array.isArray(feature)
            ? feature.map((f) => f.toUpperCase())
            : [selectedFeature.toUpperCase()],
          top: '12%',
          left: '5%',
          textStyle: { color: '#000' },
          z: 20,
        },
        xAxis: {
          type: 'category',
          data: teamPlayers.map((it3) => it3.player_name),
          axisLabel: {
            fontSize: 14,
            rotate: 0,
            interval: 0,
            formatter: (val) => getPlayerInitials(val),
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%',
          top: '25%',
          containLabel: true,
        },
        series: createPlayerSeries(),
      };

      if (playerOption && playerOption.series && playerOption.series.length > 0) {
        myChart.setOption(playerOption);
      } else {
        console.error('Chart option is invalid:', playerOption);
      }
    },
    [currentTeamAbbreviation, selectedFeature, teamColors, opponentAbbreviation, token]
  );

  const enableChartClick = (myChart) => {
    if (myChart) {
      myChart.on('click', 'series', handleChartClick);
    }
  };

  // --------------------------------
  // On selectedTeam or feature change
  // --------------------------------
  useEffect(() => {
    if (selectedTeam) {
      setInDrillDown(false);
      setCurrentGameDate(null);
      const selectedTeamAbbreviation = teamNameToAbbreviation[selectedTeam];
      initializeChart(selectedTeamAbbreviation, null, false, null);
    }
  }, [selectedTeam, selectedFeature, teamColors, token]);

  // Re-init when showTotal changes
  useEffect(() => {
    if (chartRef.current && currentTeamAbbreviation) {
      const cachedData = cachedTeamsData[currentTeamAbbreviation];
      if (cachedData && Object.keys(cachedData).length >= 1) {
        destroyChart();
        if (inDrillDown) {
          initializeChart(currentTeamAbbreviation, cachedData, true, currentGameDate);
        } else {
          initializeChart(currentTeamAbbreviation, cachedData);
        }
      }
    }
  }, [
    showTotal,
    initializeChart,
    currentTeamAbbreviation,
    inDrillDown,
    cachedTeamsData,
    currentGameDate,
    token,
  ]);

  // --------------------------------
  // Render
  // --------------------------------
  return (
    <div className="team-versus-chart-wrapper">
      {/* Toggle switch (only if not in drilldown) */}
      {!inDrillDown && (
        <div className="team-vs-toggle-container">
          <span className="toggle-label">
            {showTotal ? 'Total' : 'Stacked'}
          </span>
          <label className="switch" style={{ '--primary-color': teamColors[0] }}>
            <input
              type="checkbox"
              onChange={() => setShowTotal((prev) => !prev)}
              checked={showTotal}
            />
            <span className="slider round"></span>
          </label>
        </div>
      )}

      {/* “Back” as simple text in top-right corner */}
      {inDrillDown && (
        <span className="team-vs-back-link" onClick={goBackToTeams}>
          Back
        </span>
      )}

      {/* The chart container */}
      <div ref={chartRef} className="team-versus-team-chart" />
    </div>
  );
};

export default TeamVersusTeamChart;
