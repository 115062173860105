import React, { useEffect, useState } from "react";
import "./Stream.css";
import QuarterDetails from "./components/QuarterDetails";
import ScoreCard from "./components/ScoreCard";
import { abbreviationToTeamName } from "../components/TeamDictionary";
import { teamsDict } from "../components/TeamInfo";

const Stream = ({ awayTeam, homeTeam }) => {
  const [liveData, setLiveData] = useState(null);
  const [quarterDetails, setQuarterDetails] = useState({});
  const [scores, setScores] = useState({
    pts: { team1: [0, 0, 0, 0], team2: [0, 0, 0, 0] },
    ast: { team1: [0, 0, 0, 0], team2: [0, 0, 0, 0] },
    reb: { team1: [0, 0, 0, 0], team2: [0, 0, 0, 0] },
  });

  const awayTeamName = abbreviationToTeamName?.[awayTeam] || "Unknown Away Team";
  const homeTeamName = abbreviationToTeamName?.[homeTeam] || "Unknown Home Team";
  const matchupKey = `${homeTeamName} vs ${awayTeamName}`; // must match backend format

  const homeColor = teamsDict?.[homeTeamName]?.colors?.[0] || "#000000";
  const awayColor = teamsDict?.[awayTeamName]?.colors?.[0] || "#FFFFFF";
  const homeLogo = teamsDict?.[homeTeamName]?.logo || "";
  const awayLogo = teamsDict?.[awayTeamName]?.logo || "";

  // Force no caching in the fetch request:
  const fetchLiveData = async () => {
    console.log("[DEBUG] fetchLiveData called...");
    try {
      const response = await fetch("/api/get_stat_differences/", {
        cache: "no-store",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0"
        }
      });
      if (!response.ok) {
        throw new Error(`Fetch error: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("[DEBUG] Fetched liveData:", data);
      setLiveData(data);
    } catch (err) {
      console.error("[ERROR] Error fetching live data:", err);
    }
  };

  useEffect(() => {
    fetchLiveData();
    const interval = setInterval(fetchLiveData, 10000); // poll every 10s
    return () => clearInterval(interval);
  }, []);

  // Build scoreboard totals by summing up the "pts", "ast", "reb" in quarterDetails
  const calculateScores = (details) => {
    console.log("[DEBUG] calculateScores - details:", details);
    const metrics = ["pts", "ast", "reb"];
    const updatedScores = metrics.reduce((acc, metric) => {
      acc[metric] = { team1: [0, 0, 0, 0], team2: [0, 0, 0, 0] };
      if (!details?.[matchupKey]) return acc;

      Object.keys(details[matchupKey]).forEach((quarterKey) => {
        let quarterIndex = null;
        if (quarterKey.startsWith("Q")) {
          quarterIndex = parseInt(quarterKey.replace("Q", ""), 10) - 1;
        }
        // only Q1..Q4
        if (quarterIndex === null || quarterIndex < 0 || quarterIndex >= 4) {
          return;
        }
        details[matchupKey][quarterKey].forEach((action) => {
          const value = action[metric] || 0;
          if (action.isOpponent) {
            acc[metric].team2[quarterIndex] += value;
          } else {
            acc[metric].team1[quarterIndex] += value;
          }
        });
      });
      return acc;
    }, {});
    console.log("[DEBUG] updatedScores:", updatedScores);
    return updatedScores;
  };

  const updateQuarterDetails = () => {
    console.log("[DEBUG] updateQuarterDetails called. liveData:", liveData);
    if (!liveData) {
      console.log("[DEBUG] No liveData yet, returning...");
      return;
    }
    const currentGame = liveData.games.find((g) => g.matchup === matchupKey);
    if (!currentGame) {
      console.log(`[DEBUG] No game found for matchupKey: ${matchupKey}`);
      return;
    }

    console.log("[DEBUG] currentGame found:", currentGame);

    // Even if no new increments, currentGame.players might have timeline
    const newDetails = { ...quarterDetails };
    if (!newDetails[matchupKey]) newDetails[matchupKey] = {};

    currentGame.players.forEach((player) => {
      const { name, timeline, team } = player;
      console.log(`[DEBUG] Processing player: ${name}, timeline:`, timeline);
      if (!timeline || timeline.length === 0) {
        console.log(`[DEBUG] Player ${name} has no timeline, skipping...`);
        return;
      }
      timeline.forEach((event) => {
        const { quarter, time, pts_total, ast_total, reb_total } = event;
        if (!quarter) return;
        if (!Array.isArray(newDetails[matchupKey][quarter])) {
          newDetails[matchupKey][quarter] = [];
        }
        newDetails[matchupKey][quarter].push({
          player: name,
          time: time || "N/A",
          pts: pts_total,
          ast: ast_total,
          reb: reb_total,
          isOpponent: (team === awayTeamName),
        });
      });
    });

    console.log("[DEBUG] newDetails after processing timeline:", newDetails);

    setQuarterDetails(newDetails);
    const updatedScores = calculateScores(newDetails);
    setScores(updatedScores);

    // optionally store in localStorage so it's persistent
    localStorage.setItem("quarterDetailsCache", JSON.stringify(newDetails));
  };

  useEffect(() => {
    updateQuarterDetails();
  }, [liveData]);

  return (
    <div className="stream-container">
      <div className="scorecards-row">
        <div className="score-card-container">
          <ScoreCard metric="pts" scores={scores.pts} homeLogo={homeLogo} awayLogo={awayLogo} />
        </div>
        <div className="score-card-container">
          <ScoreCard metric="ast" scores={scores.ast} homeLogo={homeLogo} awayLogo={awayLogo} />
        </div>
        <div className="score-card-container">
          <ScoreCard metric="reb" scores={scores.reb} homeLogo={homeLogo} awayLogo={awayLogo} />
        </div>
      </div>

      <div className="quarterdetails-row">
        <div className="quarterdetails-container">
          <QuarterDetails
            quarters={quarterDetails?.[matchupKey] || {}}
            title="Points Breakdown"
            homeColor={homeColor}
            awayColor={awayColor}
            metric="pts"
          />
        </div>
        <div className="quarterdetails-container">
          <QuarterDetails
            quarters={quarterDetails?.[matchupKey] || {}}
            title="Assists Breakdown"
            homeColor={homeColor}
            awayColor={awayColor}
            metric="ast"
          />
        </div>
        <div className="quarterdetails-container">
          <QuarterDetails
            quarters={quarterDetails?.[matchupKey] || {}}
            title="Rebounds Breakdown"
            homeColor={homeColor}
            awayColor={awayColor}
            metric="reb"
          />
        </div>
      </div>
    </div>
  );
};

export default Stream;
