import React from 'react';
import { Lock } from 'lucide-react';
import './ProjectedStatsTable.css';
import { abbreviationToTeamName } from '../components/TeamDictionary';
import { teamsDict } from '../components/TeamInfo';
const projectedStats = {
  PTS: { DEN: '', MIN: '' },
  OREB: { DEN: '', MIN: '' },
  DREB: { DEN: '', MIN: '' },
  AST: { DEN: '', MIN: '' },
  TOV: { DEN: '', MIN: '' },
  STL: { DEN: '', MIN: '' },
  '3pt': { DEN: '', MIN: '' },
};


const ProjectedStatsTable = ({ homeTeamAbbr, awayTeamAbbr }) => (
  <div className="projected-stats-table">
    <table>
      <thead>
        <tr>
          <th colSpan="3" className="table-title">Line-up Projected Stats</th>
        </tr>
        <tr>
          <th>Stat</th>
          <th>
            <img src={`/team_logos/${abbreviationToTeamName[homeTeamAbbr].toLowerCase()}.png`} alt={`${homeTeamAbbr} Logo`} className="projected-stats-team-logo" />
            <span className="team-name">{homeTeamAbbr}</span>
          </th>
          <th>
            <img src={`/team_logos/${abbreviationToTeamName[awayTeamAbbr].toLowerCase()}.png`} alt={`${awayTeamAbbr} Logo`} className="projected-stats-team-logo" />
            <span className="team-name">{awayTeamAbbr}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(projectedStats).map(([stat, values]) => (
          <tr key={stat}>
            <td>{stat}</td>
            <td><Lock size={14} color={teamsDict[abbreviationToTeamName[homeTeamAbbr]].colors[0]} /></td>
            <td><Lock size={14} color={teamsDict[abbreviationToTeamName[awayTeamAbbr]].colors[0]} /></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ProjectedStatsTable;