// teamMappings.js

// Mapping from Team Name to Abbreviation
const teamNameToAbbreviation = {
  'Bucks': 'MIL',
  'Nuggets': 'DEN',
  'Lakers': 'LAL',
  'Warriors': 'GSW',
  'Nets': 'BKN',
  'Suns': 'PHX',
  'Clippers': 'LAC',
  'Heat': 'MIA',
  'Bulls': 'CHI',
  'Hawks': 'ATL',
  'Timberwolves': 'MIN',
  'Celtics': 'BOS',
  '76ers': 'PHI',
  'Mavericks': 'DAL',
  'Raptors': 'TOR',
  'Jazz': 'UTA',
  'Blazers': 'POR',
  'Grizzlies': 'MEM',
  'Pelicans': 'NOP',
  'Kings': 'SAC',
  'Hornets': 'CHA',
  'Pacers': 'IND',
  'Spurs': 'SAS',
  'Thunder': 'OKC',
  'Rockets': 'HOU',
  'Pistons': 'DET',
  'Wizards': 'WAS',
  'Magic': 'ORL',
  'Knicks': 'NYK',
  'Cavaliers': 'CLE'
};

// Dynamically create Abbreviation to Team Name mapping
const abbreviationToTeamName = Object.entries(teamNameToAbbreviation).reduce((acc, [teamName, abbreviation]) => {
  acc[abbreviation] = teamName;
  return acc;
}, {});

// Export both mappings
export { teamNameToAbbreviation, abbreviationToTeamName };
