import React, { useState, useEffect } from 'react';
import { fetchData } from '../api'; // Import the API function
import { abbreviationToTeamName } from '../components/TeamDictionary';
import './DefenceVsPositionTable.css';
import { useKeycloak } from '../KeycloakProvider'; // Import the Keycloak context

const useFetchDefenseData = (teamAbbr) => {
  const [defenseData, setDefenseData] = useState({});
  const { token } = useKeycloak(); // Get the token from Keycloak

  useEffect(() => {
    const fetchDefenseStats = async () => {
      const encodedFeature = encodeURIComponent('TOTAL');
      const endpoint = `position/?feature=${encodedFeature}&opponent=${teamAbbr}`;

      try {
        const data = await fetchData(endpoint, token);

        let newDefenseData = {};
        data.forEach((item) => {
          newDefenseData[item.position] = {
            value: item.pts_position_vs_opponent_rolling_30_mean.toFixed(1),
            rank: item.pts_last_30_rank,
          };
        });

        setDefenseData(newDefenseData);
      } catch (error) {
        console.error('Error fetching defense data:', error);
      }
    };

    if (teamAbbr) {
      fetchDefenseStats();
    }
  }, [teamAbbr, token]);

  return defenseData;
};

const getRankColor = (rank) => {
  if (rank >= 1 && rank <= 10) return 'red';
  if (rank >= 11 && rank <= 20) return 'black';
  if (rank >= 21 && rank <= 30) return 'green';
  return 'black';
};

const getOrdinalSuffix = (rank) => {
  const j = rank % 10,
        k = rank % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

const DefenceVsPositionTable = ({ homeTeamAbbr, awayTeamAbbr }) => {
  const homeTeamData = useFetchDefenseData(homeTeamAbbr);
  const awayTeamData = useFetchDefenseData(awayTeamAbbr);

  const positions = ['PG', 'SG', 'SF', 'PF', 'C'];

  return (
    <div className="defence-vs-position-table">
      <table>
        <thead>
          <tr>
            <th colSpan="3" className="table-title">Defence vs Position</th>
          </tr>
          <tr>
            <th>Position</th>
            <th>
              <img src={`/team_logos/${abbreviationToTeamName[homeTeamAbbr].toLowerCase()}.png`} alt={`${homeTeamAbbr} Logo`} className="defence-vs-position-team-logo" />
              <span className="team-name">{homeTeamAbbr}</span>
            </th>
            <th>
              <img src={`/team_logos/${abbreviationToTeamName[awayTeamAbbr].toLowerCase()}.png`} alt={`${awayTeamAbbr} Logo`} className="defence-vs-position-team-logo" />
              <span className="team-name">{awayTeamAbbr}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {positions.map((position) => (
            <tr key={position}>
              <td>{position}</td>
              <td>
                {homeTeamData[position] ? (
                  <>
                    {homeTeamData[position].value}{' '}
                    <span style={{ 
                      color: getRankColor(homeTeamData[position].rank),
                      fontSize: '0.7em',
                      verticalAlign: 'baseline'
                    }}>
                      ({homeTeamData[position].rank}{getOrdinalSuffix(homeTeamData[position].rank)})
                    </span>
                  </>
                ) : '-'}
              </td>
              <td>
                {awayTeamData[position] ? (
                  <>
                    {awayTeamData[position].value}{' '}
                    <span style={{ 
                      color: getRankColor(awayTeamData[position].rank),
                      fontSize: '0.7em',
                      verticalAlign: 'baseline'
                    }}>
                      ({awayTeamData[position].rank}{getOrdinalSuffix(awayTeamData[position].rank)})
                    </span>
                  </>
                ) : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DefenceVsPositionTable;
