import { useState, useEffect, useRef } from 'react';
import { useKeycloak } from '../../KeycloakProvider';  // Import Keycloak context
import { fetchData } from '../../api';  // Import the API utility for fetching data

const useFetchShootingChartData = (selectedPlayer, selectedFeature, x = 15) => {
  const [chartData, setChartData] = useState({});
  const dataCache = useRef({});
  const { token } = useKeycloak();  // Get the token from Keycloak

  useEffect(() => {
    if (selectedPlayer && selectedFeature && token) {  // Ensure token is available
      const cacheKey = `${selectedPlayer}-${selectedFeature}`;
      if (!dataCache.current[cacheKey]) {
       

        const commonMetrics = ['game_date', 'opponent'];
        let featureMetrics = [];

        switch (selectedFeature) {
          case 'Total':
            featureMetrics = [
              'fg2m_mean_rolling_7_games', 'fg3m_mean_rolling_7_games',
              'fg2a_mean_rolling_7_games', 'fg3a_mean_rolling_7_games'
            ];
            break;
          case 'Pts+Ast':
            featureMetrics = ['pts_mean_rolling_7_games', 'ast_mean_rolling_7_games'];
            break;
          case 'Pts+Reb':
            featureMetrics = ['pts_mean_rolling_7_games', 'reb_mean_rolling_7_games'];
            break;
          case 'Pts+Ast+Reb':
            featureMetrics = ['pts_mean_rolling_7_games', 'ast_mean_rolling_7_games', 'reb_mean_rolling_7_games'];
            break;
          case 'Ast+Reb':
            featureMetrics = ['ast_mean_rolling_7_games', 'reb_mean_rolling_7_games'];
            break;
          case 'AST':
            featureMetrics = ['ast_mean_rolling_7_games', 'tov_mean_rolling_7_games', 'passes_mean_rolling_7_games', 'touches_mean_rolling_7_games'];
            break;
          case 'REB':
            featureMetrics = ['oreb_mean_rolling_7_games', 'dreb_mean_rolling_7_games',
                              'reboundchancesoffensive_mean_rolling_7_games', 'reboundchancesdefensive_mean_rolling_7_games'];
            break;
          case 'BLK':
            featureMetrics = ['blk_mean_rolling_7_games', 'blka_mean_rolling_7_games'];
            break;
          case 'STL':
            featureMetrics = ['stl_mean_rolling_7_games'];
            break;
          default:
            console.error('Unknown feature:', selectedFeature);
            return;
        }

        const metrics = [...commonMetrics, ...featureMetrics];
        const queryString = metrics.map(metric => `metrics=${metric}`).join('&');
        const url = `players/filter_by_player/?player_id=${selectedPlayer}&${queryString}&x=${x}`;

        // Fetch the data using the API utility
        fetchData(url, token)
          .then(data => {
            const sortedData = data.sort((a, b) => new Date(a.game_date) - new Date(b.game_date)).slice(-x);

            let newChartData = {
              Dates: sortedData.map(d => d.game_date),
              Opponents: sortedData.map(d => d.opponent),
            };

            switch (selectedFeature) {
              case 'Total':
                newChartData.PT2 = sortedData.map(d => (d.fg2m_mean_rolling_7_games / d.fg2a_mean_rolling_7_games) * 100);
                newChartData.PT3 = sortedData.map(d => (d.fg3m_mean_rolling_7_games / d.fg3a_mean_rolling_7_games) * 100);
                break;
              case 'Pts+Ast':
                newChartData.Total = sortedData.map(d => {
                  let total = 0;
                  if (selectedFeature.includes('Ast')) total += d.ast_mean_rolling_7_games;
                  if (selectedFeature.includes('Pts')) total += d.pts_mean_rolling_7_games;
                  return total;
                });
                break;
              case 'Pts+Reb':
                newChartData.Total = sortedData.map(d => {
                  let total = 0;
                  if (selectedFeature.includes('Pts')) total += d.pts_mean_rolling_7_games;
                  if (selectedFeature.includes('Reb')) total += d.reb_mean_rolling_7_games;
                  return total;
                });
                break;
              case 'Pts+Ast+Reb':
                newChartData.Total = sortedData.map(d => {
                  let total = 0;
                  if (selectedFeature.includes('Ast')) total += d.ast_mean_rolling_7_games;
                  if (selectedFeature.includes('Reb')) total += d.reb_mean_rolling_7_games;
                  if (selectedFeature.includes('Pts')) total += d.pts_mean_rolling_7_games;
                  return total;
                });
                break;
              case 'Ast+Reb':
                newChartData.Total = sortedData.map(d => {
                  let total = 0;
                  if (selectedFeature.includes('Ast')) total += d.ast_mean_rolling_7_games;
                  if (selectedFeature.includes('Reb')) total += d.reb_mean_rolling_7_games;
                  return total;
                });
                break;
              case 'AST':
                newChartData['AST/TO'] = sortedData.map(d => d.ast_mean_rolling_7_games / (d.tov_mean_rolling_7_games || 1));
                // newChartData['Passes/Touches'] = sortedData.map(d => d.passes_mean_rolling_7_games / (d.touches_mean_rolling_7_games || 1));
                break;
              case 'REB':
                newChartData['OREB/Chances'] = sortedData.map(d => d.oreb_mean_rolling_7_games / (d.reboundchancesoffensive_mean_rolling_7_games || 1));
                newChartData['DREB/Chances'] = sortedData.map(d => d.dreb_mean_rolling_7_games / (d.reboundchancesdefensive_mean_rolling_7_games || 1));
                break;
              case 'BLK':
                newChartData['BLK'] = sortedData.map(d => d.blk_mean_rolling_7_games);
                newChartData['BLKA'] = sortedData.map(d => d.blka_mean_rolling_7_games);
                break;
              case 'STL':
                newChartData.STL = sortedData.map(d => d.stl_mean_rolling_7_games);
                break;
            }

            dataCache.current[cacheKey] = newChartData;
            setChartData(newChartData);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } else {
        setChartData(dataCache.current[cacheKey]);
      }
    }
  }, [selectedPlayer, selectedFeature, x, token]);  // Include token as a dependency

  return chartData;
};

export default useFetchShootingChartData;
