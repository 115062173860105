import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        fontFamily: 'Montserrat',
        lineHeight: 1.6,
        color: "#333",
        padding: "20px",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--main-color)",
          textAlign: "center",
        }}
      >
        Privacy Policy
      </h1>
      <p>
        <strong>Last Updated:</strong> 12/14/2024
      </p>

      <p>
        <strong>Showstone</strong> (“we,” “our,” “us”) respects your
        privacy and is committed to protecting the personal information you
        share with us. This Privacy Policy outlines how we collect, use, and
        store your information, and your rights under the General Data
        Protection Regulation (GDPR) and other applicable laws.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>1. Information We Collect</h2>
      <p>
        We collect only the data necessary to provide our services, ensure
        platform security, and improve user experience.
      </p>
      <ul>
        <li>
          <strong>Authentication Data:</strong> When you log in using Google or
          other methods, we store your <em>Auth ID</em> to authenticate your
          account.
        </li>
        <li>
          <strong>Subscription Data:</strong> If you subscribe through PayPal,
          we store your <em>PayPal Subscription ID</em> and{" "}
          <em>PayPal email address</em> to manage your subscription and provide
          appropriate services.
        </li>
        <li>
          <strong>Automatically Collected Information:</strong>
          <ul>
            <li>
              <em>IP Address and Device Information:</em> Handled by{" "}
              <strong>Auth0</strong> for fraud detection and anomaly detection.
              See{" "}
              <a
                href="https://auth0.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Auth0’s Privacy Policy
              </a>
              .
            </li>
            <li>
              <em>Application Insights:</em> We use{" "}
              <strong>Azure Application Insights</strong> to monitor platform
              performance and improve functionality. You can opt out of this
              tracking through our cookie consent tool.
            </li>
            <li>
              <em>Google Fonts and Google Sign-In:</em> These services may
              collect data like your IP address, governed by{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google’s Privacy Policy
              </a>
              .
            </li>
          </ul>
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>2. How We Use Your Information</h2>
      <p>We use your data for the following purposes:</p>
      <ul>
        <li>To provide and manage our services.</li>
        <li>To verify user identity and prevent fraudulent activities.</li>
        <li>To manage subscriptions and payment processes through PayPal.</li>
        <li>To analyze and improve platform performance through Azure Application Insights.</li>
        <li>To comply with legal obligations and ensure platform security.</li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>3. GDPR Rights</h2>
      <p>Under GDPR, you have the following rights:</p>
      <ul>
        <li>
          <strong>Right to Access:</strong> Request access to the personal data
          we hold about you.
        </li>
        <li>
          <strong>Right to Correction:</strong> Request correction of
          inaccurate or incomplete data.
        </li>
        <li>
          <strong>Right to Data Deletion:</strong> You may request deletion of
          your personal data if:
          <ul>
            <li>You are not currently subscribed to any of our services.</li>
            <li>You do not have any outstanding financial obligations to us.</li>
          </ul>
          To request data deletion, contact us at{" "}
          <a href="mailto:showstone@showstone.io]">showstone@showstone.io</a>.
        </li>
        <li>
          <strong>Right to Object:</strong> Opt out of non-essential cookies
          and analytics tracking.
        </li>
        <li>
          <strong>Right to Data Portability:</strong> Request a copy of your
          data in a portable format.
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>4. Data Sharing</h2>
      <p>We do not sell your personal data. We may share it with:</p>
      <ul>
        <li>
          <strong>Auth0:</strong> Handles authentication and security.
        </li>
        <li>
          <strong>PayPal:</strong> Manages subscription payments.
        </li>
        <li>
          <strong>Azure Application Insights:</strong> Monitors platform
          performance.
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>5. Cookies</h2>
      <p>We use cookies to enhance your experience:</p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> Required for functionality (e.g.,
          JWT tokens, backend session cookies).
        </li>
        <li>
          <strong>Non-Essential Cookies:</strong> Used for analytics and
          performance tracking through Azure Application Insights. You can opt
          out via our cookie consent tool.
        </li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>6. Security</h2>
      <p>We use industry-standard practices to protect your data, including:</p>
      <ul>
        <li>Encryption of sensitive data.</li>
        <li>Access control to limit who can view your data.</li>
        <li>Regular security audits of our platform.</li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>7. Data Retention</h2>
      <p>We retain your data only as long as necessary:</p>
      <ul>
        <li>
          Authentication data and PayPal information are stored for the
          duration of your subscription or as required by law.
        </li>
        <li>Session data is stored temporarily while you are logged in.</li>
      </ul>

      <h2 style={{ color: "var(--main-color)" }}>8. Updates to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. The updated
        version will be available on this page with a revised "Last Updated"
        date.
      </p>

      <h2 style={{ color: "var(--main-color)" }}>9. Contact Us</h2>
      <p>If you have questions or wish to exercise your rights, contact us at:</p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:showstone@showstone.io">showstone@showstone.io</a>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
