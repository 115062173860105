import React from 'react';
import PlayerInfo from './MatchupPlayerInfo';
import PlayerPredictions from './MatchupPlayerPredictions';
import './MatchupPlayerCard.css';

const MatchupPlayerCard = ({ playerData, color1, color2, color3, onClick }) => {
  if (!playerData) {
    return <div>No player data available</div>;
  }

  return (
    <div className="matchup-players-player-card" onClick={onClick}>
      <div className="matchup-players-player-info">
        <PlayerInfo playerData={playerData} color1={color1} color2={color2} color3={color2} />
      </div>
      <div className="matchup-players-player-predictions">
        <PlayerPredictions playerData = {playerData} color1={color1} />
      </div>
    </div>
  );
};

export default MatchupPlayerCard;
