import React, { useState, useEffect } from 'react';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import './PlayerSelection.css';

const PlayerSelection = ({ selectedFeature }) => {
  const {
    teamColors,
    selectedPlayer,
    cart,
    setCart,
    thresholds,
    adjustedThresholds,
    nextGameDetails, // Access nextGameDetails for date and time
  } = usePlayerColors();

  const [lineType, setLineType] = useState('over');
  const [comment, setComment] = useState('');
  const [isMinimized, setIsMinimized] = useState(true);

  useEffect(() => {
    setComment(''); // Reset comment when selected player changes
  }, [selectedPlayer]);

  const playerName = players[selectedPlayer]?.name || 'Unknown Player';

  const thresholdValue = thresholds[playerName]?.[selectedFeature];
  const adjustedThresholdValue = adjustedThresholds[playerName]?.[selectedFeature];

  const line =
    adjustedThresholdValue !== undefined && adjustedThresholdValue !== null
      ? adjustedThresholdValue
      : thresholdValue !== undefined && thresholdValue !== null
      ? thresholdValue
      : 6.5; // default value

  const formattedLine = Number(line).toFixed(1);

  const featureMap = {
    Total: 'PTS',
    AST: 'AST',
    REB: 'REB',
    BLK: 'BLK',
    STL: 'STL',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb',
    'Ast+Reb': 'Ast+Reb',
  };

  const addToCart = () => {
    if (selectedPlayer) {
      const mappedFeature = featureMap[selectedFeature] || selectedFeature;

      const isPlayerInCart = cart.some(
        (item) => item.playerId === selectedPlayer && item.feature === mappedFeature
      );

      if (isPlayerInCart) {
        alert(`${playerName} with feature ${mappedFeature} is already added to the cart.`);
        return;
      }

      const playerDetails = players[selectedPlayer];
      const newEntry = {
        playerId: selectedPlayer,
        playerName: playerDetails.name,
        feature: mappedFeature,
        propType: mappedFeature,
        line: Number(formattedLine),
        lineType,
        comment,
        date: nextGameDetails.date, // Add date
        time: nextGameDetails.time, // Add time
      };

      setCart([...cart, newEntry]);
      setComment(''); // Reset comment field after adding
    }
  };

  const removeFromCart = (index) => {
    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Determine background color
  const backgroundColor = teamColors[0] || '#ffffff';

  // Function to calculate relative luminance
  function getLuminance(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  // Function to decide font color based on luminance
  function getFontColor(color) {
    const luminance = getLuminance(color);
    return luminance > 0.5 ? '#000000' : '#FFFFFF'; // Black for light backgrounds, white for dark
  }

  const fontColor = getFontColor(backgroundColor);

  return (
    <div
      className={`player-selection ${isMinimized ? 'minimized' : ''}`}
      style={{ backgroundColor, color: fontColor }}
    >
      <div className="player-selection-toggle-button" onClick={toggleMinimize}>
        {isMinimized ? '+' : '-'}
      </div>
      {!isMinimized && (
        <>
          <h2 className="player-selection-title">Player Selection</h2>
          {selectedPlayer && (
            <>
              <button
                onClick={addToCart}
                style={{
                  backgroundColor: teamColors[1] || '#555',
                  color: getFontColor(teamColors[1] || '#555'),
                }}
              >
                Add {players[selectedPlayer].name} {selectedFeature} {formattedLine}{' '}
                {lineType}
              </button>
              <select
                onChange={(e) => setLineType(e.target.value)}
                value={lineType}
              >
                <option value="over">Over</option>
                <option value="under">Under</option>
              </select>
              <input
                type="text"
                placeholder="Add a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </>
          )}
          <h3>Selected Players:</h3>
          {cart.length > 0 ? (
            cart.map((item, index) => {
              const cartItemBackground = teamColors[2] || '#ffffff';
              const cartItemFontColor = getFontColor(cartItemBackground);

              return (
                <div
                  key={index}
                  className="player-selection-cart-item"
                  style={{
                    backgroundColor: cartItemBackground,
                    color: cartItemFontColor,
                  }}
                >
                  <span>
                    {item.playerName} - {item.feature} {item.line} {item.lineType}
                    {item.comment && <span> - {item.comment}</span>}
                  </span>
                  <button
                    className="remove-button"
                    onClick={() => removeFromCart(index)}
                    style={{
                      backgroundColor: teamColors[1] || '#555',
                      color: getFontColor(teamColors[1] || '#555'),
                    }}
                  >
                    Remove
                  </button>
                </div>
              );
            })
          ) : (
            <p>No players selected yet.</p>
          )}
        </>
      )}
    </div>
  );
};

export default PlayerSelection;
