import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import './SimilarPlayerPerformance.css';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import { useKeycloak } from '../KeycloakProvider';
import { fetchData } from '../api';

/**
 * 1) A helper function to compute the feature value for each game
 */
function computeFeatureValue(gameRow, feature) {
  switch (feature) {
    case 'Total': // Typically "Points"
      return gameRow.fg2m * 2 + gameRow.fg3m * 3 + gameRow.ftm;
    case 'AST':
      return gameRow.ast;
    case 'REB':
      return gameRow.reb;
    case 'BLK':
      return gameRow.blk;
    case 'STL':
      return gameRow.stl;
    case 'Pts+Ast':
      return (
        gameRow.fg2m * 2 + gameRow.fg3m * 3 + gameRow.ftm + gameRow.ast
      );
    case 'Pts+Reb':
      return (
        gameRow.fg2m * 2 + gameRow.fg3m * 3 + gameRow.ftm + gameRow.reb
      );
    case 'Ast+Reb':
      return gameRow.ast + gameRow.reb;
    case 'Pts+Ast+Reb':
      return (
        gameRow.fg2m * 2 +
        gameRow.fg3m * 3 +
        gameRow.ftm +
        gameRow.ast +
        gameRow.reb
      );
    default:
      // fallback
      return gameRow.fg2m * 2 + gameRow.fg3m * 3 + gameRow.ftm;
  }
}

/**
 * 2) Chart component that displays a small bar+line chart
 */
const ChartWithBar = ({ values, dates, lineValues, chartKey }) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: (params) => {
        if (!params || params.length < 2) return '';

        const date = params[0].name;
        const barVal = params[0].value;
        const lineVal = params[1].value;
        const difference = (barVal - lineVal).toFixed(2);

        return `
          Date: ${date}<br/>
          Feature: ${barVal}<br/>
          Line: ${lineVal}<br/>
          Difference: ${difference}
        `;
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '5%',
      bottom: '5%',
      containLabel: false,
    },
    xAxis: {
      type: 'category',
      data: dates,
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    series: [
      {
        type: 'bar',
        data: values,
        itemStyle: {
          color: (params) =>
            params.value > lineValues[params.dataIndex]
              ? '#008000'
              : '#ff0000',
        },
      },
      {
        type: 'line',
        data: lineValues,
        showSymbol: false,
        lineStyle: {
          color: '#507B58',
          width: 2,
        },
        itemStyle: {
          color: '#507B58',
        },
        z: 3,
      },
    ],
  };

  return (
    <div className="similar-player-chart-container">
      <ReactECharts
        option={option}
        style={{ height: '90px', width: '120px' }}
        key={chartKey}
      />
    </div>
  );
};

/**
 * 3) Main component
 */
const SimilarPlayerPerformance = ({ selectedFeature }) => {
  const { selectedPlayer, thresholds } = usePlayerColors();
  const [featureData, setFeatureData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useKeycloak();

  // Map UI labels to param names
  const featureMapping = {
    Total: 'pts',
    'Pts+Ast+Reb': 'pts_ast_reb',
    'Pts+Ast': 'pts_ast',
    'Pts+Reb': 'pts_reb',
    'Ast+Reb': 'ast_reb',
    AST: 'ast',
    REB: 'reb',
    BLK: 'blk',
    STL: 'stl',
  };

  const displayMapping = {
    Total: 'PTS',
    'Pts+Ast+Reb': 'P+A+R',
    'Pts+Ast': 'P+A',
    'Pts+Reb': 'P+R',
    'Ast+Reb': 'A+R',
  };

  // Convert UI feature -> API feature if needed
  const requestFeature = featureMapping[selectedFeature] || selectedFeature;
  const displayFeature = displayMapping[selectedFeature] || selectedFeature;

  /**
   * 4) fetchLast5GamesForSimilarPlayer
   */
  const fetchLast5GamesForSimilarPlayer = async (similarPlayerId, feature) => {
    const metrics = [
      'ftm',
      'fg2m',
      'fg3m',
      'game_date',
      'min',
      'ast',
      'reb',
      'blk',
      'stl',
    ];
    const queryString = metrics.map((m) => `metrics=${m}`).join('&');

    // Opponent
    const opponentAbbreviation = players[selectedPlayer]?.opponentName
      ? teamNameToAbbreviation[players[selectedPlayer].opponentName]
      : '';

    const url = `players/filter_by_player/?player_id=${similarPlayerId}&${queryString}&x=5${
      opponentAbbreviation ? `&opponent=${opponentAbbreviation}` : ''
    }`;

    const data = await fetchData(url, token);
    if (!data.length) {
      console.warn(
        `No data found for player_id ${similarPlayerId} vs ${opponentAbbreviation}`
      );
      return null;
    }

    // Sort chronologically
    const sortedData = data.sort(
      (a, b) => new Date(a.game_date) - new Date(b.game_date)
    );

    // Compute feature array
    const featureValues = sortedData.map((row) =>
      computeFeatureValue(row, feature)
    );

    return {
      featureValues,
      minValues: sortedData.map((d) => d.min),
      dates: sortedData.map((d) => d.game_date),
    };
  };

  /**
   * 5) fetchSimilarPlayers
   */
  const fetchSimilarPlayers = async () => {
    setLoading(true);
    setFeatureData([]);

    try {
      // If thresholds for this player are not yet loaded, wait
      // But typically you might store them in a global or as a promise
      // We'll assume they're loaded, but check if undefined
      const currentPlayerName = players[selectedPlayer]?.name;
      if (!currentPlayerName) {
        console.warn('No selected player name found.');
        setLoading(false);
        return;
      }

      // We'll check the threshold for this feature
      const currentPlayerThresholds = thresholds[currentPlayerName];
      if (!currentPlayerThresholds) {
        console.warn('No thresholds found for', currentPlayerName);
      }

      const url = `players/get_similar_players/?player_id=${selectedPlayer}&feature=${requestFeature}&x=5`;
      const similarPlayers = await fetchData(url, token);

      const validFeatureData = [];

      for (const simPlayer of similarPlayers) {
        try {
          const chartData = await fetchLast5GamesForSimilarPlayer(
            simPlayer.player_id,
            selectedFeature
          );

          if (chartData) {
            const playerInfo = players[simPlayer.player_id];
            if (!playerInfo) {
              console.warn(`Missing info for ${simPlayer.player_id}`);
              continue;
            }

            // The threshold "line" for this feature
            // If it's undefined, fallback to e.g. 0
            let lineValue = 0;
            if (currentPlayerThresholds && currentPlayerThresholds[selectedFeature] !== undefined) {
              lineValue = currentPlayerThresholds[selectedFeature];
            }

            const lineValues = Array(chartData.featureValues.length).fill(lineValue);

            validFeatureData.push({
              player: playerInfo.name,
              image: playerInfo.imageSrc,
              team: playerInfo.teamName,
              teamLogo: playerInfo.teamLogoSrc,
              position: playerInfo.position,
              values: chartData.featureValues,
              minValues: chartData.minValues,
              dates: chartData.dates,
              lineValues,
            });
          }
        } catch (error) {
          console.error(
            `Error processing player_id ${simPlayer.player_id}:`,
            error
          );
        }
      }

      setFeatureData(validFeatureData);
    } catch (error) {
      console.error('Error fetching similar players:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * 6) useEffect to fetch data when player or feature changes
   */
  useEffect(() => {
    fetchSimilarPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature, selectedPlayer]);

  /**
   * 7) formatPlayerName
   */
  const formatPlayerName = (name) => {
    const [firstName, ...lastNameParts] = name.split(' ');
    return `${firstName[0]}. ${lastNameParts.join(' ')}`;
  };

  /**
   * 8) Render
   */
  return (
    <div className="similar-player-performance-container">
      <div className="similar-player-header-container">
        <div className="title-container">
          <h2>Similar Players</h2>
          <h3>VS {players[selectedPlayer]?.opponentName}</h3>
        </div>
      </div>

      {loading ? (
        <p>Loading data...</p>
      ) : featureData.length > 0 ? (
        <table className="similar-player-performance-table">
          <thead>
            <tr>
              <th className="similar-player-centered-header">Player</th>
              <th className="similar-player-centered-header">{displayFeature}</th>
              <th className="similar-player-centered-header">Min</th>
              <th className="similar-player-centered-header">VS line</th>
            </tr>
          </thead>
          <tbody>
            {featureData.map((playerData, index) => (
              <tr key={index}>
                <td className="similar-player-centered-cell player-cell">
                  <div className="similar-player-player-info-container">
                    <img
                      src={playerData.image}
                      alt={playerData.player}
                      className="similar-player-performance-player-image"
                    />
                    <img
                      src={playerData.teamLogo}
                      alt={`${playerData.team} logo`}
                      className="similar-player-team-logo"
                    />
                    <div className="similar-player-name-box">
                      <span className="similar-player-performance-player-position">
                        {playerData.position}
                      </span>
                      <span className="similar-player-performance-player-name">
                        {formatPlayerName(playerData.player)}
                      </span>
                    </div>
                  </div>
                </td>

                {/* Average of the last 5 games */}
                <td className="similar-player-centered-cell">
                  {(
                    playerData.values.reduce((a, b) => a + b, 0) /
                    playerData.values.length
                  ).toFixed(2)}
                </td>

                {/* Example: minutes from the first game */}
                <td className="similar-player-centered-cell">
                  {playerData.minValues[0]}
                </td>

                {/* The small bar+line chart */}
                <td className="similar-player-centered-cell">
                  <ChartWithBar
                    values={playerData.values}
                    dates={playerData.dates}
                    lineValues={playerData.lineValues}
                    chartKey={`${playerData.player}-${selectedFeature}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No similar player data found.</p>
      )}
    </div>
  );
};

export default SimilarPlayerPerformance;
