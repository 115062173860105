import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { useKeycloak } from "../KeycloakProvider";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import { getPlayerId } from "../components/PlayerLookup";
import "./PlayerFeatureTable.css";

function PlayerFeatureTable() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");

  const [filters, setFilters] = useState({
    playerName: "",
    featureProbability: 70,
    feature: "",
    teamAbbreviation: "",
    opponent: "",
  });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [useAmericanOdds, setUseAmericanOdds] = useState(true);

  const { token, roles } = useKeycloak();
  const { setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();

  // Example check for premium
  const isPremiumUser = roles.includes("premium");

  const convertToAmericanOdds = (decimalOdds) => {
    if (!decimalOdds || isNaN(decimalOdds)) return "";
    const val = parseFloat(decimalOdds);
    if (val >= 2.0) {
      return `+${Math.round((val - 1) * 100)}`;
    } else {
      return `${Math.round(-100 / (val - 1))}`;
    }
  };

  const formatTimeAgo = (isoTimestamp) => {
    if (!isoTimestamp) return "";
    const updatedDate = new Date(isoTimestamp);
    const now = new Date();
    const diffMs = now - updatedDate;
    const diffMin = diffMs / (1000 * 60);

    if (diffMin < 60) {
      const mins = Math.floor(diffMin);
      return mins <= 1 ? "1 minute ago" : `${mins} minutes ago`;
    } else {
      const hrs = Math.floor(diffMin / 60);
      return hrs <= 1 ? "1 hour ago" : `${hrs} hours ago`;
    }
  };

  useEffect(() => {
    const storedOdds = localStorage.getItem("americanOdds");
    if (storedOdds !== null) {
      setUseAmericanOdds(JSON.parse(storedOdds));
    }
  }, []);

  useEffect(() => {
    const handleOddsChange = () => {
      const stored = localStorage.getItem("americanOdds");
      if (stored !== null) {
        setUseAmericanOdds(JSON.parse(stored));
      }
    };
    window.addEventListener("oddsChange", handleOddsChange);
    return () => window.removeEventListener("oddsChange", handleOddsChange);
  }, []);

  // Fetch data only if token and user is premium
  useEffect(() => {
    if (!token || !isPremiumUser) return;

    const fetchPredictions = async () => {
      try {
        const resp = await fetchData(
          "player-feature-predictions/filter_predictions/?latest=true",
          token
        );
        setData(resp);
        setFilteredData(resp);

        if (resp.length > 0 && resp[0].last_updated) {
          setLastUpdated(resp[0].last_updated);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchPredictions();
  }, [token, isPremiumUser]);

  // Filter + sort
  useEffect(() => {
    let filtered = [...data];
    const {
      playerName,
      featureProbability,
      feature,
      teamAbbreviation,
      opponent,
    } = filters;

    if (playerName) {
      filtered = filtered.filter((row) =>
        row.player_name?.toLowerCase().includes(playerName.toLowerCase())
      );
    }

    if (featureProbability) {
      const minProb = parseFloat(featureProbability) / 100;
      filtered = filtered.filter(
        (row) => row.feature_probability && row.feature_probability > minProb
      );
    }

    if (feature) {
      filtered = filtered.filter(
        (row) => row.feature?.toLowerCase() === feature.toLowerCase()
      );
    }

    if (teamAbbreviation) {
      filtered = filtered.filter((row) =>
        row.team_abbreviation
          ?.toLowerCase()
          .includes(teamAbbreviation.toLowerCase())
      );
    }

    if (opponent) {
      filtered = filtered.filter((row) =>
        row.next_opponent?.toLowerCase().includes(opponent.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        const valA = a[sortConfig.key];
        const valB = b[sortConfig.key];
        if (valA < valB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valA > valB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredData(filtered);
  }, [data, filters, sortConfig]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Click => set selectedPlayer + navigate to /PlayerDashboard?playerId=theId
  const handlePlayerClick = (playerName) => {
    const id = getPlayerId(playerName);
    setSelectedPlayer(id);
    // We'll pass the ID as a query param: ?playerId=ABC
    navigate(`/PlayerDashboard?playerId=${encodeURIComponent(id)}`);
  };

  // If the user is NOT premium, show a nicer message + button
  if (!isPremiumUser) {
    return (
      <div style={{ textAlign: 'center', margin: '2rem' }}>
      <p style={{ fontSize: '1.2rem',fontFamily:'var(--font-main)', marginBottom: '1rem' }}>
        This page is only for premium members.
      </p>
      <button
        onClick={() => window.location.href = "/Subscription"}
        style={{
          padding: '0.75rem 1.5rem',
          backgroundColor: 'var(--main-color)',
          fontFamily:'var(--font-main)',
          border: 'none',
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontSize: '1rem',
        }}
      >
          Buy Premium
        </button>
      </div>
    );
  }

  const lastUpdatedAgo = formatTimeAgo(lastUpdated);

  return (
    <div className="player-feature-table-container">
      <h1>
        Tonight's Projections
        {lastUpdatedAgo && (
          <span style={{ marginLeft: "10px", fontSize: "0.9em", color: "#666" }}>
            (Updated {lastUpdatedAgo})
          </span>
        )}
      </h1>

      {/* Filters */}
      <div className="projection-filters">
        <input
          type="text"
          placeholder="Player Name"
          name="playerName"
          value={filters.playerName}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          placeholder="Probability >"
          name="featureProbability"
          value={filters.featureProbability}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          placeholder="Feature"
          name="feature"
          value={filters.feature}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          placeholder="Team Abbreviation"
          name="teamAbbreviation"
          value={filters.teamAbbreviation}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          placeholder="Opponent"
          name="opponent"
          value={filters.opponent}
          onChange={handleFilterChange}
        />
      </div>

      <div className="table-wrapper">
        {filteredData.length > 0 ? (
          <table className="player-feature-table">
            <thead>
              <tr>
                <th onClick={() => handleSort("player_name")}>Player Name</th>
                <th onClick={() => handleSort("team_abbreviation")}>Team</th>
                <th onClick={() => handleSort("next_opponent")}>Opponent</th>
                <th onClick={() => handleSort("feature")}>Prop</th>
                <th onClick={() => handleSort("threshold")}>Threshold</th>
                <th onClick={() => handleSort("sportsbook")}>Sportsbook</th>
                <th onClick={() => handleSort("feature_prediction")}>Proj</th>
                <th onClick={() => handleSort("feature_probability")}>
                  Confidence
                </th>
                <th onClick={() => handleSort("over_under")}>Bet</th>
                <th onClick={() => handleSort("odds")}>Odds</th>
                <th onClick={() => handleSort("game_date")}>Game Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => {
                const displayedOdds = useAmericanOdds
                  ? convertToAmericanOdds(row.odds)
                  : parseFloat(row.odds)?.toFixed(2);

                return (
                  <tr key={index}>
                    <td>
                      <a
                        href={`/PlayerDashboard?playerId=${encodeURIComponent(
                          getPlayerId(row.player_name)
                        )}`}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "var(--main-color)",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePlayerClick(row.player_name);
                        }}
                      >
                        {row.player_name}
                      </a>
                    </td>
                    <td>{row.team_abbreviation}</td>
                    <td>{row.next_opponent}</td>
                    <td>{row.feature}</td>
                    <td>{row.threshold}</td>
                    <td>{row.sportsbook}</td>
                    <td>{Math.round(row.feature_prediction)}</td>
                    <td>{(row.feature_probability * 100).toFixed(1)}%</td>
                    <td>{row.over_under}</td>
                    <td>{displayedOdds}</td>
                    <td>{row.game_date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No data available to display.</p>
        )}
      </div>
    </div>
  );
}

export default PlayerFeatureTable;
